import { httpApi } from "../Http.api";
import { AxiosError } from "axios";
import { GetStoreResponseData } from "interfaces/ApiInterface/IStoreSetting";
import { IDomainList } from "interfaces/ApiInterface/IStore";
import { IPriceListResponse, 
  GetParentStoreDomainNameResponseData } from "interfaces/ApiInterface/IStoreSetting";

// Get Store Details according to Portal Number
export const getStoreDetails = (id: number): Promise<GetStoreResponseData> => {
  return httpApi
    .get(`/bstores/getbstoredetailsbyportalid/${id}`)
    .then((res: GetStoreResponseData) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

// Get Store Details according to Portal Number
export const getParentStoreDomainName = 
  (id: number): Promise<GetParentStoreDomainNameResponseData> => {
    return httpApi
      .get(`/bstores/GetParentStoreDomainName/${id}`)
      .then((res: GetParentStoreDomainNameResponseData) => res)
      .catch((err: AxiosError) => {
        throw err;
      });
  };

// Edit Store Details
export const updateStoreDetails = (data: any) => { // eslint-disable-line
  return httpApi
    .put("/bstores/updatebstore", { ...data })
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

//Get Price list
export const getPriceList = (id: number, isAssociated: boolean): Promise<IPriceListResponse> => {
  return httpApi
    .get(
      // eslint-disable-next-line max-len
      `/bstores/getavailableunavailablebstorepricelist/${id}/${isAssociated}?expand=&filter=isfromadmin,IsManageInBStore~eq~1`
    )
    .then((res: IPriceListResponse) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

//Check domain url
export const checkDomainUrl = (): Promise<IDomainList> => {
  return httpApi
    .get("/spokecustomdomain/spokecustomdomainlist")
    .then((res: IDomainList) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

//check if the store name exists
export const checkStoreName = (): Promise<IDomainList> => {
  return httpApi
    .get("domain")
    .then((res) => res)
    .catch((err) => {
      throw err;
    });
};
