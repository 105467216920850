/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import {
  Modal,
  Col,
  Row,
  Typography,
  Button,
  Form,
  Input,
  Select,
  Checkbox,
  Space,
  FormInstance,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
const { Title } = Typography;
import { COUNTRIES, STATES } from "components/SpokeCustomComponents/Billing/Billing.Constant";
import { CheckCircleFilled } from "@ant-design/icons";
import "./RequestPcnaAccountModal.scss";
import { isValidEmail } from "utils";
import { requestForPcnaAccount } from "api/Services/SpokeCustumAPIs/store.api";
import { IStoreCreationFormValues } from "interfaces/ClientInterface/IStore";
import { IRequestPcnaPayload } from "interfaces/ApiInterface/IStore";
import { notificationController } from "components/Common/Notification/Notification";
import { useAppDispatch } from "hooks/reduxHooks";
import { getPageChange } from "store/Slices/store.slice";

interface IRequestPcnaAccountModalProps {
  isModalVisible: boolean;
  handleCancel?: () => void;
  // eslint-disable-next-line no-unused-vars
  successHandlerCallback?: (companyName: string) => void;
  userInputFormData: IStoreCreationFormValues;
  parentFormRef: FormInstance;
}

const RequestPcnaAccountModal: React.FC<IRequestPcnaAccountModalProps> = ({
  isModalVisible,
  handleCancel,
  userInputFormData,
  parentFormRef,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    userInputFormData?.EmailAddress != "" &&
      form.setFields([
        {
          name: "EmailAddress",
          value: userInputFormData?.EmailAddress,
          errors: [],
        },
      ]);

    //Selecting the only country available from the dropdown
    form.setFields([
      {
        name: "Country",
        value: COUNTRIES?.[0]?.label,
        errors: [],
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInputFormData]);

  const handleBack = () => {
    // clearFormFields();
    handleCancel && handleCancel();
  };

  // const clearFormFields = () => {
  //   form.resetFields();
  // };

  const doneClickHandler = async () => {
    try {
      const dataRes = await form.validateFields([
        "EmailAddress",
        "IsInvoiceEmailAddress",
        "Country",
        "Street",
        "City",
        "State",
        "PostalCode",
      ]);

      setLoading(true);
      const payload: IRequestPcnaPayload = {
        ...dataRes,
        DistributorName: userInputFormData?.Company,
        FirstName: userInputFormData?.FirstName,
        Lastname: userInputFormData?.LastName,
        JobTitle: userInputFormData?.Title,
      };
      await requestForPcnaAccount(payload)
        .then((res) => {
          if (res?.data?.IsSuccess) {
            setShowSuccessMessage(true);
          } else {
            notificationController.error({
              message: "Something went wrong",
              description: "Please try again later",
            });
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });

      form.setFields([
        {
          name: "PcnaAccountDetails",
          value: "",
          errors: [],
        },
      ]);
      form.setFieldsValue({
        BillingEmailAddress: "",
        BillingCountry: "",
        BillingStreet: "",
        BillingCity: "",
        BillingState: "",
        BillingPostalCode: "",
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const handleNextAfterSubmit = () => {
    parentFormRef.setFields([
      {
        name: "PcnaAccountRequested",
        value: true,
        errors: [],
      },
    ]);
    dispatch(getPageChange(4));
  };
  const stateList = () => {
    const states = STATES.map((st) => {
      return {
        label: st.Text,
        value: st.Text,
      };
    });
    return states;
  };
  // eslint-disable-next-line no-unused-vars
  const renderSubmittedState = () => {
    return (
      <div className="submitted-pcna-account-wrapper">
        <Row gutter={[16, 16]} className="d-flex justify-center align-center">
          <Col span={22}>
            <Title level={4} className="m-0">
              PCNA account
            </Title>
          </Col>
          <Col span={2}>
            <div className="close-icon" onClick={handleNextAfterSubmit}>
              <CloseOutlined />
            </div>
          </Col>
          <Col span={24}>
            <Space direction="horizontal" size={8} className="d-flex justify-start align-start">
              <CheckCircleFilled className="checked-icon" />
              <Space direction="vertical" size={0}>
                <div className="title-message">Your PCNA account details have been submitted.</div>
                <div className="sub-title-message mt-8">
                  Registration may take 1-2 days. Continue setting up your store and we&apos;ll
                  reach out with any questions.
                </div>
              </Space>
            </Space>
          </Col>
        </Row>
        <div className="bottom-action-container">
          <div className="btn-submit">
            <Button type="primary" data-test-selector="btnDone" onClick={handleNextAfterSubmit}>
              Next
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const renderRequestForm = () => {
    return (
      <div className="request-pcna-account-wrapper">
        <Form form={form}>
          <Row gutter={[16, 16]} className="d-flex justify-center align-center">
            <Col span={24}>
              <Title level={4} className="m-0">
                PCNA account
              </Title>
              <Title level={5} className="mt-0 pcna-sub-title">
                Add your billing details to register for a PCNA account
              </Title>
            </Col>
            <Col span={24}>
              <div>
                <div className="font-style">Email address for invoices</div>
                <Form.Item
                  name="EmailAddress"
                  className="m-0"
                  rules={[
                    { required: true, message: "Email address is required", whitespace: true },
                    {
                      validator: (rule, value) => {
                        if (!value || isValidEmail(value)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(new Error("The input is not a valid Email"));
                        }
                      },
                    },
                    {
                      max: 50,
                      message: "Input must not exceed the maximum character limit of 50",
                    },
                  ]}
                >
                  <Input
                    data-test-selector="txtEmailAddress "
                    placeholder="e.g. accounts@ritex.com"
                    allowClear={true}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={24}>
              <div className="font-style mt-8">Email address for statements</div>
              <Form.Item
                name="IsInvoiceEmailAddress"
                valuePropName="checked"
                className="checkbox-billing-address m-0"
                initialValue={true}
              >
                <Checkbox data-test-selector="chkIsInvoiceEmailAddress" defaultChecked={true}>
                  Same as the email address for invoices
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={24}>
              <div className="font-style">Country</div>
              <Form.Item
                name={"Country"}
                className="m-0"
                rules={[{ required: true, message: "Country is required" }]}
              >
                <Select
                  defaultValue={COUNTRIES?.[0]?.label}
                  placeholder="Select Country"
                  bordered={true}
                  data-test-selector={"drpCountryList"}
                  options={COUNTRIES}
                />
              </Form.Item>
              <Title className="m-0 country-footer-message">
                Givee is only available for Distributors in the United States
              </Title>
            </Col>
            <Col span={24}>
              <div className="font-style">Street</div>
              <Form.Item
                name={"Street"}
                className="m-0"
                rules={[{ required: true, message: "Street is required", whitespace: true }]}
              >
                <Input data-test-selector="txtCompanyName" allowClear={true} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <div className="font-style">City/town</div>
              <Form.Item
                name={"City"}
                className="m-0"
                rules={[{ required: true, message: "City  is required", whitespace: true }]}
              >
                <Input data-test-selector="txtCity" allowClear={true} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <div className="font-style">State</div>
              <Form.Item
                name={"State"}
                className="m-0"
                rules={[{ required: true, message: "State is required", whitespace: true }]}
              >
                <Select options={stateList()} data-test-selector="drpState" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <div className="font-style">ZIP Code</div>
              <Form.Item
                name={"PostalCode"}
                className="m-0"
                rules={[{ required: true, message: "Postal Code is required", whitespace: true }]}
              >
                <Input data-test-selector="txtPostalCode" allowClear={true} />
              </Form.Item>
            </Col>
          </Row>
          <div className="bottom-action-container">
            <Button
              className="back-btn"
              type="default"
              onClick={handleBack}
              data-test-selector="btnCancel"
            >
              Back
            </Button>
            <div className="btn-submit">
              <Button
                htmlType="submit"
                type="primary"
                data-test-selector="btnDone"
                onClick={doneClickHandler}
                loading={loading}
              >
                Submit
              </Button>
            </div>
          </div>
        </Form>
      </div>
    );
  };

  const renderContentConditionally = () => {
    return showSuccessMessage ? renderSubmittedState() : renderRequestForm();
  };

  return (
    <Modal
      open={isModalVisible}
      width={600}
      onCancel={() => {
        handleCancel && handleCancel();
      }}
      closable={!showSuccessMessage}
      destroyOnClose
      data-test-selector="modalRequestPcnaAccount"
      footer={null}
      centered
      maskClosable={false}
    >
      {renderContentConditionally()}
    </Modal>
  );
};

export default RequestPcnaAccountModal;
