import React, { useState } from "react";
import { Modal, Col, Row, Typography, Button, Form, Input } from "antd";
import "./AddCompanyModal.scss";
import { useAppSelector } from "hooks/reduxHooks";
import { validatecompanyName } from "api/Services/SpokeCustumAPIs/store.api";
const { Title } = Typography;

interface IAddNewCompanyModal {
  isModalVisible: boolean;
  handleCancel?: () => void;
  // eslint-disable-next-line no-unused-vars
  successHandlerCallback?: (companyName: string) => void;
}

const AddNewCompanyModal: React.FC<IAddNewCompanyModal> = ({
  isModalVisible,
  handleCancel,
  successHandlerCallback,
}) => {
  const [form] = Form.useForm();
  const userInfo = useAppSelector((state) => state.auth.userInfo);
  const UserId = userInfo?.WebstoreUserId;

  const [compNameStatus, setCompNameStatus] = useState<boolean>(false);

  const handleBack = () => {
    clearCompanyInput();
    handleCancel && handleCancel();
  };

  const clearCompanyInput = () => {
    form.setFields([
      {
        name: "CompanyName",
        value: "",
        errors: [],
      },
    ]);
  };

  const doneClickHandler = async () => {
    try {
      const companyName = form.getFieldValue("CompanyName");
      const compValidationPayload = {
        userId: UserId,
        companyName: companyName,
      };
      const compValidationResponse = await validatecompanyName(compValidationPayload);
      const isCompanyExist = compValidationResponse?.data?.IsSuccess;
      setCompNameStatus(isCompanyExist);

      if (isCompanyExist) {
        form.setFields([
          {
            name: "CompanyName",
            value: companyName,
            errors: ["This company name is already taken, please add another name"],
          },
        ]);
      } else {
        await form.validateFields(["CompanyName"]);
        clearCompanyInput();
        successHandlerCallback && successHandlerCallback(companyName);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const handlePaste = () => {
    form.validateFields(["CompanyName"]);
  };

  return (
    <Modal
      open={isModalVisible}
      width={600}
      onCancel={() => {
        handleCancel && handleCancel();
      }}
      closable
      destroyOnClose
      data-test-selector="modalCopyStripe"
      footer={null}
      centered
    >
      <div className="add-company-wrapper">
        <Form form={form}>
          <Row gutter={[16, 16]} className="d-flex justify-center align-center">
            <Col span={24} className="mt-16">
              <Title level={4} className="m-0">
                Add a new company
              </Title>
            </Col>
            <Col span={24}>
              <div className="margin-bottom my-1">
                <div className="font-style">Company name</div>
                <Form.Item
                  hasFeedback={true}
                  validateStatus={compNameStatus ? "error" : ""}
                  name="CompanyName"
                  rules={[
                    {
                      max: 100,
                      message: "Input must not exceed the maximum character limit of 100",
                    },
                    {
                      validator: (rule, value, callback) => {
                        // Check if the input has a value before calling validateStoreUrl
                        if (!value) {
                          callback("Company name is required");
                          setCompNameStatus(true);
                        } else if (value.trim() === "") {
                          callback("Company name is required");
                          setCompNameStatus(true);
                        } else {
                          callback(); // Skip validation when the field is empty
                          setCompNameStatus(false);
                        }
                      },
                    },
                  ]}
                >
                  <Input data-test-selector="txtCompanyName" onPaste={handlePaste} />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <div className="bottom-action-container">
            <Button
              className="back-btn"
              type="default"
              onClick={handleBack}
              data-test-selector="btnCancel"
            >
              Cancel
            </Button>
            <div className="btn-submit">
              <Button type="primary" data-test-selector="btnDone" onClick={doneClickHandler}>
                Done
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default AddNewCompanyModal;
