import React from "react";
import { Space } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import "./CustomRadio.scss";

interface ICustomRadioButton {
  image?: JSX.Element;
  title: string;
  subTitle?: string;
  isSelected: boolean;
  customActionIcon?: React.ReactNode;
  // eslint-disable-next-line no-unused-vars
  onClickHandler: (value: string) => void;
  value: string;
}

const CustomRadioButton: React.FC<ICustomRadioButton> = ({
  image,
  title,
  subTitle,
  isSelected,
  customActionIcon = null,
  onClickHandler,
  value,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const IconImage: any = image;
  return (
    <div
      className="custom-radio-container"
      onClick={() => onClickHandler(value)}
      data-test-selector={`chkCustomRadio${value}`}
    >
      <div
        className={`d-flex justify-between align-center ${
          isSelected ? "active-card" : "in-active-card"
        }`}
      >
        <Space direction="horizontal" className="content-part">
          <div className="img-wrapper">
            <IconImage />
          </div>
          <Space direction="vertical" size={5}>
            <div className="title" data-test-selector="hdgTitle">
              {title}
            </div>
            <div className="sub-title" data-test-selector="hdgSubTitle">
              {subTitle}
            </div>
          </Space>
        </Space>
        {customActionIcon ? (
          customActionIcon
        ) : (
          <div className="action-btn">
            {isSelected ? (
              <CheckCircleFilled className="checked" />
            ) : (
              <div className="blank-circle" />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomRadioButton;
