/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IStoreOverviewDetails,
  IStoreValidation,
} from "interfaces/ClientInterface/SpokeCustumApiInterface/IGlobal";

//invalid values comes into custom2
const invalidCustom2Values = ["", "-22", null];

const initialState: IStoreValidation = {
  isValidData: {
    IsStoreLogo: -1,
    IsPrintLogo: -1,
    IsProductSelected: -1,
    GuestInvited: -1,
  },
  storeType: null,
  storeOverviewData: {} as IStoreOverviewDetails,
  pcnaId: null,
};

const StoreValidation = createSlice({
  name: "storeValid",
  initialState,
  reducers: {
    setValidationData: (state, action: PayloadAction<any>) => {
      state.isValidData = { ...state, ...action.payload };
    },
    setStoreType: (state, action: PayloadAction<any>) => {
      state.storeType = action.payload;
    },
    setStoreOverviewData: (state, action: PayloadAction<any>) => {
      state.storeOverviewData = action.payload;
      state.pcnaId =
        invalidCustom2Values.indexOf(action.payload.Custom2) !== -1 ? null : action.payload.Custom2;
    },
  },
});

export const { setValidationData, setStoreType, setStoreOverviewData } = StoreValidation.actions;
export default StoreValidation.reducer;
