/* eslint-disable max-lines */
/* eslint-disable max-len */
import { clearLocalStorageData, preservePortalId } from "api/Services/localStorage.service";
import { useAppDispatch } from "hooks/reduxHooks";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { setUserDetails } from "store/Slices/auth.slice";

export const Login: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const searchParams = new URLSearchParams(location.search);
  const impersonationToken = searchParams.get("Impersonation");
  const portalId = searchParams.get("PortalId");
  const redirectToLandingPageURL = process.env.REACT_APP_REDIRECT_URL;

  useEffect(() => {
    if (impersonationToken) {
      if (impersonationToken === "-1") {
        if (portalId) {
          storePortalIdInSessionAndRedux(portalId);
        }
        navigate("/dashboard/about-store");
      } else {
        if (portalId) {
          storePortalIdInSessionAndRedux(portalId);
        }
        navigate("/dashboard/login");
      }
    } else {
      //redirect user to landing page if portal or token is absent
      window.location.href = redirectToLandingPageURL || "";
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const storePortalIdInSessionAndRedux = (portalId: any) => {
    clearLocalStorageData();
    const userInfoData = preservePortalId(portalId);
    //storing login details in redux
    dispatch(
      setUserDetails({
        token: "",
        userInfo: userInfoData,
      })
    );
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          width: "100%",
        }}
      ></div>
    </>
  );
};

export default Login;
