import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Custom2State {
  value: any; // eslint-disable-line
}

const catelogId = localStorage.getItem("catelogId");

const initialState: Custom2State = {
  value: catelogId,
};

const custom2Slice = createSlice({
  name: "custom2",
  initialState,
  reducers: {
    setCustom2Value: (state, action: PayloadAction<any>) => { // eslint-disable-line
      state.value = action.payload;
      localStorage.setItem("catelogId", action.payload);
    },
  },
});

export const { setCustom2Value } = custom2Slice.actions;
export default custom2Slice.reducer;
