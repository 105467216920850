import { httpApi } from "api/Http.api";
import { AuthRequest } from "interfaces/ClientInterface/IAuth";
import { IUser } from "interfaces/ClientInterface/IUser";

export const testUser = {
  id: 1,
  firstName: "Christopher",
  lastName: "Johnson",
  imgUrl: "test.png",
  userName: "@john1989",
  email: {
    name: "christopher.johnson@altence.com",
    verified: true,
  },
  phone: {
    number: "+18143519459",
    verified: true,
  },
  sex: "male",
  birthday: "01/26/2022",
  lang: "en",
  country: "GB",
  city: "London",
  address1: "14 London Road",
  zipcode: 5211,
  website: "altence.com",
  socials: {
    twitter: "@altence_team",
    facebook: "https://facebook.com/groups/1076577369582221",
    linkedin: "https://linkedin.com/company/altence",
  },
};

export const persistInactivityUser = (time: string): void => {
  localStorage.setItem("lastActivity", time);
};

export const getInactivityUser = (): string => {
  return localStorage.getItem("lastActivity") || "";
};

export const persistToken = (token: string): void => {
  localStorage.setItem("accessToken", token);
};

export const readToken = (): string => {
  return localStorage.getItem("accessToken") || "";
};

export const persistUser = (user: IUser): void => {
  localStorage.setItem("user", JSON.stringify(user));
};
export const persistAccountInfo = (accountInfo: AuthRequest): void => {
  localStorage.setItem("userInfo", JSON.stringify(accountInfo));
};

export const readUser = (): IUser | null => {
  const userStr = localStorage.getItem("user");

  return userStr ? JSON.parse(userStr) : testUser;
};

export const readUserAccountInfo = (): AuthRequest | null => {
  const userStr = localStorage.getItem("userInfo");

  return userStr ? JSON.parse(userStr) : testUser;
};

export const deleteToken = (): void => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("account_info");
  localStorage.removeItem("lastActivity");
  localStorage.removeItem("userInfo");
};

export const getAccountInfo = (): { ClientID: string; AccountID: string } => {
  const accountInfo = localStorage.getItem("account_info");
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return accountInfo ? (JSON.parse(accountInfo) as any) : null;
};

export const getClient = (): string | null => {
  const accountInfo = localStorage.getItem("account_info");
  if (accountInfo) {
    const parsedAccountDetails = JSON.parse(accountInfo);
    return parsedAccountDetails.ClientID ? parsedAccountDetails.ClientID : null;
  }
  return null;
};

export const isAccount = (): boolean => {
  const accountInfo = localStorage.getItem("account_info");
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const parsedAccountInfo = accountInfo ? (JSON.parse(accountInfo) as any) : null;

  return parsedAccountInfo ? (parsedAccountInfo.AccountID ? true : false) : false;
};

export const deleteUser = (): void => localStorage.removeItem("user");

/**
 * This function sets a header in Axios requests with a user ID.
 * @param {string} userId - The `userId` parameter is a string that
 * represents the unique identifier of
 * a user (Znode-UserId). This function sets a custom header called "Znode-UserId" in the
 * Axios HTTP client with the provided `userId` value.
 */
export const setAxiosHeader = (token: string): void => {
  httpApi.defaults.headers.common["Authorization"] = token ? `Bearer ${token}` : "";
};

export const preservePortalId = (portalId: number) => {
  const accountData = {
    Token: "",
    WebstoreUserId: 0,
    PortalId: Number(portalId),
    ParentStoreName: "",
  };
  persistAccountInfo(accountData);
  return accountData;
};

export const LOCAL_STORAGE_KEYS = {
  AUTHORIZE_DATA: "AuthorizePaymentData",
  SAVE_AUTHORIZE_FOR_FUTURE_USE: "SaveThisAccountForFutureUse",
  PRESERVE_PAGE_INDEX_AUTHORIZE: "PreservePageIndexAuthorize",
  PREVIEW_URL: "preview",
  CHILD_PORTAL_ID: "childPortalId",
  STORE_DETAILS: "storeDetails",
};

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Sets Data on LocalStorage on the basis of given key.
 * @param key
 * @param value
 */
export function setLocalStorageData(key: string, value: string) {
  if (key && value) {
    localStorage.setItem(key.toString(), value.toString());
  }
}

/**
 * Gets Data from LocalStorage on the basis of given key.
 * @param key
 * @returns
 */
export function getLocalStorageData(key: string): string {
  if (key) {
    return localStorage.getItem(key.toString()) || "";
  }
  return "";
}

/**
 * Remove Data from LocalStorage on the basis of given key.
 * @param key
 * @returns
 */
export function removeLocalStorageDataByKey(key: string) {
  if (key) {
    localStorage.removeItem(key.toString());
    return "";
  }
}

/**
 * Remove All The Data from LocalStorage
 */
export function clearLocalStorageData() {
  localStorage.clear();
}
