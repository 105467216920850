import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import "./LoadingComponent.scss";

export const LoadingComponent = () => {
  return (
    <div className="loading-wrapper">
      <LoadingOutlined />
    </div>
  );
};
