import { UsaFlagIcon } from "Icon/Icon";

export const IS_ASSOCIATED = true;
export const ACCOUNT_ID = 1;
// export const PORTAL_ID = 46;
export const DEFAULT_PAGE_INDEX = 1;
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PRODUCTS_PAGE_SIZE = 10;
export const UserId = 0;
export const ParentPortalId = 13;
export const PublishCatalogId = 10;
export const TemplateName = "BStoreCustomerTemplate";

export const Currency = "$";

export const FIVE_MB_IN_BYTES = 5242880;
export const TEN_MB_IN_BYTES = 10485760;
export const ERROR_SIZE_MSG = "File size should be 10MB or less.";
export const WEBSITE_URL = "https://www.getgivee.com";

//browser tab title's
export const GET_STARTED_PAGE_TITLE = "Get Started | Givee Select";
export const LOGIN_PAGE_TITLE = "Login | Givee Select";
export const STORE_CREATOR_PAGE_TITLE = "Store Creator | Givee Select";
export const DEFAULT_TAB_TITLE = "Givee Select";
export const RGB = "RGB";

export const HEAP_CUSTOM_EVENTS = {
  NEW_STORE_STEP: "new-store/step",
  FIRST_STORE_STEP: "first-store/step",

  STORE_OVERVIEW_SAVE: "overview/publish",
  STORE_OVERVIEW_PREVIEW: "overview/preview",
  STORE_OVERVIEW_LINK_CLICK: "overview/store-link",
  STORE_OVERVIEW_LINK_COPY: "overview/copy-link",
  STORE_OVERVIEW_RQT_DESIGN: "overview/store-design",
  STORE_OVERVIEW_RQT_PRODUCT: "overview/product-selection",
  STORE_OVERVIEW_RQT_GUESTS: "overview/guests",
  STORE_OVERVIEW_OPT_STORE_ADMIN: "overview/settings-details",

  DESIGN_LOGO: "design/store-logo",
  DESIGN_RQ_FIRST_ART: "design/upload-1",
  DESIGN_OP_SECOND_ART: "design/upload-2",
  DESIGN_OP_THIRD_ART: "design/upload-3",
  DESIGN_OP_FOURTH_ART: "design/upload-4",
  DESIGN_OP_FIFTH_ART: "design/upload-5",
  DESIGN_OP_SIXTH_ART: "design/upload-6",
  DESIGN_COLOR_PICKER: "design/personalization-checkbox",
  DESIGN_COLOR_DROPDOWN: "design/font-dropdown",
  DESIGN_PREVIEW: "design/preview",
  DESIGN_LINK_CLICK: "design/store-link",

  PRODUCT_PREVIEW: "products/preview",
  PRODUCT_LINK_CLICK: "products/store-link",
  PRODUCT_MY_SELECTION: "products/my-selection",
  PRODUCT_ALL_PRODUCTS: "products/all-products",
  PRODUCT_PRICE_DROPDOWN: "products/price-dropdown",
  PRODUCT_POD_TOGGLE: "products/pod-toggle",
  PRODUCT_CATEGORY_DROPDOWN: "products/category-dropdown",
  PRODUCT_CONFIGURE_PRODUCT: "products/configure",
  PRODUCT_DELETE_PRODUCT: "products/delete-product",

  GUESTS_DOWNLOAD_TEMPLATE: "guests/download-template",
  GUESTS_UPLOAD_TEMPLATE: "guests/upload-template",
  GUESTS_ADD_GUESTS: "guests/add-guests",
  GUESTS_MODAL_SUBMIT: "guests/modal-submit",
  GUESTS_MODAL_CANCEL: "guests/modal-cancel",

  ORDERS_DOWNLOAD_TEMPLATE: "orders/download-csv",

  BILLING_DOWNLOAD_CSV: "billing/download-csv",
  BILLING_DOWNLOAD_PDF: "billing/download-pdf",
  BILLING_PAY: "billing/pay",

  STORE_ADMIN_ORDER_NOTES: "store-admin/order-notes",
  STORE_ADMIN_STORE_TYPE: "store-admin/store-type",
  STORE_ADMIN_GIFTING_METHOD: "store-admin/gifting-method",
};

export const LOADING_STATES = {
  DESIGN_STORE_LOGO: "DESIGN_STORE_LOGO",
  DESIGN_ARTWORK_LOGO_1: "DESIGN_ARTWORK_LOGO_1",
  DESIGN_ARTWORK_LOGO_2: "DESIGN_ARTWORK_LOGO_2",
  DESIGN_ARTWORK_LOGO_3: "DESIGN_ARTWORK_LOGO_3",
  DESIGN_ARTWORK_LOGO_4: "DESIGN_ARTWORK_LOGO_4",
  DESIGN_ARTWORK_LOGO_5: "DESIGN_ARTWORK_LOGO_5",
  DESIGN_ARTWORK_LOGO_6: "DESIGN_ARTWORK_LOGO_6",
};

export const STRIPE_DISABLE_MESSAGE = "Add Stripe Connect to enable E-Commerce";

export const APPLICATION_ENVIRONMENTS = {
  LOCAL: "Local",
  STAGE: "Stage",
  DEVELOPMENT: "Development",
  PRODUCTION: "Production",
};

export const SCRIPTS = {
  BEAMER_SCRIPT: "https://app.getbeamer.com/js/beamer-embed.js",
  SALESFORCE_CHAT_BOT_SCRIPTS: [
    "https://pcna.my.salesforce.com/embeddedservice/5.0/client/liveagent.esw.min.js",
    "https://pcna.my.salesforce.com/embeddedservice/5.0/utils/common.min.js",
    "https://pcna.my.salesforce.com/embeddedservice/5.0/esw.min.js",
    "https://pcna.my.salesforce.com/embeddedservice/5.0/client/invite.esw.min.js",
  ],
};

export const EXTERNAL_LINKS = {
  CATALOG_CODED_PRICE: "https://viewer.zoomcatalog.com/pcna-givee-select-catalog-coded-pricing",
};

export const GLOBAL_ADDITIONAL_ATTRIBUTES = {
  B_STORE_GIFTING_OPTIONS: "BStoreGiftingOptions",
  B_STORE_ITEMS_LIMIT_IN_CART: "BStoreItemslimitincart",
  B_STORE_MONETORY_VALUE: "BStoreMonetoryValue",
  B_STORE_USER_VALIDATION: "BStoreUserValidation",
  B_STORE_PREMADE_CODE: "BStorePremadeCode",
  BSTORE_EMAILDOMAIN: "BstoreEmaildomain",
  STORE_DESCRIPTION: "StoreDescription",
  PRINT_TEXT_FORMAT: "PrintTextFormat",
  PRINT_TEXT_COLOR: "PrintTextColor",
  PRINT_TEXT_FONT: "PrintTextFont",
  PRINT_TEXT_FONT_CODE: "PrintTextFontCode",
  WEBSITE_URL: "WebsiteURL",
  B_STORES_MAIN_LOGO: "BStoresMainLogo",
  B_STORES_REVERSE_COLOR_LOGO: "BStoresReverseColorLogo",
  B_STORES_SINGLE_IMPRINT_LOGO: "BStoresSingleImprintLogo",
  B_STORES_PRODUCT_LOGO_4: "BStoreProductLogo4",
  B_STORES_PRODUCT_LOGO_5: "BStoreProductLogo5",
  B_STORES_PRODUCT_LOGO_6: "BStoreProductLogo6",
  B_STORES_DEFAULT_LOGO: "BStoresDefaultLogo",
  TWO_FACTOR_AUTH: "TwoFactorAuth",
  STORE_TYPE: "StoreType",
  STRIPE_ID: "StripeId",
  BSTORE_ADDITIONAL_INSTRUCTIONS: "BstoreAdditionalInstructions",
  DISCONNECT_STRIPE: "DisconnectStripe",
  HIDE_PRICING_AND_INVENTORY: "HidePricingandInventory",
  HIDE_SKU: "HideSKU",
};

export const PRODUCT_ADDITIONAL_ATTRIBUTES = {
  BOOK_N_RUN: "BookNRun",
  CALL_FOR_PRICING: "CallForPricing",
  COLOR: "Color",
  CONTAINER: "Container",
  DECO_METHOD: "DecoMethod",
  DISCOUNT_CODE: "DiscountCode",
  REFERENCE_CODE: "ReferenceCode",
  DISPLAY_VARIANTS_ON_GRID: "DisplayVariantsOnGrid",
  FEATURE_DESCRIPTION: "FeatureDescription",
  FONT_COLOR: "Fontcolor",
  FREE_SHIPPING: "FreeShipping",
  HIDE_FROM_SEARCH: "HideFromSearch",
  IS_ACTIVE: "IsActive",
  IS_DOWNLOADABLE: "IsDownloadable",
  IS_OBSOLETE: "IsObsolete",
  ITEM_NUMBER: "ItemNumber",
  LOCATION: "Location",
  LONG_DESCRIPTION: "LongDescription",
  MASTER_CATALOG: "MasterCatalog",
  MAXIMUM_QUANTITY: "MaximumQuantity",
  MINIMUM_QUANTITY: "MinimumQuantity",
  OUT_OF_STOCK_OPTIONS: "OutOfStockOptions",
  PACKAGE_SIZE_FROM_REQUEST: "PackageSizeFromRequest",
  PERSONALIZED: "Personalized",
  PRINT_ON_DEMAND: "PrintOnDemand",
  PRODUCT_CODE: "ProductCode",
  PRODUCT_IMAGE: "ProductImage",
  PRODUCT_LOGO: "ProductLogo",
  PRODUCT_NAME: "ProductName",
  PRODUCT_SPECIFICATION: "ProductSpecification",
  PRODUCT_TYPE: "ProductType",
  SHIPPING_COST_RULES: "ShippingCostRules",
  SHORT_DESCRIPTION: "ShortDescription",
  SIZE: "Size",
  SKIP_PERSONALIZATION_FONT_COLOR: "SkipPersonalizationFontColor",
  SKU: "SKU",
  SO_A_HEIGHT: "SoAHeight",
  SO_AUOM: "SoAUOM",
  SO_A_WIDTH: "SoAWidth",
  PRODUCT_US_ONLY: "USOnly",
  PRODUCTS_ALL_DECO_METHODS_VALUE: "All Decoration methods",
  PRODUCTS_ALL_DECO_METHODS_CODE: "",
};

export const PUBLISH_STATES = {
  PREVIEW: "PREVIEW",
  PUBLISH: "PUBLISH",
  PRODUCTION: "PRODUCTION",
  NONE: "NONE",
};

export const LAUNCH_STATES = {
  LAUNCHED: "1",
  NOTLAUNCHED: "0",
};

export const ERROR_MESSAGES = {
  SOMETHING_WENT_WRONG_ERR_MESSAGE: "Something went wrong",
  STORE_LOGO_ERR_MESSAGE: "Please upload store logo",
  PRINT_LOGO_ERR_MESSAGE: "Please upload at least one print logo",
  TRY_LATER_ERR_MESSAGE: "Please try again later",
};

export const COUNTRY_PHONE_CODES = [
  {
    flag: UsaFlagIcon,
    code: "+1",
  },
];
