import React from "react";

interface Props {
  children: React.ReactNode;
}

const RequireAuth: React.FC<Props> = ({ children }) => {
  // const token = useAppSelector((state) => state.auth.token);
  return <>{children}</>;
};

export default RequireAuth;
