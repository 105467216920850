/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import { Typography, Form, Row, Button, Col, Space, Tag } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
// import StepsComponent from "components/Common/Steps/Steps";
import { useAppSelector } from "hooks/reduxHooks";
import { numberWithCommas } from "utils";
import { DEFAULT_PAGE_INDEX } from "config/SpokeCustom.Constant";
import "./ExistingStripeAccount.scss";
import StripeCustomRadioButton from "./StripeCustomRadioButton";
import { LoadingComponent } from "components/SpokeCustomComponents/LoadingComponent/LoadingComponent";
import {
  generateStripeAccount,
  getStoresWithStripeDetails,
  updateStripeDetails,
} from "api/Services/SpokeCustumAPIs/stripe.api";
import { ExportOutlined } from "@ant-design/icons";
import { notificationController } from "components/Common/Notification/Notification";
import { useNavigate } from "react-router-dom";
import StripeModal from "./stripeModal";
import Pager from "components/SpokeCustomComponents/SharedComponents/Pager/Pager";
import {
  IExistingStripeAccountProps,
  IStoresStripeList,
  IStripePageData,
  IStripeValueInForm,
} from "interfaces/ClientInterface/SpokeCustumApiInterface/IAboutStore";
import { IStripeDetails } from "interfaces/ClientInterface/SpokeCustumApiInterface/IStoreAdmin";
import { IStoresWithStripeResponse } from "interfaces/ApiInterface/SpokeCustumApiInterface/IStores";

const { Title } = Typography;

const ExistingStripeAccount: React.FC<IExistingStripeAccountProps> = (props) => {
  const { form } = props;
  const navigate = useNavigate();
  const childPortalId = useAppSelector((state) => state.childPortal.id);
  const userInfo = useAppSelector((state) => state.auth.userInfo);
  const UserId = userInfo?.WebstoreUserId;
  const DEFAULT_PAGE_SIZE_STRIPE = 5;
  const [loading, setLoading] = useState<boolean>(false);
  const [stripeDataloading, setStripeDataloading] = useState<boolean>(false);
  const [existingStripeData, setExistingStripeData] = useState<IStoresStripeList[]>([]);
  const [StripePaginationData, setStripePaginationData] = useState<IStripePageData>({
    PageIndex: DEFAULT_PAGE_INDEX,
    PageSize: DEFAULT_PAGE_SIZE_STRIPE,
    TotalResults: 0,
  });
  const [pageRedirectingModal, setPageRedirectingModal] = useState<boolean>(false);
  const [pageRedirectingError, setPageRedirectingError] = useState<boolean>(false);

  const strpieIdFormValue = Form.useWatch("existingStripeDetails", {
    form,
    preserve: true,
  });

  const handleSkip = () => {
    navigate("/dashboard/stores");
  };

  const nextClickHandler = async () => {
    try {
      await form.validateFields(["existingStripeDetails"]);
      if (strpieIdFormValue) {
        copyStripeAccount();
      } else {
        notificationController.error({
          message: "Please setup or select Stripe account before proceeding further",
        });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const validateStripeConnect = (stripeDetails: IStripeDetails) => {
    if (
      stripeDetails.StripeChargesEnabled &&
      stripeDetails.StripePayoutsEnabled &&
      stripeDetails.StripeDetailsSubmitted
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getStatusTag = (stripeDetails: IStoresWithStripeResponse) => {
    const isSuccess = validateStripeConnect(stripeDetails);
    return (
      <Tag color={isSuccess ? "success" : "processing"}>{isSuccess ? "Verified" : "In review"}</Tag>
    );
  };

  useEffect(() => {
    setStripeDataloading(true);
    getStoresWithStripeDetails(
      UserId || 0,
      StripePaginationData.PageSize,
      StripePaginationData.PageIndex
    )
      .then((res) => {
        const stripeResponse = res?.data?.AccountList?.spokeCustomBstoreStripAccountModels;
        const mappedStripeData = stripeResponse?.map((res: IStoresWithStripeResponse) => {
          return {
            storePortalId: res?.PortalId,
            storeName: res?.StoreName,
            stripeId: res?.StripeId,
            status: getStatusTag(res),
          };
        });

        setExistingStripeData(mappedStripeData);

        setStripePaginationData((prevState: IStripePageData) => ({
          ...prevState,
          TotalResults: stripeResponse?.[0]?.RowCount,
        }));
      })
      .catch(() => {
        notificationController.error({
          message: "Failed to load Stores with stripe details",
        });
      })
      .finally(() => {
        setStripeDataloading(false);
      });

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [StripePaginationData.PageIndex, StripePaginationData.PageSize]);

  const handleRadioClick = (value: IStripeValueInForm) => {
    // set the value for radio option
    form.setFields([
      //set the value for radio option
      {
        name: "existingStripeDetails",
        value: value,
        errors: [],
      },
    ]);
  };

  const renderCard = (item: IStoresStripeList) => {
    const isActive = strpieIdFormValue?.storePortalId == item?.storePortalId;

    return (
      <Col span={24} key={item?.storeName}>
        <StripeCustomRadioButton
          onClickHandler={handleRadioClick}
          isSelected={isActive}
          storeName={item?.storeName}
          status={item?.status}
          uniqueID={item?.stripeId}
          value={item}
        />
      </Col>
    );
  };

  const onCreateStripeAccount = () => {
    setLoading(true);
    setPageRedirectingModal(true); //Open redirect message modal

    const body = {
      Email: userInfo?.Email || "",
      RedirectURL: `${window.location.origin}/redirect/${childPortalId}`,
      PortalID: childPortalId,
      UserId: userInfo?.WebstoreUserId || 0,
    };
    generateStripeAccount(body)
      .then((response) => {
        if (response?.data?.IsSuccess) {
          const stripeURL = response?.data?.Custom1;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const win = window as any;
          win.open(stripeURL, "_self").focus();
        } else {
          setPageRedirectingError(true); //Set Error flag
          notificationController.error({
            message: response?.data?.ErrorMessage,
          });
        }
      })
      .catch(() => {
        setLoading(false);
        setPageRedirectingError(true); //Set Error flag
        notificationController.error({
          message: "Failed to generate the stripe account onboarding link",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const copyStripeAccount = async () => {
    setLoading(true);
    await updateStripeDetails(strpieIdFormValue?.storeStripeid || "", childPortalId?.toString())
      .then(() => {
        navigate("/dashboard/stores");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {stripeDataloading ? (
        <Space className="d-flex justify-center align-center h-300">
          <LoadingComponent />
        </Space>
      ) : (
        <div className="Existing-stripe-container">
          <Title
            level={3}
            className="my-1"
            style={{ marginBottom: "25px" }}
            data-test-selector="hdgBringStoreToLive"
          >
            Create a new Stripe Connect account to enable payments, or select a previously added
            one.
          </Title>
          <div className="mb-45">
            {existingStripeData?.length > 0 && (
              <Form.Item name="existingStripeDetails">
                <Row gutter={[24, 24]} className="store-type-container">
                  {existingStripeData?.map((item: IStoresStripeList) => {
                    return renderCard(item);
                  })}
                </Row>
              </Form.Item>
            )}
          </div>
          <Button
            block
            size="middle"
            type="primary"
            onClick={onCreateStripeAccount}
            data-test-selector="btnCreateStripe"
            icon={<ExportOutlined />}
            iconPosition="end"
            loading={loading}
            disabled={loading}
          >
            Create a new Stripe Connect account
          </Button>
          <Title level={5} className="input-bottom-info mt-8">
            You&apos;ll be directed to Stripe to create your account and redirected back once
            it&apos;s complete. The setup is quick and easy.
          </Title>
          {existingStripeData?.length > 0 && StripePaginationData.TotalResults > 5 && (
            <div className="mb-32">
              <Pager
                size="small"
                defaultCurrent={1}
                current={StripePaginationData?.PageIndex}
                pageSize={StripePaginationData?.PageSize}
                total={StripePaginationData?.TotalResults}
                onChange={(page, pageSize) => {
                  setStripePaginationData((prevState: IStripePageData) => ({
                    ...prevState,
                    PageIndex: page,
                  }));
                  if (pageSize) {
                    setStripePaginationData((prevState: IStripePageData) => ({
                      ...prevState,
                      PageSize: pageSize,
                    }));
                  }
                }}
                showSizeChanger
                onShowSizeChange={(current, pageSize) => {
                  setStripePaginationData((prevState: IStripePageData) => ({
                    ...prevState,
                    PageIndex: 1,
                    PageSize: pageSize,
                  }));
                }}
                showTotal={(total, range) => (
                  <span>
                    Showing <b>{numberWithCommas(range[0])}</b> to
                    <b> {numberWithCommas(range[1])}</b> of
                    <b> {numberWithCommas(total)}</b> items
                  </span>
                )}
                itemRender={(current, type, originalElement) => {
                  if (type === "page") {
                    return (
                      <a
                        style={
                          current === StripePaginationData.PageIndex
                            ? { backgroundColor: "#4D9CD4", color: "#ffffff" }
                            : {}
                        }
                        href={`#${current}`}
                        onClick={(e) => {
                          e.preventDefault();
                          setStripePaginationData((prevState: IStripePageData) => ({
                            ...prevState,
                            PageIndex: current,
                          }));
                        }}
                      >
                        {numberWithCommas(current)}
                      </a>
                    );
                  }
                  return originalElement;
                }}
                pageSizeOptions={[5, 10, 20, 50]}
              />
            </div>
          )}
          <Row className="my-1 btn-section">
            <Button
              className="text"
              data-test-selector="divSkipForNow"
              type="text"
              onClick={handleSkip}
              disabled={loading}
            >
              Skip for now
              <ArrowRightOutlined />
            </Button>
            <div className="d-flex">
              <Form.Item>
                <Button
                  htmlType="submit"
                  className="gray-bg-button"
                  onClick={nextClickHandler}
                  data-test-selector="btnNext"
                  disabled={loading}
                  loading={loading}
                >
                  Next
                </Button>
              </Form.Item>
            </div>
          </Row>
          <StripeModal
            loading={loading}
            createNew={onCreateStripeAccount}
            openModal={pageRedirectingModal}
            errorOccured={pageRedirectingError}
            handleCancel={() => {
              setPageRedirectingModal(false);
            }}
          />
        </div>
      )}
    </>
  );
};

export default ExistingStripeAccount;
