/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { updateStripeDetails } from "api/Services/SpokeCustumAPIs/stripe.api";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { getAllStoresData } from "api/Services/SpokeCustumAPIs/allStores.api";
import { IStoresData } from "interfaces/ApiInterface/SpokeCustumApiInterface/IStores";
import { getPreviewUrl, setChildPortalId } from "store/Slices/childPortal.slice";
import { setStoreDetails } from "store/Slices/storeDetailsSlice";
import { LOCAL_STORAGE_KEYS, setLocalStorageData } from "api/Services/localStorage.service";

const StripeRedirectPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector((state) => state.auth.userInfo);
  const ParentPortalId = userInfo?.PortalId;
  const UserId = userInfo?.WebstoreUserId;

  const { portalId, stripeAccountId } = useParams<Record<string, string | undefined>>();

  useEffect(() => {
    if (portalId && stripeAccountId) {
      updateStripeDetails(stripeAccountId, portalId)
        .then(async () => {
          const res = await getAllStoresData(UserId || 0, ParentPortalId || 0);
          const allStoreData = res?.data?.BStores?.BStores;

          const storeDetails = allStoreData?.find((item: IStoresData) => {
            return item.PortalId?.toString() == portalId;
          });

          //setting preview details
          dispatch(getPreviewUrl(storeDetails.Custom3));
          setLocalStorageData(LOCAL_STORAGE_KEYS.PREVIEW_URL, storeDetails.Custom3);

          //set child portal
          dispatch(setChildPortalId(Number(portalId)));
          setLocalStorageData(LOCAL_STORAGE_KEYS.CHILD_PORTAL_ID, portalId);

          //setting store details in local storage
          const newStoreDetails = {
            parentCompanyName: storeDetails?.Custom1,
            storeStatus: storeDetails?.IsActive,
            storeStoreLaunched: storeDetails?.Custom4,
            storeName: storeDetails?.StoreName,
            openDate: storeDetails?.OpenDate,
            closeDate: storeDetails?.CloseDate,
            DomainURL: storeDetails?.DomainURL,
            IsApproved: storeDetails?.IsApproved,
          };

          setLocalStorageData(LOCAL_STORAGE_KEYS.STORE_DETAILS, JSON.stringify(newStoreDetails));
          dispatch(setStoreDetails(newStoreDetails));

          setTimeout(() => {
            navigate("/dashboard/storeadmin");
          }, 2000);
        })
        .catch(() => {
          navigate("/dashboard/stores");
        });
    } else {
      navigate("/dashboard/stores");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div className="d-flex justify-center align-center suspense-loader-wrapper">
      Please wait while redirecting...
    </div>
  );
};

export default StripeRedirectPage;
