/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-lines */
import { ORDER_LIST_DAY } from "config/constant";
import type { RcFile } from "antd/es/upload/interface";
import { notificationController } from "components/Common/Notification/Notification";
import {
  DEFAULT_TAB_TITLE,
  ERROR_SIZE_MSG,
  TEN_MB_IN_BYTES,
  WEBSITE_URL,
} from "config/SpokeCustom.Constant";
import { AuthRequest } from "interfaces/ClientInterface/IAuth";
import {
  IAdditionalAttribute,
  IProductsAdditionalAttribute,
} from "interfaces/ClientInterface/SpokeCustumApiInterface/IGlobal";
import { IOrderData } from "interfaces/ApiInterface/SpokeCustumApiInterface/IOrder";
import { orderDataForCsv } from "interfaces/ClientInterface/IOrder";
import {
  clearLocalStorageData,
  preservePortalId,
  readUserAccountInfo,
  setAxiosHeader,
} from "api/Services/localStorage.service";
import { logoutUser } from "api/Services/SpokeCustumAPIs/login.api";

export const REGEX = {
  validUsernameRegex:
    // eslint-disable-next-line max-len, no-useless-escape
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
  passwordStrengthRegex: /^(?=.*[0-9])(?=.*[a-zA-Z]).{8,}$/i,
  validPostalCodeRegex: /^[0-9-]*$/i,
  validAlphabetRegex: /^[a-zA-Z]+$/i,
  validEmailAddressRegex: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i,
  validPhoneNumberRegex: /^[0-9+-]*$/i,
  validPriceRegex: /^\d+(\.\d+)?$/,
  validFiveDigitsBefore: /^\d{1,5}(\.\d+)?$/,
  validTwoDigitsAfter: /^\d+(\.\d{1,2})?$/,
  validDomainCSV: /^([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}(,\s*([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,})*$/,
};

export const isValidEmail = (email: string) => {
  return REGEX.validEmailAddressRegex.test(email);
};

export const camelize = (string: string): string => {
  return string
    .split(" ")
    .map((word, index) =>
      index === 0 ? word.toLowerCase() : word[0].toUpperCase() + word.slice(1)
    )
    .join("");
};

export const getDate = (date: string): string => {
  if (!date) return "";
  return date.split("T")[0];
};

export const getCurrencyPrice = (price: number, currency: string): string => {
  switch (currency) {
    case "USD": {
      return `$${price}`;
    }

    default: {
      return `$${price}`;
    }
  }
};

export const parseJwt = (token: string) => {
  if (!token) {
    return;
  }
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
};

export const capitalize = (word: string): string => `${word[0].toUpperCase()}${word.slice(1)}`;

export const trimStr = (str: string, charCount = 15) => {
  if (!str) return "";
  const res = str.substring(0, charCount);
  return res + "...";
};

export const isMorePages = (dataLength: number, perPageRecord = 10) => {
  dataLength = dataLength ? dataLength : 0;
  return dataLength > perPageRecord ? true : false;
};

export const dateFormate = (date: string) => {
  const dateData = new Date(date);
  const options: Intl.DateTimeFormatOptions = { month: "long", day: "numeric", year: "numeric" };
  const formattedDate = dateData.toLocaleDateString("en-US", options);
  return formattedDate;
};

/** Before uploading media image */
export const beforeUpload = (file: RcFile) => {
  const fileName = file.name.replace(/\.[^/.]+$/, "").length < 146;

  if (!fileName) {
    notificationController.error({
      message: "File name should not contain more than 145 characters",
    });
  }
  return fileName;
};

export const beforeFeviconUpload = (file: RcFile) => {
  const isValidFevicon =
    file.type === "image/x-icon" || file.type === "image/png" || file.type === "image/svg";

  if (!isValidFevicon) {
    notificationController.error({
      message: "You can only upload ICO/PNG",
    });
  }

  return isValidFevicon;
};

export const designFeviconUploadCheck = (file: RcFile): Promise<boolean> => {
  const MAX_WIDTH_HEIGHT_DIMENSION = 512;
  const isValidFevicon = file.type === "image/png" || file.type.includes("svg");

  return new Promise((resolve) => {
    const reader = new FileReader();

    if (!isValidFevicon) {
      notificationController.error({
        message: "You can upload PNG and SVG files only.",
      });
      resolve(false);
      return;
    }

    reader.readAsDataURL(file);
    reader.addEventListener("load", (event) => {
      const _loadedImageUrl = event.target?.result;
      const image = document.createElement("img");
      image.src = _loadedImageUrl as string;

      image.addEventListener("load", () => {
        const { width, height } = image;

        if (width !== height) {
          notificationController.error({
            message:
              "The image you're trying to upload must have equal width and height dimensions.",
          });
          resolve(false);
          return;
        }

        if (width > MAX_WIDTH_HEIGHT_DIMENSION) {
          notificationController.error({
            message:
              "The dimensions of the image width and height should not exceed 512 x 512 pixels.",
          });
          resolve(false);
          return;
        }

        if (!(file?.size <= TEN_MB_IN_BYTES)) {
          notificationController.error({
            message: ERROR_SIZE_MSG,
          });
          resolve(false);
          return;
        }

        resolve(true);
      });
    });
  });
};

// eslint-disable-next-line no-unused-vars
export const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

export const dateCovertFormate = (inputDate: string) => {
  const dateObj = new Date(inputDate);
  const formattedDate = `${(dateObj.getMonth() + 1).toString().padStart(2, "0")}/${dateObj
    .getDate()
    .toString()
    .padStart(2, "0")}/${dateObj.getFullYear()}`;
  return formattedDate;
};

// Covert date formate with date and time
export function convertDateWithTime(inputDate: string) {
  const dateObj = new Date(inputDate);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const options: any = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true, // Use 12-hour clock with AM/PM
  };

  // Format the date using the Intl.DateTimeFormat object
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(dateObj);

  return formattedDate;
}

// Generate Dynamic Url
export function generateDynamicUrl(
  apiUrl: string,
  pageIndex: number,
  pageSize: number,
  userId?: number | null,
  omsTypeName?: string | null,
  omsTypeId?: number | null,
  portalId?: number | null,
  sort?: string | null,
  filter?: string | null,
  startDate?: string | null, // New parameter for start date
  endDate?: string | null // New parameter for end date
): string {
  const base_url = `${process.env.REACT_APP_API_URL}/${apiUrl}`;

  // Building userObj and dateRange string based on the conditions
  const userObj = omsTypeName && omsTypeId ? `${omsTypeName}~eq~${omsTypeId},` : "";
  const dateRange = startDate && endDate ? `datetimerange~bw~'${startDate}' and '${endDate}',` : "";

  // Building the page string
  const page = `index~${pageIndex},size~${apiUrl === "Quote/List" ? "+" : " "}${pageSize}`;

  // Determining the publishState based on apiUrl
  const publishState = apiUrl === "Quote/List" ? "PRODUCTION" : "";

  // Constructing the final URL
  return `${base_url}?expand=&filter=${
    apiUrl === "Orders/List" && userId ? `UserId~eq~${userId},` : ""
  }${filter ? `${filter},` : ""}${
    apiUrl === "Orders/List" && portalId ? `portalId~eq~${portalId},` : ""
  }${apiUrl === "Quote/List" ? userObj : ""}${dateRange}&sort=${sort || ""}${
    apiUrl === "Orders/List" && userId ? "" : apiUrl === "Orders/List" ? "~DESC" : ""
  }&page=${page}&publishState=${publishState}${apiUrl === "Orders/List" ? "&cache=refresh" : ""}`;
}

// Generate Import Log Url
export const generateBaseUrl = (
  apiUrl: string,
  filterName: string | null,
  templateName: string,
  sortName: string | null,
  page: number,
  size: number
) => {
  const base_url = `${process.env.REACT_APP_API_URL}/${apiUrl}`;
  const filter = `${filterName}~is~${templateName}`;
  const sort = `${sortName}~DESC`;

  return `${base_url}?expand=&filter=${filterName ? filter : ""}&sort=${
    sortName ? sort : ""
  }&page=index~${page},size~${size}&publishState=`;
};

// Download Template
// Get Lasted Date
export const getLastedDate = () => {
  const today = new Date();
  const daysAgo = new Date();
  daysAgo.setDate(today.getDate() - ORDER_LIST_DAY);

  const month = String(daysAgo.getMonth() + 1).padStart(2, "0");
  const day = String(daysAgo.getDate()).padStart(2, "0");
  const year = daysAgo.getFullYear();

  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
};

// Validate Subdomain url
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const validateSubdomainInput = (_: any, value: string) => {
  const regex = /^(?!www\.|https?:\/\/)[a-zA-Z0-9]+([-.]{1}[a-zA-Z0-9]+)?$/;
  if (!regex.test(value)) {
    return Promise.reject("Please enter a correct subdomain URL");
  }
  return Promise.resolve();
};

/** Email regex validation */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const validateUserName = (_: any, value: string) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (!value || value.match(emailRegex)) {
    return Promise.resolve();
  }
  return Promise.reject("Please enter a valid Email or Username.");
};

export const currencyFormatWithTwoDecimals = (
  value: string | number | null | undefined,
  currency = "$"
) => {
  const numericValue = Number(value);
  if (!isNaN(numericValue)) {
    return (
      currency +
      numericValue?.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: true,
      })
    );
  } else {
    return "$0.00";
  }
};

export const numberWithCommas = (value: string | number | null | undefined) => {
  const numericValue = Number(value);
  if (!isNaN(numericValue)) {
    return numericValue.toLocaleString("en-US");
  } else {
    // prettier-ignore
    return 0.00;
  }
};

export const numberWithTwoDecimals = (value: string | number | null | undefined) => {
  const numericValue = Number(value);
  if (!isNaN(numericValue)) {
    return numericValue.toFixed(2);
  } else {
    // prettier-ignore
    return 0.00;
  }
};

export const isPasswordStrength = (password: string) => {
  return password && REGEX.passwordStrengthRegex.test(password);
};

export const LogoClickHandler = () => {
  const anchor = document.createElement("a");
  anchor.target = "_blank";
  anchor.href = WEBSITE_URL;
  anchor.click();
};

export const updateBrowserTabTitle = (title: string) => {
  document.title = title || DEFAULT_TAB_TITLE;
};

export const decodeSpecialCharsString = (text: string) => {
  const parser = new DOMParser();
  const decodedString = parser.parseFromString(`<!doctype html><body>${text}`, "text/html").body
    .textContent;
  return decodedString;
};

export const isPostalCodeValid = (postalCode: string) => {
  return postalCode && REGEX.validPostalCodeRegex.test(postalCode);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isObjectEmpty = (obj: any = {}) => {
  return Object.keys(obj).length === 0;
};

/**
 * The function setHeapEntries sets user information in the Heap analytics tool for tracking user
 * behavior.
 * @param {AuthRequest} userInfo - The `userInfo` parameter is an object of type `AuthRequest`
 * Dashboard site: https://heapanalytics.com
 */
export const setHeapEntries = (userInfo: AuthRequest) => {
  const win: any = window; //eslint-disable-line
  win.heap.identify(userInfo?.Email);
  win.heap.addUserProperties({
    Name: userInfo?.FullName,
    RoleName: userInfo?.RoleName,
    UserId: userInfo?.WebstoreUserId,
  });
};

/**
 * The function `setLuckOrangeIdentity` is used to track events or identify a visitor using LuckOrange
 * integration in TypeScript.
 * @param {AuthRequest} userInfo - The `userInfo` parameter in the `setLuckOrangeIdentity` function is
 * an object of type `AuthRequest`.
 * Dashboard site: https://app.luckyorange.com
 */
export const setLuckOrangeIdentity = async (userInfo: AuthRequest) => {
  const win: any = window;
  if (win && win.LOQ) {
    win.LOQ = win.LOQ || [];
    win.LOQ.push([
      "ready",
      function (LO: any) {
        // Identify a visitor
        LO.$internal.ready("visitor").then(function () {
          LO.visitor.identify({
            email: userInfo?.Email?.toString(),
          });
        });
      },
    ]);
  }
};

/**
 * The function `clearLuckyOrangeEventProperties` clears and expires visitor properties in the Lucky
 * Orange event tracking system.
 */
export const clearLuckyOrangeEventProperties = () => {
  const win: any = window;
  if (win && win.LOQ) {
    win.LOQ = win.LOQ || [];
    win.LOQ.push([
      "ready",
      function (LO: any) {
        // Clear visitor's session
        LO.$internal.ready("session").then(function () {
          LO.session.clear();
        });
      },
    ]);
  }
};

/**
 * The function `setCustomHeapEvents` tracks a custom event using the Heap analytics tool.
 * @param {string} eventName - The `eventName` parameter is a string that represents the name of the
 * custom event that you want to track using the Heap analytics tool.
 */
export const setCustomHeapEvents = (eventName: string) => {
  const win: any = window; //eslint-disable-line
  if (win.heap) {
    win.heap.track(eventName);
  }
};

/**
 * The function `clearHeapEventProperties` clears event properties in the Heap analytics tool.
 */
export const clearHeapEventProperties = () => {
  const win: any = window; //eslint-disable-line
  if (win.heap) {
    win.heap.clearEventProperties();
  }
};

/**
 * The function `resetHeapIdentity` resets the identity in the Heap analytics tool.
 */
export const resetHeapIdentity = () => {
  const win: any = window; //eslint-disable-line
  if (win.heap) {
    win.heap.resetIdentity();
  }
};

export function safeJsonParse(jsonString?: string | null) {
  try {
    if (jsonString) {
      return JSON.parse(jsonString);
    } else return undefined;
  } catch (error) {
    return undefined;
  }
}

export const splitFullName = (fullName: string) => {
  // Split the full name by space
  const nameParts = fullName?.split(" ");

  // If there's only one part, assume it's the first name
  if (nameParts?.length === 1) {
    return [nameParts[0], ""];
  } else {
    // Otherwise, the last part is the last name
    const lastName = nameParts?.pop();
    // The rest are considered the first name
    const firstName = nameParts?.join(" ");
    return [firstName, lastName];
  }
};

export const downloadCSV = (csvData: string, fileName: string) => {
  const BOM = "\uFEFF";
  const csvContent = BOM + csvData;
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `${fileName}.csv`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

export const loadScript = (src: string, isAsync: boolean) => {
  const script = document.createElement("script");
  script.setAttribute("src", src);
  script.setAttribute("type", "text/javascript");
  script.setAttribute("async", `${isAsync}`);
  document.body.appendChild(script);
};

export const removeScript = (srcArray: string[]) => {
  srcArray.forEach((src) => {
    const scriptElements = document.querySelectorAll(`script[src='${src}']`);
    scriptElements.forEach((script) => script.remove());
  });
};

/**
 * This TypeScript function retrieves the attribute value from a list of additional attributes based on
 * a specified attribute value.
 * @param {IAdditionalAttribute[]} attributeList - The `attributeList` parameter is an array of objects
 * of type `IAdditionalAttribute`.
 * @param {string} attributeValue - The `attributeValue` parameter is the value that you want to search
 * for within the `attributeList`. The function `getAttributeValueFromList` takes in a list of
 * additional attributes (`attributeList`) and searches for an attribute with a specific code matching
 * the `attributeValue`. If a match is found
 * @returns The `getAttributeValueFromList` function returns the `AttributeValue` of the first item in
 * the `attributeList` array that has an `AttributeCode` matching the `attributeValue` parameter.
 */
export const getAttributeValueFromList = (
  attributeList: IAdditionalAttribute[],
  attributeValue: string
) => {
  const data = attributeList?.filter((a: IAdditionalAttribute) => {
    if (a?.AttributeCode == attributeValue) {
      return a;
    }
  })?.[0]?.AttributeValue;
  return data;
};

/**
 * List of Attribute Value's - values are ending with "s".
 * This TypeScript function finds attribute values from a list based on a specified attribute value.
 * @param {IProductsAdditionalAttribute[]} attributeList - The `attributeList` parameter is an array of
 * objects of type `IProductsAdditionalAttribute`.
 * @param {string} attributeValue - The `attributeValue` parameter is a string that represents the
 * attribute code you want to search for in the list of `IProductsAdditionalAttribute` objects.
 * @returns The function `findAttributeValuesFromList` returns the `AttributeValues` of the first item
 * in the `attributeList` array that has an `AttributeCode` matching the `attributeValue` parameter.
 */
export const findAttributeValuesFromList = (
  attributeList: IProductsAdditionalAttribute[],
  attributeValue: string
) => {
  const data = attributeList?.filter((a: IProductsAdditionalAttribute) => {
    if (a?.AttributeCode == attributeValue) {
      return a;
    }
  })?.[0]?.AttributeValues;
  return data;
};

export const convertOrderDataToCSVFormat = (data: IOrderData[]): string => {
  const csvHeader = [
    "B-Store name",
    "Order",
    "Guest first name",
    "Guest last name",
    "Item",
    "Quantity",
    "SKU",
    "Guest email",
    "Date ordered",
    "Store Price $",
    "Coded Price $",
    "Net Price $",
    "Shipping $",
    "Value $",
    "Status",
    "Tracking number",
    "Street 1",
    "Street 2",
    "City",
    "State",
    "PostalCode",
    "Country",
  ];

  const csvData = data?.map((item: IOrderData) => {
    // prettier-ignore
    const calculateValue =
      (Number(item.ItemCount) * Number(item.SalesPrice)) + Number(item.LineItemShipping);
    return {
      StoreName: `"${item.StoreName}"`,
      Order: `"${item.OrderNumber}"`,
      GuestFirstName: `"${item.FirstName}"`,
      GuestLastName: `"${item.LastName}"`,
      Item: `"${item.OrderItem}"`,
      Quantity: `"${item.ItemCount}"`,
      SKU: `"${item.Sku}"`,
      GuestEmail: `"${item.Email}"`,
      DateOrdered: ` ${dateCovertFormate(item.OrderDate)}`,
      StorePrice: `${numberWithTwoDecimals(item.SalesPrice)}`,
      CodedPrice: `${numberWithTwoDecimals(item.SuggestedMSRP)}`,
      NetPrice: `${numberWithTwoDecimals(item.CostPrice)}`,
      Shipping: `${numberWithTwoDecimals(item.LineItemShipping)}`,
      Value: `${numberWithTwoDecimals(calculateValue)}`,
      Status: item.OrderState === "Ready for ERP" ? "Submitted" : item.OrderState,
      TrackingNumber: item.TrackingNumber ? `="${item.TrackingNumber}"` : "",
      Street1: `"${item.Street1}"`,
      Street2: `"${item.Street2}"`,
      City: `"${item.City}"`,
      State: `"${item.State}"`,
      PostalCode: `"${item.BillingPostalCode}"`,
      Country: `"${item.Country}"`,
    };
  });

  const header = Object.keys(csvData[0]);
  const csv = [
    csvHeader.join(","),
    ...csvData.map((row) =>
      header.map((field) => row[field as keyof orderDataForCsv] ?? "").join(",")
    ),
  ].join("\n");

  return csv;
};

export const storeNameToDomainSlug = (storeName: string) => {
  const urlFriendlyName = storeName
    ?.trim() //Trim Whitespace
    ?.replace(/\s+/g, "-") //Replace Spaces with Hyphens
    ?.replace(/[^a-zA-Z0-9-]/g, "") //Remove Non-Alphanumeric Characters (except hyphens)
    ?.replace(/-+/g, "-") //Consolidate Multiple Hyphens
    ?.toLowerCase() //Convert to Lowercase
    ?.replace(/^-+|-+$/g, ""); //Trim hyphens from the start and end
  return urlFriendlyName;
};

export const LogoutHandler = () => {
  //clearing session and redirecting to landing page
  const userInfo = readUserAccountInfo();
  const PortalId = userInfo?.PortalId ? userInfo?.PortalId : 0;
  logoutUser(PortalId)
    .then(() => {
      clearLocalStorageData();
      setAxiosHeader("");

      // Clearing HEAP and Lucky Orange cookies and cache
      clearHeapEventProperties();
      resetHeapIdentity();
      clearLuckyOrangeEventProperties();

      setTimeout(() => {
        preservePortalId(PortalId);

        /**
         * navigating with reload to clear beamer and salesforce
         * chat bots scripts
         */
        window.location.href = "/dashboard/login";
      });
    })
    .catch(() => {
      notificationController.error({
        message: "Something went wrong",
      });
    });
};

export const trimObjectValues = (obj: { [key: string]: any }): { [key: string]: any } => {
  if (obj && typeof obj === "object" && !Array.isArray(obj)) {
    for (const key in obj) {
      if (typeof obj[key] === "string") {
        obj[key] = obj[key].trim();
      } else if (typeof obj[key] === "object") {
        trimObjectValues(obj[key]);
      }
    }
  }
  return obj;
};

// prettier-ignore
export const addTimestampToFilename = (fileName: string): string => {
  const now = new Date();
  // Formatting the date components
  const MM = (now.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based, so we add 1
  const dd = now.getDate().toString()
    .padStart(2, "0");
  const YYYY = now.getFullYear().toString();
  const HH = now.getHours().toString()
    .padStart(2, "0");
  const mm = now.getMinutes().toString()
    .padStart(2, "0");
  const SS = now.getSeconds().toString()
    .padStart(2, "0");

  const timestamp = `${MM}${dd}${YYYY}${HH}${mm}${SS}`;

  return `${timestamp}__delim__${fileName}`;
};

export const removeTimestampFromFilename = (filename: string): string => {
  const delimiter = "__delim__";
  const pattern = /\d{8}\d{6}__delim__/; // Pattern to match MMddYYYYHHmmSS__delim__

  // Check if the filename matches the expected pattern
  if (pattern.test(filename)) {
    return filename.split(delimiter)[1];
  } else {
    return filename;
  }
};
