import React from "react";
import { notification } from "antd";
import {
  CheckCircleFilled,
  ExclamationCircleFilled,
  InfoCircleFilled,
  StopFilled,
} from "@ant-design/icons";
import { NotificationProps } from "interfaces/ClientInterface/IStore";
import "./Notification.scss";

const openSuccessNotification = (config: NotificationProps): void => {
  notification.destroy();
  notification.success({
    ...config,
    icon: (
      <div>
        <CheckCircleFilled className="success-icon" style={{ color: "#4D9CD4" }} />
      </div>
    ),
    message: <div className={`title ${!config.description && "title-only"}`}>{config.message}</div>,
    description: config.description ? (
      <div className="description">{config.description}</div>
    ) : (
      <div />
    ),
    className: config.className ? config.className : "notification-without-description",
    duration: config.duration ? config.duration : 3,
  });
};

const openInfoNotification = (config: NotificationProps): void => {
  notification.destroy();
  notification.info({
    ...config,
    icon: (
      <div>
        <InfoCircleFilled className="info-icon" />
      </div>
    ),
    message: <div className={`title ${!config.description && "title-only"}`}>{config.message}</div>,
    description: config.description ? (
      <div className="description">{config.description}</div>
    ) : (
      <div />
    ),
    className: config.className ? config.className : "notification-without-description",
    duration: config.duration ? config.duration : 3,
  });
};

const openWarningNotification = (config: NotificationProps): void => {
  notification.destroy();
  notification.warning({
    ...config,
    icon: (
      <div>
        <ExclamationCircleFilled className="warning-icon" />
      </div>
    ),
    message: <div className={`title ${!config.description && "title-only"}`}>{config.message}</div>,
    description: config.description ? (
      <div className="description">{config.description}</div>
    ) : (
      <div />
    ),
    className: config.className ? config.className : "notification-without-description",
    duration: config.duration ? config.duration : 3,
  });
};

const openErrorNotification = (config: NotificationProps): void => {
  notification.destroy();
  notification.error({
    ...config,
    icon: (
      <div>
        <StopFilled className="error-icon" />
      </div>
    ),
    message: <div className={`title ${!config.description && "title-only"}`}>{config.message}</div>,
    description: config.description ? (
      <div className="description">{config.description}</div>
    ) : (
      <div />
    ),
    className: config.className ? config.className : "notification-without-description",
    duration: config.duration ? config.duration : 3,
  });
};

export const notificationController = {
  success: openSuccessNotification,
  info: openInfoNotification,
  warning: openWarningNotification,
  error: openErrorNotification,
};
