import { httpApi } from "../../Http.api";
import { AxiosError } from "axios";
import { IStoresResponse } from "interfaces/ApiInterface/SpokeCustumApiInterface/IStores";
import { IPublishPayload } from "interfaces/ClientInterface/SpokeCustumApiInterface/IGlobal";

/** Spoke Custom Store APIs Start */

// Get list of all the stores
export const getAllStoresData = (userId: number, portalId: number): Promise<IStoresResponse> => {
  return httpApi
    .get(`/bstoreswebstore/getbstorelist/${portalId}?UserId=${userId}`)
    .then((res: IStoresResponse) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

export const getHostStore = (payload:IPublishPayload) => {
 
  return httpApi
    .get(
      // eslint-disable-next-line max-len
      `/spokecustombstore/spokecustompublishbstoresetting?portalId=${payload.portalId}&TargetPublishState=${payload.publishState}&PublishContent=BStoreSettings,CmsContent,Catalog,DRAFT&TakeFromDraftFirst=true&UserId=${payload.userId}&launchStatus=${payload.launchStatus}`
    )
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

/** Spoke Custom Store APIs end */
