import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CatalogUseState {
  enteredDomainName: string;
  storeName: string;
}

const initialState: CatalogUseState = {
  enteredDomainName: "",
  storeName: "",
};

export const catalogUseSlice = createSlice({
  name: "catalogUse",
  initialState,
  reducers: {
    setEnteredDomainNames: (state, action: PayloadAction<string>) => {
      state.enteredDomainName = action.payload;
    },
    setStoreName: (state, action: PayloadAction<string>) => {
      state.storeName = action.payload;
    },
    resetEnteredDomainName: (state) => {
      state.enteredDomainName = "";
    },
  },
});

export const { setEnteredDomainNames, setStoreName, resetEnteredDomainName } =
  catalogUseSlice.actions;
export default catalogUseSlice.reducer;
