import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LOCAL_STORAGE_KEYS, setLocalStorageData } from "api/Services/localStorage.service";

interface StoreDetailsState {
  parentCompanyName: string | null;
  storeStatus: boolean;
  storeStoreLaunched: string;
  storeName: string | null;
  openDate: string | null;
  closeDate: string | null;
  DomainURL: string;
  IsApproved: boolean;
}

const storedState = JSON.parse(localStorage.getItem("storeDetails") || "{}");
const initialState: StoreDetailsState = {
  parentCompanyName: storedState?.parentCompanyName || null,
  storeStatus: storedState?.storeStatus || false,
  storeStoreLaunched: storedState?.storeStoreLaunched || "",
  storeName: storedState?.storeName || null,
  openDate: storedState?.openDate || null,
  closeDate: storedState?.closeDate || null,
  DomainURL: storedState?.DomainURL || "",
  IsApproved: storedState?.IsApproved || false,
};

const storeDetailsSlice = createSlice({
  name: "storeDetails",
  initialState,
  reducers: {
    setStoreDetails: (state, action: PayloadAction<Partial<StoreDetailsState>>) => {
      Object.assign(state, action.payload);
      setLocalStorageData(LOCAL_STORAGE_KEYS.STORE_DETAILS, JSON.stringify(state));
    },
  },
});

export const { setStoreDetails } = storeDetailsSlice.actions;
export default storeDetailsSlice.reducer;
