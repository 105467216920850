import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IOrderState } from "interfaces/ApiInterface/SpokeCustumApiInterface/IOrder";

const initialState: IOrderState = {
  orderId: 0,
  userId: 0,
  sort: "OrderDateWithTime",
};

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    getOrderId: (state, action: PayloadAction<number>) => {
      state.orderId = action.payload;
    },
    getUserId: (state, action: PayloadAction<{ userId: number; sort: string | null }>) => {
      state.userId = action.payload.userId;
      state.sort = action.payload.sort;
    },
  },
});

export const { getOrderId, getUserId } = orderSlice.actions;
export default orderSlice.reducer;
