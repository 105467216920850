import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface INavbarState {
  dashboardVisible: boolean;
}

const initialState: INavbarState = {
  dashboardVisible: false,
};

export const navbarSlice = createSlice({
  name: "navbar",
  initialState,
  reducers: {
    setNavbarVisible: (state, action: PayloadAction<boolean>) => {
      state.dashboardVisible = action.payload;
    },
  },
});

export const { setNavbarVisible } = navbarSlice.actions;
export default navbarSlice.reducer;
