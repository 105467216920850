import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UploadState {
  uploadedFavicon: string;
}

const initialState: UploadState = {
  uploadedFavicon: "",
};

const uploadFaviconSlice = createSlice({
  name: "uploadFavicon",
  initialState,
  reducers: {
    addFavicon: (state, action: PayloadAction<string>) => {
      state.uploadedFavicon = action.payload;
    },
    removeFaviconByIndex: (state) => {
      state.uploadedFavicon = "";
    },
    clearFavicon: (state) => {
      state.uploadedFavicon = "";
    },
  },
});

export const { addFavicon, removeFaviconByIndex, clearFavicon } = uploadFaviconSlice.actions;
export default uploadFaviconSlice.reducer;
