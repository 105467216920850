import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UploadState {
  uploadedImages: string;
  mediaId: string;
}

const initialState: UploadState = {
  uploadedImages: "",
  mediaId: "",
};

const uploadSlice = createSlice({
  name: "upload",
  initialState,
  reducers: {
    addImage: (state, action: PayloadAction<string>) => {
      state.uploadedImages = action.payload;
    },
    removeImageByIndex: (state) => {
      state.uploadedImages = "";
    },
    clearImages: (state) => {
      state.uploadedImages = "";
    },
    storeMediaId: (state, action: PayloadAction<string>) => {
      state.mediaId = action.payload;
    },
  },
});

export const { addImage, removeImageByIndex, clearImages, storeMediaId } = uploadSlice.actions;
export default uploadSlice.reducer;
