import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AuthRequest } from "interfaces/ClientInterface/IAuth";

import {
  deleteToken,
  deleteUser,
  persistAccountInfo,
  persistToken,
  readToken,
  readUserAccountInfo,
} from "api/Services/localStorage.service";
import { notificationController } from "components/Common/Notification/Notification";
import { fetchLoggedInDetails } from "api/Auth.api";
import { IValidateTokenResponse } from "interfaces/ApiInterface/IAuth";

export interface AuthSlice {
  token: string | null;
  userInfo: AuthRequest | null;
}

const initialState: AuthSlice = {
  token: readToken(),
  userInfo: readUserAccountInfo(),
};

export const doLogin = createAsyncThunk("auth/doLogin", async (loginPayload: AuthRequest) =>
  fetchLoggedInDetails(loginPayload)
    .then((res: IValidateTokenResponse) => {
      const data = res?.BStoresImpersonationDetails;
      const userData = {
        Token: data.Token,
        WebstoreUserId: data.WebstoreUserId,
        PortalId: data.PortalId,
        ParentStoreName: data.ParentStoreName,
      };
      persistToken(data.Token);
      persistAccountInfo(userData);
      return userData;
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err);
      notificationController.error({
        message: "Something went wrong",
        description: "Please try again later",
      });
    })
);

export const doLogout = createAsyncThunk("auth/doLogout", () => {
  deleteToken();
  deleteUser();
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(doLogin.fulfilled, (state, action) => {
      if (action.payload) {
        state.token = action.payload.Token;
        state.userInfo = action.payload;
      }
    });
    builder.addCase(doLogout.fulfilled, (state) => {
      state.token = "";
    });
  },
  reducers: {
    // eslint-disable-next-line
    setUserDetails: (state, action) => {
      state.token = action?.payload?.token;
      state.userInfo = action?.payload?.userInfo;
    },
  },
});

export const { setUserDetails } = authSlice.actions;
export default authSlice.reducer;