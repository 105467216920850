/* eslint-disable indent */
/* eslint-disable max-lines-per-function */
import React, { useEffect } from "react";
import { Typography, Input, Form, Row, Button, Spin } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
// import StepsComponent from "components/Common/Steps/Steps";
import { readToken } from "api/Services/localStorage.service";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { getPageChange } from "store/Slices/store.slice";
import { IStoreMessages } from "interfaces/ClientInterface/SpokeCustumApiInterface/IAboutStore";
import "./StoreMessages.scss";

const { Title } = Typography;
const { TextArea } = Input;

const StoreMessages: React.FC<IStoreMessages> = ({
  form,
  onCreateStoreClick,
  loggedInUserCreateStoreLoader,
  templateMessagesLoaded,
  setTemplateMessagesLoaded,
}) => {
  const dispatch = useAppDispatch();
  const isUserLoggedIn = readToken() ? true : false;
  const pageNumber = useAppSelector((state) => state.store.pageNumber);

  const handleBack = () => {
    dispatch(getPageChange(pageNumber - 1));
  };

  const nextClickHandler = async () => {
    onCreateStoreClick && onCreateStoreClick(false);
  };

  const storeNameinForm = form.getFieldValue("StoreName");

  const templateMessages = {
    welcomeHeader: `Welcome to ${storeNameinForm}`,
    welcomeCopy: "Gift yourself a little something.",
    landingHeader: "Choose the perfect gift.",
    landingCopy: "Select an item from our curated selection below.",
    thankyouHeader: "Your order has been successfully placed.",
    thankyouCopy: "Thank you for coming to our event! Keep an eye out for your tracking email.",
  };

  useEffect(() => {
    if (!templateMessagesLoaded) {
      form.setFields([
        {
          name: "WelcomePageTitle",
          value: templateMessages.welcomeHeader,
          errors: [],
        },
        {
          name: "WelcomePageSubTitle",
          value: templateMessages.welcomeCopy,
          errors: [],
        },
        {
          name: "ProductsPageTitle",
          value: templateMessages.landingHeader,
          errors: [],
        },
        {
          name: "ProductsPageSubTitle",
          value: templateMessages.landingCopy,
          errors: [],
        },
        {
          name: "OrderConfirmationTitle",
          value: templateMessages.thankyouHeader,
          errors: [],
        },
        {
          name: "OrderConfirmationSubTitle",
          value: templateMessages.thankyouCopy,
          errors: [],
        },
      ]);
      setTemplateMessagesLoaded(true);
    }

    //eslint-disable-next-line
  }, []);

  const dissableOnNext =
    loggedInUserCreateStoreLoader?.loader || loggedInUserCreateStoreLoader?.isSkip;

  return (
    <>
      <div className="store-messages-container">
        <Title level={3} className="my-1" data-test-selector="hdgBringStoreToLive">
          Bring your store to live by adding your own messaging
        </Title>
        <Title level={5}>To assist you, we&apos;ve added some ready-to-use template messages</Title>
        <Title level={4}>Welcome messages</Title>
        <div className="mb-45">
          <div className="font-style">Welcome page header</div>
          <Form.Item name="WelcomePageTitle">
            <TextArea
              showCount
              maxLength={50}
              style={{ height: 35, resize: "none" }}
              data-test-selector="txtWelcomePageTitle"
              disabled={dissableOnNext}
            />
          </Form.Item>
          <div className="font-style">Welcome page copy</div>
          <Form.Item name="WelcomePageSubTitle">
            <TextArea
              showCount
              maxLength={500}
              style={{ height: 120, resize: "none" }}
              data-test-selector="txtWelcomePageSubtitle"
              disabled={dissableOnNext}
            />
          </Form.Item>
        </div>
        <Title level={4}>Landing page messages</Title>
        <div className="mb-45">
          <div className="font-style">Landing page header</div>
          <Form.Item name="ProductsPageTitle">
            <TextArea
              showCount
              maxLength={50}
              style={{ height: 35, resize: "none" }}
              data-test-selector="txtProductsPageTitle"
              disabled={dissableOnNext}
            />
          </Form.Item>
          <div className="font-style">Landing page copy</div>
          <Form.Item name="ProductsPageSubTitle">
            <TextArea
              showCount
              maxLength={500}
              style={{ height: 120, resize: "none" }}
              data-test-selector="txtProductsPageSubtitle"
              disabled={dissableOnNext}
            />
          </Form.Item>
        </div>
        <Title level={4}>Thank you messages</Title>
        <div className="mb-45">
          <div className="font-style">Thank you header</div>
          <Form.Item name="OrderConfirmationTitle">
            <TextArea
              showCount
              maxLength={50}
              style={{ height: 35, resize: "none" }}
              data-test-selector="txtOrderConfirmationTitle"
              disabled={dissableOnNext}
            />
          </Form.Item>
          <div className="font-style">Thank you copy</div>
          <Form.Item name="OrderConfirmationSubTitle">
            <TextArea
              showCount
              maxLength={500}
              style={{ height: 120, resize: "none" }}
              data-test-selector="txtOrderConfirmationSubTitle"
              disabled={dissableOnNext}
            />
          </Form.Item>
        </div>
        {/* <StepsComponent /> */}
        <Row className="my-1 btn-section">
          <Button
            className="text"
            onClick={() => {
              onCreateStoreClick && onCreateStoreClick(true);
            }}
            data-test-selector="divSkipForNow"
            type="text"
            disabled={dissableOnNext}
          >
            Skip for now
            <span>
              {isUserLoggedIn &&
              loggedInUserCreateStoreLoader?.loader &&
              loggedInUserCreateStoreLoader?.isSkip ? (
                <Spin className="skip-loading" />
              ) : (
                <ArrowRightOutlined />
              )}
            </span>
          </Button>
          <div className="d-flex">
            <Button
              onClick={handleBack}
              style={{ marginRight: "10px" }}
              className="back-step-btn"
              data-test-selector="btnBack"
              disabled={loggedInUserCreateStoreLoader?.loader}
            >
              Back
            </Button>
            <Form.Item>
              <Button
                htmlType="submit"
                className="gray-bg-button"
                onClick={nextClickHandler}
                data-test-selector="btnNext"
                loading={dissableOnNext}
                disabled={
                  loggedInUserCreateStoreLoader?.loader && loggedInUserCreateStoreLoader?.isSkip
                }
              >
                Next
              </Button>
            </Form.Item>
          </div>
        </Row>
      </div>
    </>
  );
};

export default StoreMessages;
