/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */
import { Button, Checkbox, Col, Select, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { Form, Input, Row } from "antd";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { readToken } from "api/Services/localStorage.service";
// import StepsComponent from "components/Common/Steps/Steps";
import {
  REGEX,
  isPasswordStrength,
  isValidEmail,
  clearHeapEventProperties,
  clearLuckyOrangeEventProperties,
  resetHeapIdentity,
} from "utils";
import {
  IUserDetails,
  IValidateUserEmail,
} from "interfaces/ClientInterface/SpokeCustumApiInterface/IAboutStore";
import { COUNTRY_PHONE_CODES } from "config/SpokeCustom.Constant";
import { useNavigate } from "react-router-dom";
import { getPageChange } from "store/Slices/store.slice";
import { DownOutlined } from "@ant-design/icons";
import { ICountryPhoneCodes } from "interfaces/ClientInterface/SpokeCustumApiInterface/IGlobal";
import "./UserDetails.scss";
import { validateUserEmail } from "api/Services/SpokeCustumAPIs/store.api";
import { IValidateUserResponse } from "interfaces/ApiInterface/SpokeCustumApiInterface/IStores";

const { Title } = Typography;
const { Option } = Select;

const UserDetails: React.FC<IUserDetails> = ({ form, blackListedDomains, ParentPortalId }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isUserLoggedIn = readToken() ? true : false;
  const pageNumber = useAppSelector((state) => state.store.pageNumber);
  const [passwordError, setPasswordError] = useState<boolean>(false);

  useEffect(() => {
    // This Clears Heap cache and cookies during store creation process
    if (!isUserLoggedIn) {
      clearHeapEventProperties();
      resetHeapIdentity();
      clearLuckyOrangeEventProperties();
    }

    //default PhoneCountryCode
    form.setFields([
      {
        name: "PhoneCountryCode",
        value: COUNTRY_PHONE_CODES?.[0]?.code,
        errors: [],
      },
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const emailDomainValidator = (_: any, value: string, callback: any) => {
    if (blackListedDomains == "" || blackListedDomains == null) {
      callback();
      return;
    }
    const invalidDomainsList = blackListedDomains?.split(",");
    const trimmedValue = value?.trim();
    if (
      trimmedValue &&
      invalidDomainsList.some((domain) =>
        trimmedValue?.toLocaleLowerCase()?.endsWith(domain?.toLocaleLowerCase()?.trim())
      )
    ) {
      callback("Please use a business domain to create your Givee account.");
    } else {
      callback();
    }
  };

  const handleCancel = () => {
    form.resetFields();
    navigate("/dashboard/login");
  };

  const nextClickHandler = async () => {
    try {
      await form.validateFields([
        "FirstName",
        "LastName",
        "Company",
        "Title",
        "PhoneNumber",
        "EmailAddress",
        "Password",
        "ConfirmPassword",
        "IsAgree",
      ]);
      const emailAddress = await form.getFieldValue(["EmailAddress"]);
      const payload: IValidateUserEmail = {
        portalId: ParentPortalId,
        email: emailAddress,
      };
      const res = await validateUserEmail(payload);
      const responseData: IValidateUserResponse = res?.data;
      if (responseData?.IsSuccess) {
        dispatch(getPageChange(pageNumber + 1));
      } else {
        form.setFields([
          {
            name: "EmailAddress",
            errors: [responseData?.ErrorMessage],
          },
        ]);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  return (
    <div className="detail-view">
      <Title level={3} className="m-0 ">
        {
          "Welcome to Givee! Let's get started by creating your account. The setup is quick and easy."
        }
      </Title>
      <div>
        <Row className="mb-5 mt-16">
          <Col span={24}>
            <Title level={5} className="font-style">
              Your company name
            </Title>
            <Form.Item
              name={"Company"}
              rules={[
                { required: true, message: "Company name is required", whitespace: true },
                {
                  max: 100,
                  message: "Input must not exceed the maximum character limit of 100",
                },
              ]}
            >
              <Input data-test-selector="txtCompany" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10} className="mb-5">
          <Col span={12}>
            <Title level={5} className="font-style">
              First name
            </Title>
            <Form.Item
              name={"FirstName"}
              rules={[
                { required: true, message: "First name is required", whitespace: true },
                {
                  max: 100,
                  message: "Input must not exceed the maximum character limit of 100",
                },
              ]}
            >
              <Input data-test-selector="txtFirstName" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Title level={5} className="font-style">
              Last name
            </Title>
            <Form.Item
              name={"LastName"}
              rules={[
                { required: true, message: "Last name is required", whitespace: true },
                {
                  max: 100,
                  message: "Input must not exceed the maximum character limit of 100",
                },
              ]}
            >
              <Input data-test-selector="txtLastName" />
            </Form.Item>
          </Col>
        </Row>

        <Row className="mb-5">
          <Col span={24}>
            <Title level={5} className="font-style">
              Job title
            </Title>
            <Form.Item
              name={"Title"}
              rules={[{ required: true, message: "Title is required", whitespace: true }]}
            >
              <Input data-test-selector="txtTitle" />
            </Form.Item>
          </Col>
        </Row>

        <Row className="d-flex justify-center" gutter={[10, 0]}>
          <Col span={24}>
            <Title level={5} className="font-style">
              Phone number
            </Title>
          </Col>
          <Col span={4}>
            <Form.Item name={"PhoneCountryCode"}>
              <Select
                defaultValue="+1"
                suffixIcon={<DownOutlined />}
                style={{ borderRadius: "0px" }}
              >
                {COUNTRY_PHONE_CODES.map((item: ICountryPhoneCodes) => {
                  const FlagComponent = item.flag;
                  return (
                    <Option value="+1" key={item.code}>
                      <FlagComponent />
                      <span className="ml-8">+1</span>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={20}>
            <Form.Item
              name={"PhoneNumber"}
              rules={[
                { required: true, message: "Phone number is required" },
                {
                  pattern: new RegExp(REGEX.validPhoneNumberRegex),
                  message: "The input is not a valid number",
                },
                {
                  max: 15,
                  message: "Input must not exceed the maximum character limit of 15",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col span={24}>
            <Title level={5} className="font-style">
              Email address
            </Title>
            <Form.Item
              name={"EmailAddress"}
              rules={[
                { required: true, message: "Email address is required" },
                {
                  validator: (rule, value, callback) => {
                    if (!value || isValidEmail(value)) {
                      if (value) {
                        // If value is present and is a valid email, then run emailDomainValidator
                        return emailDomainValidator(rule, value, callback);
                      } else {
                        return Promise.resolve();
                      }
                    } else {
                      return Promise.reject(new Error("The input is not a valid Email"));
                    }
                  },
                },
                {
                  max: 50,
                  message: "Input must not exceed the maximum character limit of 50",
                },
              ]}
            >
              <Input data-test-selector="txtEmailAddress" />
            </Form.Item>
          </Col>
        </Row>
        {/* <Row className="mb-5">
          <Col span={24}>
            <Title level={5} className="font-style">
              PCNA ID (Optional)
            </Title>
            <Form.Item name={"CNA"}>
              <Input />
            </Form.Item>
          </Col>
        </Row> */}

        {/* New Field Added for Password and confirm Password According to new Mock ups */}
        <Row className="mb-5">
          <Col span={24}>
            <Title level={5} className="font-style">
              Password
            </Title>
            <Form.Item
              className="mb-0"
              name={"Password"}
              rules={[
                () => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.reject(new Error("Password is required"));
                    } else if (value.trim() === "") {
                      return Promise.reject(new Error("Password is required"));
                    }
                    if (value.trim() !== "" && !isPasswordStrength(value)) {
                      setPasswordError(true);
                      return Promise.reject(new Error(""));
                    }
                    setPasswordError(false);
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input.Password data-test-selector="txtPassword" />
            </Form.Item>
            <Title className={passwordError ? "text-color-error text-info" : "text-info"}>
              Password must contain at least 8 characters including 1 number
            </Title>
          </Col>
        </Row>

        <Row className="mb-5">
          <Col span={24}>
            <Title level={5} className="font-style">
              Confirm Password
            </Title>
            <Form.Item
              name={"ConfirmPassword"}
              dependencies={["Password"]}
              rules={[
                { required: true, message: "Confirm your password" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("Password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("The entered passwords do not match"));
                  },
                }),
              ]}
            >
              <Input.Password data-test-selector="txtConfirmPassword" />
            </Form.Item>
          </Col>
        </Row>

        <Row className="mb-5">
          <Col>
            <Form.Item
              name={"IsAgree"}
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(new Error("You must accept the terms and conditions.")),
                },
              ]}
              valuePropName="checked"
              className="term-condition-btn"
            >
              <Checkbox data-test-selector="chkTermsCondition">
                I agree to the Givee {""}
                <a
                  href="https://www.pcna.com/en-us/givee-terms-and-conditions"
                  target="_blank"
                  className="text-color"
                  data-test-selector="lnkTermsCondition"
                >
                  Terms and Conditions
                </a>{" "}
                on behalf of my organization
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </div>
      {/* <StepsComponent /> */}
      <Title level={5} className="m-0 signup-text">
        {"Sign up takes less than 3 minutes to complete."}
      </Title>
      <Row className="my-1" style={{ justifyContent: "end" }}>
        <div className="back-btn">
          <Button
            className="bg-blue back-step-btn"
            onClick={handleCancel}
            data-test-selector="btnBack"
          >
            Cancel
          </Button>
        </div>
        <div className="next-btn">
          <Form.Item>
            <Button
              className="gray-bg-button"
              data-test-selector="btnCreateStore"
              onClick={nextClickHandler}
            >
              Next
            </Button>
          </Form.Item>
        </div>
      </Row>
    </div>
  );
};

export default UserDetails;
