import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IQuoteState } from "interfaces/ClientInterface/IQuotes";

const initialState: IQuoteState = {
  quoteId: 0,
};

export const quoteSlice = createSlice({
  name: "quote",
  initialState,
  reducers: {
    getQuoteId: (state, action: PayloadAction<number>) => {
      state.quoteId = action.payload;
    },
  },
});

export const { getQuoteId } = quoteSlice.actions;
export default quoteSlice.reducer;
