import axios, { AxiosError } from "axios";
import { ApiError } from "./ApiError";
import { readToken } from "./Services/localStorage.service";
import { LogoutHandler } from "utils";

export interface ApiResponse<T> {
  data: T;
}

export interface ApiErrorData {
  message: string;
}

export const httpApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
httpApi.interceptors.request.use((config: any) => {
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${readToken()}`,
  };
  config.validateStatus = () => {
    return true;
  };
  return config;
});

httpApi.interceptors.response.use(
  (response) => {
    //prevent Forgot Password API to check the below error condition
    const isForgotPasswordURL = response?.config?.url == "/users/getbyusername";
    //redirect to main page if token is not present in request
    if (response?.status == 500 && response?.data?.ErrorCode == 52 && !isForgotPasswordURL) {
      const redirectToLandingPageURL = process.env.REACT_APP_REDIRECT_URL;
      window.location.href = redirectToLandingPageURL || "";
    }
    if (response?.status === 401) {
      LogoutHandler();
    }
    return response;
  },
  (error: AxiosError<ApiErrorData>) => {
    if (error?.response?.status === 401) {
      LogoutHandler();
    }
    throw new ApiError<ApiErrorData>(
      error && error.response?.data?.message ? error.response?.data?.message : error.message
    );
  }
);
