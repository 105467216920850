/* eslint-disable no-console */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
/* eslint-disable max-len */
/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import { theme, Form, notification, Space, Button, Image } from "antd";
import { useAppSelector, useAppDispatch } from "hooks/reduxHooks";
import CompanyInformation from "components/Store/AboutStore/CompanyInformation/CompanyInformation";
import { getPageChange } from "store/Slices/store.slice";
import {
  ICreateStoreData,
  ILoggedInUserCreateStoreLoading,
  ISubDomainNameResponse,
} from "interfaces/ApiInterface/IStore";
import { Link, useNavigate } from "react-router-dom";
import { notificationController } from "components/Common/Notification/Notification";
import {
  createStoreFunc,
  getCompaniesList,
  getPcnaAccountList,
  getSubdomainName,
} from "api/Services/SpokeCustumAPIs/store.api";
import FooterComponent from "components/Common/Footer/Footer";
import { resetEnteredDomainName } from "store/Slices/catalogUse.slice";
import { clearImages } from "store/Slices/uploadImage.slice";
import {
  persistAccountInfo,
  persistToken,
  readToken,
  setAxiosHeader,
} from "api/Services/localStorage.service";
import { setUserDetails } from "store/Slices/auth.slice";
import logo from "../../../../assets/Images/Logo.png";
import LOGO_WHITE from "../../../../assets/Images/logo-white.png";
import {
  LogoClickHandler,
  setCustomHeapEvents,
  setHeapEntries,
  setLuckOrangeIdentity,
  trimObjectValues,
  updateBrowserTabTitle,
} from "utils";
import {
  DEFAULT_PAGE_INDEX,
  GET_STARTED_PAGE_TITLE,
  GLOBAL_ADDITIONAL_ATTRIBUTES,
  HEAP_CUSTOM_EVENTS,
  STORE_CREATOR_PAGE_TITLE,
} from "config/SpokeCustom.Constant";
import { setDomainsNames } from "store/Slices/domainSlice";
import UserDetails from "../UserDetails/UserDetails";
import StoreLogo from "../StoreLogo/StoreLogo";
import { IStoreCreationFormValues } from "interfaces/ClientInterface/IStore";
import AlreadyExistCompany from "../CompanyInformation/AlreadyExistCompany/AlreadyExistCompany";
import StoreName from "../StoreDetails/StoreName";
import StoreUrl from "../StoreDetails/StoreUrl";
import {
  ICompanyList,
  INewCompanyPageData,
  IPcnaAccountList,
} from "interfaces/ClientInterface/SpokeCustumApiInterface/IAboutStore";
import { ICompanyResponse } from "interfaces/ApiInterface/SpokeCustumApiInterface/IStores";
import { loginUser } from "api/Services/SpokeCustumAPIs/login.api";
import { ILoginForm } from "interfaces/ClientInterface/SpokeCustumApiInterface/ILogin";
import StoreTypeSelection from "../StoreType/StoreTypeSelection";
import GiftingStoreOptions from "../GiftingOptions/GiftStoreOptions";
import {
  E_COMMERCE_STORE_LIMITS,
  GIFTING_STORE_LIMITS,
  STORE_TYPE_ATTRIBUTES,
} from "components/SpokeCustomComponents/StoreAdmin/StoreAdmin.Constant";
import ECommerceStoreOptions from "../GiftingOptions/ECommerceOptions";
import StoreMessages from "../StoreMessages/StoreMessages";
import "./CreateStore.scss";
import PCNAAccountForm from "../PcnaAccount/PcnaAccount";
import { LoadingComponent } from "components/SpokeCustomComponents/LoadingComponent/LoadingComponent";
import OnBoardingBillingDetails from "../BillingDetails/OnBoardingBillingDetails";
import ExistingStripeAccount from "../ExistingStripeAccount/ExistingStripeAccount";
import { setChildPortalId } from "store/Slices/childPortal.slice";

const close = () => {
  console.log("Notification was closed.");
};

const CreateStore: React.FC = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const domainName = useAppSelector((state) => state.domain.domainName);
  const userInfo = useAppSelector((state) => state.auth.userInfo);
  const childPortalId = userInfo?.PortalId ? userInfo?.PortalId : 0;
  const ParentPortalId = userInfo?.PortalId || 0;
  const UserId = userInfo?.WebstoreUserId;
  const pageNumber = useAppSelector((state) => state.store.pageNumber);
  const createStore = useAppSelector((state) => state.store.createStore);
  const catalogId = useAppSelector((state) => state.custom2.value);
  // eslint-disable-next-line no-unused-vars
  const [storeData, setStoreData] = useState<ICreateStoreData>(createStore);
  const [loading, setLoading] = useState<boolean>(false);
  const [mediaId, setMediaId] = useState<string>("");
  const isUserLoggedIn = readToken() ? true : false;
  const [domainNameValue, setDomainNameValue] = useState<string>("");
  const [blackListedDomains, setBlackListedDomains] = useState<string>("");
  const [storeUrlList, setStoreUrlList] = useState<string[]>();
  const [selectedCompany, setSelectedCompany] = useState<string>("");
  const [preserveNewlyAddedCompany, setPreserveNewlyAddedCompany] = useState<string>("");
  const storeTypeFormValue = Form.useWatch(GLOBAL_ADDITIONAL_ATTRIBUTES.STORE_TYPE, {
    form,
    preserve: true,
  });
  const [pcnaAccountsList, setPcnaAccountsList] = useState<IPcnaAccountList[]>([]);
  const pcnaAccountDetailsFormName = "PcnaAccountDetails";
  const [touch, setTouch] = useState(false);
  const [loggedInUserCreateStoreLoader, setLoggedInUserCreateStoreLoader] =
    useState<ILoggedInUserCreateStoreLoading>({
      loader: false,
      isSkip: false,
    });

  //already existing company states to preserve value on page change
  const DEFAULT_PAGE_SIZE_COMPANY = 5;
  const [companyList, setCompanyList] = useState<ICompanyList[]>([]);
  const [newCompanyPageData, setNewCompanyPageData] = useState({
    pageIndex: DEFAULT_PAGE_INDEX,
    pageSize: DEFAULT_PAGE_SIZE_COMPANY,
    totalResult: 0,
  } as INewCompanyPageData);

  // Store messages states to preserve value on page change
  const [templateMessagesLoaded, setTemplateMessagesLoaded] = useState<boolean>(false);

  // pcna states to preserve value on page change
  const [pcnaAccountDetailsLoadOnce, setPcnaAccountDetailsLoadOnce] = useState<boolean>(false);

  useEffect(() => {
    const unloadCallback = (event: { preventDefault: () => void; returnValue: string }) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    if (touch) {
      window.addEventListener("beforeunload", unloadCallback);
    } else {
      window.removeEventListener("beforeunload", unloadCallback);
    }

    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, [touch]);

  useEffect(() => {
    if (ParentPortalId) {
      getSubdomainName(ParentPortalId).then((res: ISubDomainNameResponse) => {
        const responseData = res?.data?.BStoresDomainName;
        const newDomainName = responseData?.BStoreDomainName || "";
        setBlackListedDomains(responseData?.Custom1 || "");
        setDomainNameValue(newDomainName);
        dispatch(setDomainsNames(newDomainName));
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // This tracks store creation step in HEAP
    const event = isUserLoggedIn
      ? `${HEAP_CUSTOM_EVENTS.NEW_STORE_STEP}-${pageNumber}`
      : `${HEAP_CUSTOM_EVENTS.FIRST_STORE_STEP}-${pageNumber}`;

    setCustomHeapEvents(event);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  useEffect(() => {
    updateBrowserTabTitle(
      !isUserLoggedIn && pageNumber == 1 ? GET_STARTED_PAGE_TITLE : STORE_CREATOR_PAGE_TITLE
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLoggedIn, pageNumber]);

  useEffect(() => {
    return () => {
      dispatch(getPageChange(1));
      dispatch(clearImages());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isUserLoggedIn) {
      fetchCompanyList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newCompanyPageData.pageIndex, newCompanyPageData.pageSize]);

  useEffect(() => {
    if (!isUserLoggedIn && pageNumber == 2 && !pcnaAccountDetailsLoadOnce) {
      fetchPcnaAccountsList();
      setPcnaAccountDetailsLoadOnce(true);
    }

    //Allow to fetch PCNA details if pageNumber changes to 1
    //because user may have changed email ID
    if (!isUserLoggedIn && pageNumber == 1) {
      setPcnaAccountDetailsLoadOnce(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  const fetchPcnaAccountsList = async () => {
    setLoading(true);
    const emailAddress = await form.validateFields(["EmailAddress"]);
    getPcnaAccountList(emailAddress?.EmailAddress)
      // eslint-disable-next-line no-unused-vars
      .then((res) => {
        const sortedList = sortByCreatedDate(res?.data?.PcnaDetails?.PcnaDetails || []);
        setPcnaAccountsList(sortedList);

        form.setFields([
          {
            name: pcnaAccountDetailsFormName,
            value: sortedList?.[0],
            errors: [],
          },
        ]);

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  function sortByCreatedDate(pcnaDetails: IPcnaAccountList[]) {
    return pcnaDetails?.sort((a, b) => {
      // Convert CreatedDate strings to Date objects
      const dateA = new Date(a?.CreatedDate)?.getTime();
      const dateB = new Date(b?.CreatedDate)?.getTime();

      // Compare the two dates
      return dateA - dateB;
    });
  }

  const handleMediaIdReceived = (newMediaId: string) => {
    setMediaId(newMediaId);
    setStoreData((prevState) => ({
      ...prevState,
      MediaId: newMediaId ? newMediaId : "",
    }));
  };

  const openNotification = (errMessage: string) => {
    const key = `open${Date.now()}`;
    const btn = (
      <Space>
        <Button
          type="primary"
          size="small"
          onClick={() => {
            navigate("/dashboard/login");
            api.destroy(key);
          }}
        >
          Login Here
        </Button>
      </Space>
    );
    api.open({
      message: errMessage,
      btn,
      key,
      onClose: close,
    });
  };

  const stopCreateStoreLoading = () => {
    setLoggedInUserCreateStoreLoader({
      loader: false,
      isSkip: false,
    });
  };

  const onCreateStoreClick = async (skipMedia: boolean) => {
    const formValues: IStoreCreationFormValues = await form.getFieldsValue(true);
    const deepCloneValues = JSON.parse(JSON.stringify(formValues));
    const values = trimObjectValues(deepCloneValues);
    values.PhoneNumber = values.PhoneNumber
      ? `${values.PhoneCountryCode}-${values.PhoneNumber}`
      : "";
    const storeTypeValue = values[GLOBAL_ADDITIONAL_ATTRIBUTES.STORE_TYPE];
    const bStoreGiftingOption = values[GLOBAL_ADDITIONAL_ATTRIBUTES.B_STORE_GIFTING_OPTIONS];
    const bStoreGiftingOptionForECommerce = values?.BStoreGiftingOptionsForECommerce;

    const custom2Attributes = {
      // Store messageing screen data
      Title: values.WelcomePageTitle || "",
      SubTitle: values.WelcomePageSubTitle || "",
      PlpTitle: values.ProductsPageTitle || "",
      PlpSubtitle: values.ProductsPageSubTitle || "",
      OCTitle: values.OrderConfirmationTitle || "",
      OCSubtitle: values.OrderConfirmationSubTitle,

      // PCNA ID screen data during first store creation
      Email: values.BillingEmailAddress || "",
      Country: values.BillingCountry || "",
      Street: values.BillingStreet || "",
      City: values.BillingCity || "",
      State: values.BillingState || "",
      Postal: values.BillingPostalCode || "",
    };

    const custom3Attributes = [
      {
        AttributeCode: GLOBAL_ADDITIONAL_ATTRIBUTES.STORE_TYPE,
        AttributeValue: storeTypeValue || "",
      },
      {
        AttributeCode: GLOBAL_ADDITIONAL_ATTRIBUTES.B_STORE_GIFTING_OPTIONS,
        AttributeValue:
          storeTypeValue == STORE_TYPE_ATTRIBUTES.GIFTING_STORE
            ? bStoreGiftingOption
            : bStoreGiftingOptionForECommerce,
      },
      //Additional PCNA information
      {
        AttributeCode: "BStoresPCNAID",
        AttributeValue: values.PcnaAccountDetails?.PCNAID || "",
      },
      {
        AttributeCode: "DistributorName",
        AttributeValue: values.PcnaAccountDetails?.DistributorName || "",
      },
      {
        AttributeCode: "NationalAccountManager",
        AttributeValue: values.PcnaAccountDetails?.NationalAccountManager || "",
      },
      {
        AttributeCode: "FieldSalesManager",
        AttributeValue: values.PcnaAccountDetails?.FieldSalesManager || "",
      },
      {
        AttributeCode: "InsideSalesRep",
        AttributeValue: values.PcnaAccountDetails?.InsideSalesRep || "",
      },
      {
        AttributeCode: "Tier",
        AttributeValue: values.PcnaAccountDetails?.Tier || "",
      },
    ];

    if (storeTypeValue == STORE_TYPE_ATTRIBUTES.GIFTING_STORE) {
      if (bStoreGiftingOption === GIFTING_STORE_LIMITS.QUANTITY_BASED) {
        custom3Attributes.push({
          AttributeCode: GLOBAL_ADDITIONAL_ATTRIBUTES.B_STORE_ITEMS_LIMIT_IN_CART,
          AttributeValue: values[GLOBAL_ADDITIONAL_ATTRIBUTES.B_STORE_ITEMS_LIMIT_IN_CART] || "1",
        });
      } else if (bStoreGiftingOption === GIFTING_STORE_LIMITS.MONETARY_BASED) {
        custom3Attributes.push({
          AttributeCode: GLOBAL_ADDITIONAL_ATTRIBUTES.B_STORE_MONETORY_VALUE,
          AttributeValue: values[GLOBAL_ADDITIONAL_ATTRIBUTES.B_STORE_MONETORY_VALUE] || "",
        });
      }
    }

    if (storeTypeValue == STORE_TYPE_ATTRIBUTES.E_COMMERCE_STORE) {
      if (bStoreGiftingOptionForECommerce === E_COMMERCE_STORE_LIMITS.E_COMMERCE_WITH_CREDIT) {
        custom3Attributes.push({
          AttributeCode: GLOBAL_ADDITIONAL_ATTRIBUTES.B_STORE_MONETORY_VALUE,
          AttributeValue: values[GLOBAL_ADDITIONAL_ATTRIBUTES.B_STORE_MONETORY_VALUE] || "",
        });
      }
    }

    setLoggedInUserCreateStoreLoader({
      loader: true,
      isSkip: skipMedia,
    });

    const payload = {
      PortalId: 0,
      StoreName: values.StoreName?.trim(),
      StoreCode: "",
      UserId: isUserLoggedIn ? UserId : 0,
      ParentPortalId: ParentPortalId,
      PublishCatalogId: catalogId,
      DomainUrl: domainName?.trim(),
      CompanyName: values.CompanyName?.trim(),
      UserVerificationType: "",
      WebsiteDescription: "",
      FaviconId: 0,
      MediaId: mediaId ? mediaId : "",
      Color1: "",
      Color2: "",
      Color3: "",
      Color4: "",
      IsBStoreUsersSelfRegister: false,
      IsBStoreLoginRequired: false,
      IsBStoreShowPricing: false,
      CreatedBy: 0,
      CreatedDate: "",
      ModifiedBy: 0,
      ModifiedDate: "",
      ActionMode: "",
      Custom1: JSON.stringify(values),
      Custom2: skipMedia ? "" : JSON.stringify(custom2Attributes),
      Custom3: JSON.stringify(custom3Attributes),
      Custom4: "",
      Custom5: isUserLoggedIn ? "second" : "first",
      ImageNotAvailablePath: "",
      BStoreDomainName: values.BStoreDomainName?.trim(),
      IsManageBstoreFromWebstore: true,
      IsBStoresAutoPublished: false,
      storeName: values.StoreName?.trim(),
    };

    createStoreFunc(payload)
      .then((response) => {
        if (response?.data?.HasError) {
          if (response?.data?.ErrorCode == 1003) {
            stopCreateStoreLoading();
            openNotification(response?.data?.ErrorMessage);
            return;
          } else {
            stopCreateStoreLoading();
            notificationController.error({
              message: response?.data?.ErrorMessage,
            });
            return;
          }
        }
        dispatch(resetEnteredDomainName());
        // dispatch(clearImages());
        notificationController.success({
          message: " Your store has been created",
        });
        // Set childPortalID of this newly created store in localstorage
        dispatch(setChildPortalId(response?.data?.BStoreDetails?.PortalId));
        if (isUserLoggedIn) {
          if (storeTypeValue == STORE_TYPE_ATTRIBUTES.E_COMMERCE_STORE) {
            dispatch(getPageChange(pageNumber + 1));
          } else {
            navigate("/dashboard/stores");
          }
          stopCreateStoreLoading();
          // dispatch(getPageChange(pageNumber + 1));
        } else {
          const loginPayload = {
            username: values.EmailAddress,
            password: values.Password,
          };
          handleLogin(loginPayload);
        }
      })
      .catch(() => {
        notificationController.error({
          message: "Something went wrong",
          description: "Please try again later",
        });
        stopCreateStoreLoading();
      });
  };

  const fetchCompanyList = () => {
    setLoading(true);
    getCompaniesList(UserId, newCompanyPageData.pageIndex, newCompanyPageData.pageSize)
      .then((res) => {
        const companyArray = res?.data?.CompanyList?.spokeCustomBstoreCompanyModels || [];
        const companyListArray = companyArray.map((item: ICompanyResponse) => {
          return {
            CompanyName: item.CompanyName,
            isNewlyAdded: false,
          };
        });
        if (preserveNewlyAddedCompany?.length > 0) {
          const newlyAddedCompany: ICompanyList = {
            CompanyName: preserveNewlyAddedCompany,
            isNewlyAdded: true,
          };
          setCompanyList([...[newlyAddedCompany], ...companyListArray]);
        } else {
          setCompanyList(companyListArray || []);
        }

        setNewCompanyPageData((prevState: INewCompanyPageData) => ({
          ...prevState,
          totalResult: companyArray?.[0]?.RowCount,
        }));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleLogin = async (values: ILoginForm) => {
    try {
      const response = await loginUser(values, childPortalId);

      if (response?.HasError && response?.ErrorCode == "6") {
        notificationController.error({
          message: "Oops! you do not have rights to access this portal.",
        });
        stopCreateStoreLoading();
        window.location.href = "/dashboard/login";
        return;
      }

      if (response?.HasError) {
        notificationController.error({
          message: "Something went wrong",
          description: "Please try again later",
        });
        stopCreateStoreLoading();
        window.location.href = "/dashboard/login";
        return;
      }
      const data = response?.User;
      const userData = {
        Token: data?.User.Token,
        WebstoreUserId: data?.UserId,
        PortalId: data?.PortalId,
        ParentStoreName: "",
        Email: data?.Email,
        FullName: data?.FullName,
        RoleName: data?.RoleName,
      };
      userData && setHeapEntries(userData);
      userData && setLuckOrangeIdentity(userData);
      //persist userinfo in session
      persistAccountInfo(userData);
      //persist userinfo in session
      persistToken(data?.User.Token);
      //setting axios header
      setAxiosHeader(data?.User.Token);
      //storing login details in redux
      dispatch(
        setUserDetails({
          token: data?.User.Token,
          userInfo: userData,
        })
      );
      window.location.href = "/dashboard/stores";
    } catch (error) {
      notificationController.error({
        message: "Something went wrong",
        description: "Please try again later",
      });
      stopCreateStoreLoading();
    }
  };

  useEffect(() => {
    return () => {
      dispatch(getPageChange(1));
      dispatch(clearImages());
    };
  }, [dispatch]);

  const renderUserDetailsForm = () => {
    return (
      <UserDetails
        form={form}
        blackListedDomains={blackListedDomains}
        ParentPortalId={ParentPortalId}
      />
    );
  };

  const renderPcnaAccountDetails = () => {
    return <PCNAAccountForm form={form} pcnaAccountsList={pcnaAccountsList} loading={loading} />;
  };

  const renderAlreadyExistingCompanyForm = () => {
    return (
      <AlreadyExistCompany
        form={form}
        selectedCompanyProps={{
          selectedCompany,
          setSelectedCompany,
          setPreserveNewlyAddedCompany,
        }}
        pageProps={{ newCompanyPageData, setNewCompanyPageData }}
        companyListProps={{
          companyList,
          setCompanyList,
        }}
        loading={loading}
      />
    );
  };

  const renderStoreNameForm = () => {
    return (
      <StoreName
        form={form}
        domainNameFromProps={domainNameValue}
        setStoreUrlList={setStoreUrlList}
      />
    );
  };

  const renderCompanyInformation = () => {
    return <CompanyInformation form={form} />;
  };

  const renderOnBoardingBillingDetails = () => {
    return <OnBoardingBillingDetails form={form} />;
  };

  const renderStoreURL = () => {
    return (
      <StoreUrl form={form} domainNameFromProps={domainNameValue} storeUrlList={storeUrlList} />
    );
  };

  const renderStoreLogo = () => {
    return <StoreLogo onMediaIdReceived={handleMediaIdReceived} />;
  };

  const renderStoreTypeSelection = () => {
    return <StoreTypeSelection form={form} />;
  };

  const renderGiftingOptions = () => {
    return storeTypeFormValue == STORE_TYPE_ATTRIBUTES.GIFTING_STORE ? (
      <GiftingStoreOptions form={form} />
    ) : (
      <ECommerceStoreOptions form={form} />
    );
  };

  const renderStoreMessages = () => {
    return (
      <StoreMessages
        form={form}
        onCreateStoreClick={onCreateStoreClick}
        loggedInUserCreateStoreLoader={loggedInUserCreateStoreLoader}
        templateMessagesLoaded={templateMessagesLoaded}
        setTemplateMessagesLoaded={setTemplateMessagesLoaded}
      />
    );
  };

  const renderExistingStripeAccount = () => {
    return <ExistingStripeAccount form={form} />;
  };

  /**
   * The function `renderContentForFirstTimeUser` determines which component to render based on the
   * current page number for a first-time user flow.
   */
  const renderContentForFirstTimeUser = () => {
    switch (pageNumber) {
      case 1:
        return renderUserDetailsForm();
      case 2:
        return renderPcnaAccountDetails();
      case 3:
        return renderOnBoardingBillingDetails();
      case 4:
        return renderCompanyInformation();
      case 5:
        return renderStoreNameForm();
      case 6:
        return renderStoreURL();
      case 7:
        return renderStoreLogo();
      case 8:
        return renderStoreTypeSelection();
      case 9:
        return renderGiftingOptions();
      case 10:
        return renderStoreMessages();
      default:
        return <LoadingComponent />;
    }
  };

  /**
   * The function `renderContentForLoggedInUser` returns different forms based on the `pageNumber`
   * provided for the logged in user.
   */
  const renderContentForLoggedInUser = () => {
    switch (pageNumber) {
      case 1:
        return renderAlreadyExistingCompanyForm();
      case 2:
        return renderStoreNameForm();
      case 3:
        return renderStoreURL();
      case 4:
        return renderStoreLogo();
      case 5:
        return renderStoreTypeSelection();
      case 6:
        return renderGiftingOptions();
      case 7:
        return renderStoreMessages();
      case 8:
        return renderExistingStripeAccount();
      default:
        return <LoadingComponent />;
    }
  };

  /**
   * The function `renderPageContentConditionally` renders different content based on whether the user
   * is logged in or not.
   */
  const renderPageContentConditionally = () => {
    if (isUserLoggedIn) {
      return renderContentForLoggedInUser();
    } else {
      return renderContentForFirstTimeUser();
    }
  };

  return (
    <div className="create-new-store-container">
      <div className="about-store-container">
        <Space direction="vertical" size={0}>
          <div
            className="logo-img cursor-pointer"
            onClick={() => {
              LogoClickHandler();
            }}
          >
            <Image className="main-header-logo" fallback={logo} src={LOGO_WHITE} preview={false} />
          </div>
          <div className="store-label">
            {/* <Typography.Title level={3}>Launching a store is easy.</Typography.Title>
            <Typography.Title level={5} className="setup-info">
              {getHeaderContent()}
            </Typography.Title> */}
          </div>
        </Space>
        <Form
          form={form}
          className="sub-container"
          style={{ background: colorBgContainer }}
          initialValues={{
            StoreType: STORE_TYPE_ATTRIBUTES.GIFTING_STORE,
            BStoreGiftingOptions: GIFTING_STORE_LIMITS.QUANTITY_BASED,
            BStoreGiftingOptionsForECommerce: E_COMMERCE_STORE_LIMITS.E_COMMERCE_ONLY_STORE,
            BStoreItemslimitincart: 1,
            PcnaAccountDetails: {},
          }}
          // onFinish={onFinish}
          data-test-selector="frmStoreCreation"
          scrollToFirstError={true}
          onFieldsChange={(changedFields) => {
            changedFields && setTouch(false);
          }}
        >
          {renderPageContentConditionally()}
        </Form>
        {pageNumber == 1 && !isUserLoggedIn ? (
          <div className="new-to-givee-container">
            <p>Already have an account?</p>
            <Link
              to={"/dashboard/login"}
              className="create-store-link"
              data-test-selector="lnkSignIn"
            >
              Sign in
            </Link>
          </div>
        ) : null}
      </div>
      <FooterComponent />
      {contextHolder}
    </div>
  );
};

export default CreateStore;
