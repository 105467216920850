/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from "react";
import { Form, Input, Button, Typography } from "antd";
const { Title } = Typography;
import logo from "../../../assets/Images/Logo.png";
import { loginUser } from "api/Services/SpokeCustumAPIs/login.api";
import FooterComponent from "components/Common/Footer/Footer";

import { Link } from "react-router-dom";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import "./LoginForm.scss";
import {
  persistAccountInfo,
  persistToken,
  setAxiosHeader,
} from "api/Services/localStorage.service";
import { setUserDetails } from "store/Slices/auth.slice";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import {
  LogoClickHandler,
  resetHeapIdentity,
  setHeapEntries,
  setLuckOrangeIdentity,
  updateBrowserTabTitle,
} from "utils";
import { LOGIN_PAGE_TITLE } from "config/SpokeCustom.Constant";
import { ILoginForm } from "interfaces/ClientInterface/SpokeCustumApiInterface/ILogin";
import { notificationController } from "components/Common/Notification/Notification";

const Login = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const userInfo = useAppSelector((state) => state.auth.userInfo);
  const PortalId = userInfo?.PortalId ? userInfo?.PortalId : 0;
  // const navigate = useNavigate();
  const [userData, setUserData] = useState<ILoginForm>({
    username: "",
    password: "",
  } as ILoginForm);
  const [loading, setLoading] = useState(false);

  /**
   * if portal id is not present in local storage,
   * then redirect the user to landing page
   */
  useEffect(() => {
    updateBrowserTabTitle(LOGIN_PAGE_TITLE);
    if (PortalId == 0) {
      const redirectToLandingPageURL = process.env.REACT_APP_REDIRECT_URL;
      window.location.href = redirectToLandingPageURL || "";
    }
  }, [PortalId]);

  const handleLogin = async (values: ILoginForm) => {
    setLoading(true);
    try {
      const response = await loginUser(values, PortalId);
      setLoading(false);

      if (response?.HasError && response?.ErrorCode == "6") {
        notificationController.error({
          message: "Oops! you do not have rights to access this portal.",
        });
        return;
      }

      if (response?.HasError) {
        form.setFields([
          {
            name: "password",
            errors: ["Invalid Username or Password"],
          },
        ]);
        return;
      }
      const data = response?.User;
      const userData = {
        Token: data.User.Token,
        WebstoreUserId: data.UserId,
        PortalId: data.PortalId,
        ParentStoreName: "",
        Email: data.Email,
        FullName: data.FullName,
        RoleName: data.RoleName,
      };
      userData && setHeapEntries(userData);
      userData && setLuckOrangeIdentity(userData);
      //persist userinfo in session
      persistAccountInfo(userData);
      //persist userinfo in session
      persistToken(data?.User.Token);
      //setting axios header
      setAxiosHeader(data?.User.Token);
      //storing login details in redux
      dispatch(
        setUserDetails({
          token: data.User.Token,
          userInfo: userData,
        })
      );
      // navigate("/dashboard/stores");
      window.location.href = "/dashboard/stores";
    } catch (error) {
      setLoading(false);
    }
  };

  const onFinish = (values: ILoginForm) => {
    setUserData(values);
    handleLogin(values);
  };

  return (
    <div className="login-wrapper container-set bg-color">
      <div className="create-new-store-container">
        <div className="about-store-container">
          <div className="d-flex align-item-center flex-direction-column">
            <div className="login-form">
              <div
                className="logo cursor-pointer"
                onClick={() => {
                  LogoClickHandler();
                }}
              >
                <img src={logo} alt="Logo" />
              </div>
              <Title level={3}>Log in to your account</Title>
              <Form form={form} name="normal_login" initialValues={userData} onFinish={onFinish}>
                <Title level={5}>Email address</Title>
                <Form.Item
                  name="username"
                  rules={[
                    { required: true, message: "Email address is required" },
                    { type: "email", message: "The input is not a valid Email" },
                  ]}
                  normalize={(value) => value?.trim()}
                  className="email-input-login"
                >
                  <Input
                    prefix={<i className="email-icon" />}
                    data-test-selector="txtEmailAddress"
                  />
                </Form.Item>
                <Title level={5}>Password</Title>
                <Form.Item
                  name="password"
                  rules={[{ required: true, message: "Password is required" }]}
                  className="password-input-login"
                  normalize={(value) => value?.trim()}
                >
                  <Input.Password
                    placeholder="********"
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    data-test-selector="txtPassword"
                  />
                </Form.Item>
                <Link
                  className="login-form-forgot"
                  to={"/dashboard/ForgetPassword"}
                  data-test-selector="lnkForgotPassword"
                >
                  Forgot password?
                </Link>
                <div className="login-submit-btn">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={loading}
                    data-test-selector="btnSubmit"
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
            <div className="new-to-givee-container">
              <p>New to Givee?</p>
              <Link
                to={"/dashboard/about-store"}
                className="create-store-link"
                onClick={() => resetHeapIdentity()}
                data-test-selector="lnkCreateStore"
              >
                Create your first store
              </Link>
            </div>
          </div>
        </div>
      </div>
      <FooterComponent />
    </div>
  );
};

export default Login;
