import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PRODUCT_ADDITIONAL_ATTRIBUTES } from "config/SpokeCustom.Constant";

interface productsState {
  showGrid: boolean;
  categoryChangesModal: boolean;
  discardCategoryChanges: boolean;
  AllProductsPIMCategoryId: number;
  MySelectionPIMCategoryId: number;
  allGiveeProductsCateroryId: number;
  AllProductsPIMCategoryName: string;
  MySelectionPIMCategoryName: string;
  AllProductsPortalSortSettingId: number;
  MySelectionPortalSortSettingId: number;
  AllProductsSortDisplayName: string;
  MySelectionSortDisplayName: string;
  isSortProductsModalVisible: boolean;
  isHidePricingandInventory: boolean;
  AllProductsDecoMethodName: string;
  AllProductsDecoMethodValue: string;
  MySelectionDecoMethodName: string;
  MySelectionDecoMethodValue: string;
}

const initialState: productsState = {
  showGrid: false,
  categoryChangesModal: false,
  discardCategoryChanges: false,
  AllProductsPIMCategoryId: 0,
  MySelectionPIMCategoryId: 0,
  allGiveeProductsCateroryId: 0,
  AllProductsPIMCategoryName: "",
  MySelectionPIMCategoryName: "",
  AllProductsPortalSortSettingId: 0,
  MySelectionPortalSortSettingId: 0,
  AllProductsSortDisplayName: "",
  MySelectionSortDisplayName: "",
  isSortProductsModalVisible: false,
  isHidePricingandInventory: false,
  AllProductsDecoMethodName: PRODUCT_ADDITIONAL_ATTRIBUTES.PRODUCTS_ALL_DECO_METHODS_VALUE,
  AllProductsDecoMethodValue: PRODUCT_ADDITIONAL_ATTRIBUTES.PRODUCTS_ALL_DECO_METHODS_CODE,
  MySelectionDecoMethodName: PRODUCT_ADDITIONAL_ATTRIBUTES.PRODUCTS_ALL_DECO_METHODS_VALUE,
  MySelectionDecoMethodValue: PRODUCT_ADDITIONAL_ATTRIBUTES.PRODUCTS_ALL_DECO_METHODS_CODE,
};

const productsStateSlice = createSlice({
  name: "productsData",
  initialState,
  reducers: {
    showGrid: (state, action: PayloadAction<boolean>) => {
      state.showGrid = action.payload;
    },
    categoryChangesModal: (state, action: PayloadAction<boolean>) => {
      state.categoryChangesModal = action.payload;
    },
    discardCategoryChanges: (state, action: PayloadAction<boolean>) => {
      state.discardCategoryChanges = action.payload;
    },
    setAllProductsPIMCategoryId: (state, action: PayloadAction<number>) => {
      state.AllProductsPIMCategoryId = action.payload;
    },
    setMySelectionPIMCategoryId: (state, action: PayloadAction<number>) => {
      state.MySelectionPIMCategoryId = action.payload;
    },
    setAllGiveeProductsCateroryId: (state, action: PayloadAction<number>) => {
      state.allGiveeProductsCateroryId = action.payload;
    },
    setAllProductsPIMCategoryName: (state, action: PayloadAction<string>) => {
      state.AllProductsPIMCategoryName = action.payload;
    },
    setMySelectionPIMCategoryName: (state, action: PayloadAction<string>) => {
      state.MySelectionPIMCategoryName = action.payload;
    },
    setAllProductsPortalSortSettingId: (state, action: PayloadAction<number>) => {
      state.AllProductsPortalSortSettingId = action.payload;
    },
    setMySelectionPortalSortSettingId: (state, action: PayloadAction<number>) => {
      state.MySelectionPortalSortSettingId = action.payload;
    },
    setAllProductsSortDisplayName: (state, action: PayloadAction<string>) => {
      state.AllProductsSortDisplayName = action.payload;
    },
    setMySelectionSortDisplayName: (state, action: PayloadAction<string>) => {
      state.MySelectionSortDisplayName = action.payload;
    },
    sortProductsModalChanges: (state, action: PayloadAction<boolean>) => {
      state.isSortProductsModalVisible = action.payload;
    },
    setPriceAndInventoryHiddenValue: (state, action: PayloadAction<boolean>) => {
      state.isHidePricingandInventory = action.payload;
    },
    setAllProductsDecoMethodName: (state, action: PayloadAction<string>) => {
      state.AllProductsDecoMethodName = action.payload;
    },
    setAllProductsDecoMethodValue: (state, action: PayloadAction<string>) => {
      state.AllProductsDecoMethodValue = action.payload;
    },
    setMySelectionDecoMethodName: (state, action: PayloadAction<string>) => {
      state.MySelectionDecoMethodName = action.payload;
    },
    setMySelectionDecoMethodValue: (state, action: PayloadAction<string>) => {
      state.MySelectionDecoMethodValue = action.payload;
    },
  },
});

export const {
  showGrid,
  categoryChangesModal,
  discardCategoryChanges,
  setAllProductsPIMCategoryId,
  setMySelectionPIMCategoryId,
  setAllGiveeProductsCateroryId,
  setAllProductsPIMCategoryName,
  setMySelectionPIMCategoryName,
  setAllProductsPortalSortSettingId,
  setMySelectionPortalSortSettingId,
  setAllProductsSortDisplayName,
  setMySelectionSortDisplayName,
  sortProductsModalChanges,
  setPriceAndInventoryHiddenValue,
  setAllProductsDecoMethodName,
  setAllProductsDecoMethodValue,
  setMySelectionDecoMethodName,
  setMySelectionDecoMethodValue,
} = productsStateSlice.actions;
export default productsStateSlice.reducer;
