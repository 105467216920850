/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import React, { useState } from "react";
import { Typography, Form, Row, Button, Col, Space } from "antd";
// import StepsComponent from "components/Common/Steps/Steps";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import {
  IPcnaAccountFormProps,
  IPcnaAccountList,
} from "interfaces/ClientInterface/SpokeCustumApiInterface/IAboutStore";

import { getPageChange } from "store/Slices/store.slice";
import { LoadingComponent } from "components/SpokeCustomComponents/LoadingComponent/LoadingComponent";
import SelectionRadio from "components/SpokeCustomComponents/Common/SelectionRadio/SelectionRadio";
import { ArrowRightOutlined } from "@ant-design/icons";
import { isObjectEmpty } from "utils";
import "./PcnaAccount.scss";
import RequestPcnaAccountModal from "components/SpokeCustomComponents/Common/RequestPcnaAccountModal/RequestPcnaAccountModal";
import { IStoreCreationFormValues } from "interfaces/ClientInterface/IStore";
import { notificationController } from "components/Common/Notification/Notification";

const { Title } = Typography;

const PcnaAccountForm: React.FC<IPcnaAccountFormProps> = ({ form, pcnaAccountsList, loading }) => {
  const pcnaAccountDetailsFormName = "PcnaAccountDetails";
  const dispatch = useAppDispatch();
  const pageNumber = useAppSelector((state) => state.store.pageNumber);
  const pcnaAccountValue = Form.useWatch(pcnaAccountDetailsFormName, {
    form,
    preserve: true,
  });
  const formValues: IStoreCreationFormValues = form.getFieldsValue(true);
  const [openPcnaAccountModal, setOpenPcnaAccountModal] = useState<boolean>(false);
  const pcnaRecordFound = pcnaAccountsList?.length > 0;

  const handleBack = () => {
    dispatch(getPageChange(pageNumber - 1));
  };

  const nextClickHandler = async () => {
    if (pcnaRecordFound) {
      if (!isObjectEmpty(pcnaAccountValue)) {
        form.setFields([
          {
            name: pcnaAccountDetailsFormName,
            value: pcnaAccountValue,
            errors: [],
          },
        ]);
        dispatch(getPageChange(pageNumber + 1));
      } else {
        notificationController.error({
          message: "Please select account before proceeding further",
        });
      }
    } else {
      notificationController.error({
        message: "Please request PCNA ID before proceeding further",
      });
    }

    form.setFields([
      {
        name: "PcnaAccountSkipped",
        value: false,
        errors: [],
      },
    ]);
  };

  const handleSkip = () => {
    form.setFields([
      {
        name: "PcnaAccountSkipped",
        value: true,
        errors: [],
      },
    ]);

    form.setFields([
      {
        name: pcnaAccountDetailsFormName,
        value: "",
        errors: [],
      },
    ]);
    form.setFieldsValue({
      BillingEmailAddress: "",
      BillingCountry: "",
      BillingStreet: "",
      BillingCity: "",
      BillingState: "",
      BillingPostalCode: "",
    });

    dispatch(getPageChange(4));
  };

  const handleRadioButtonChange = (value: string) => {
    const selectedRecord = pcnaAccountsList?.find((ele: IPcnaAccountList) => {
      const title = `${ele.PCNAID} - ${ele.DistributorName}`;
      return value == title;
    });

    form.setFields([
      {
        name: pcnaAccountDetailsFormName,
        value: selectedRecord,
        errors: [],
      },
    ]);
  };

  const renderCard = (item: IPcnaAccountList, index: number) => {
    const isActive = pcnaAccountValue?.PCNAID == item.PCNAID;

    return (
      <Col span={24} key={item.DistributorName}>
        <SelectionRadio
          onClickHandler={handleRadioButtonChange}
          isSelected={isActive}
          title={`${item.PCNAID} - ${item.DistributorName}`}
          uniqueKey={`company-list-${item.DistributorName}-${index}`}
        />
      </Col>
    );
  };

  return (
    <>
      {loading ? (
        <Space className="d-flex justify-center align-center h-300">
          <LoadingComponent />
        </Space>
      ) : (
        <div className="pcna-account-wrapper">
          <Title level={3} className="my-1" data-test-selector="hdgPcnaTitle">
            {`Our records indicate you ${
              !pcnaRecordFound ? "don't" : ""
            } have a PCNA account associated with your email address.`}
          </Title>
          <Title level={5} className="subtitle-info">
            {`${
              pcnaRecordFound
                ? "Confirming your PCNA ID enables custom Givee pricing and allows you to set your billing details."
                : "If you're not a PCNA Distributor, you can request access today."
            }`}
          </Title>

          <>
            <Form.Item name={pcnaAccountDetailsFormName} className="mb-0">
              <Row gutter={[10, 14]} className="mb-32">
                {pcnaRecordFound &&
                  pcnaAccountsList?.map((item: IPcnaAccountList, index: number) => {
                    return renderCard(item, index);
                  })}
              </Row>
            </Form.Item>

            <Row className="mb-32">
              <Button
                block
                size="middle"
                type="primary"
                className="-stripe-btn"
                data-test-selector="btnRequestNewPcnaId"
                onClick={() => {
                  setOpenPcnaAccountModal(true);
                }}
              >
                Request a new PCNA ID
              </Button>
            </Row>
          </>
          {/* <StepsComponent /> */}
          <Row className="my-1 btn-section">
            <Button
              className="text"
              data-test-selector="divSkipForNow"
              type="text"
              onClick={handleSkip}
            >
              Skip for now
              <ArrowRightOutlined />
            </Button>
            <div className="d-flex">
              <Button
                onClick={handleBack}
                style={{ marginRight: "10px" }}
                className="back-step-btn"
                data-test-selector="btnBack"
              >
                Back
              </Button>
              <Form.Item>
                <Button
                  htmlType="submit"
                  className="gray-bg-button"
                  onClick={nextClickHandler}
                  data-test-selector="btnNext"
                >
                  Next
                </Button>
              </Form.Item>
            </div>
          </Row>
        </div>
      )}
      <RequestPcnaAccountModal
        isModalVisible={openPcnaAccountModal}
        handleCancel={() => {
          setOpenPcnaAccountModal(false);
        }}
        successHandlerCallback={() => {
          // eslint-disable-next-line no-console
          console.log("data");
        }}
        userInputFormData={formValues}
        parentFormRef={form}
      />
    </>
  );
};

export default PcnaAccountForm;
