import React from "react";
import { AppRouter } from "routes/AppRouter";
import { IntlProvider } from "react-intl";

function App() {
  return (
    <>
      <IntlProvider locale="en">
        <AppRouter />
      </IntlProvider>
    </>
  );
}

export default App;
