export const IS_ASSOCIATE = true;
export const INACTIVITY_TIME = 20; // in minutes
export const OMS_TYPE_ID = 3; // fetch Quote List
export const ORDER_LIST_DAY = 30; // List of days required to fetch Order List
export const ORDER_LIST_TIME = "11:53"; // Query Parameter for order list
export const DASHBOARD_GRAPH_DAYS_RANGE = "60"; // Day range to fetch dashboard graph
export const FOLDER_ID = 1; //folder id send to upload image
export const DATE_FORMATE = "YYYY-MM-DD";
export const HEAD_NAME = "BStoreCustomer"; //download upload user template
export const LOCALE_ID = 1; // use to upload user list
export const BSTORE_TEMPLATE_NAME = "BStoreCustomerTemplate"; // use to upload user list
export const MINIMUM_INVENTORY = 30;
