import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DomainState {
  domainName: string;
}

const initialState: DomainState = {
  domainName: "",
};

export const domainSlice = createSlice({
  name: "domain",
  initialState,
  reducers: {
    setDomainsNames: (state, action: PayloadAction<string>) => {
      state.domainName = action.payload;
    },
    resetDomainName: (state) => {
      state.domainName = "";
    },
  },
});

export const { setDomainsNames, resetDomainName } = domainSlice.actions;
export default domainSlice.reducer;
