import React from "react";
// Code Splitting
// MainPages
export const DashboardPage = React.lazy(() => import("../pages/Dashboard/DashboardPage"));
export const Logout = React.lazy(() => import("./Logout"));
export const AddStore = React.lazy(() => import("../pages/AddStore/AddStore"));
export const AboutStore = React.lazy(
  () => import("../components/Store/AboutStore/CreateStore/CreateStore")
);
export const NotFound = React.lazy(() => import("../pages/NotFound/NotFound"));

//Dashboard pages
export const DashboardPromotion = React.lazy(
  () => import("../components/Dashboard/DashboardPromotion")
);
export const StoreSetting = React.lazy(
  () => import("../components/Dashboard/StoreSetting/StoreSetting")
);
export const Design = React.lazy(
  () => import("../components/SpokeCustomComponents/Designs/Design/Design")
);
export const Content = React.lazy(() => import("../components/Dashboard/Content/Content"));
export const Products = React.lazy(() => import("../components/Dashboard/Products/Products"));
export const Users = React.lazy(() => import("../components/Dashboard/Users/Users"));
export const Orders = React.lazy(() => import("../components/Dashboard/Orders/Orders"));
export const Quotes = React.lazy(() => import("../components/Dashboard/Quotes/Quotes"));
export const Emails = React.lazy(() => import("../components/SpokeCustomComponents/Emails/Emails"));
export const Shipping = React.lazy(
  () => import("../components/SpokeCustomComponents/Shipping/Shipping")
);
export const StoreAdmin = React.lazy(
  () => import("../components/SpokeCustomComponents/StoreAdmin/StoreAdmin")
);
export const Order = React.lazy(() => import("../components/SpokeCustomComponents/Order/Order"));
// export const Order = React.lazy(() => import("../components/Dashboard/Order/Order"));
export const Guests = React.lazy(() => import("../components/SpokeCustomComponents/Guests/Guest"));
// for Billing
export const Billing = React.lazy(
  () => import("../components/SpokeCustomComponents/Billing/Billing")
);

export const StoreDashboard = React.lazy(
  () => import("../components/SpokeCustomComponents/StoreDashboard/StoreDashboard")
);
export const Stores = React.lazy(() => import("../components/SpokeCustomComponents/Stores/Stores"));
export const OGOrders = React.lazy(
  () => import("../components/SpokeCustomComponents/Orders/Orders")
);
export const Account = React.lazy(
  () => import("../components/SpokeCustomComponents/Account/Account")
);
export const ContactRepresentative = React.lazy(
  () => import("../components/SpokeCustomComponents/ContactRepresentative/ContactRepresentative")
);

export const Login = React.lazy(() => import("../components/SpokeCustomComponents/Login/Login"));

export const ForgetPassword = React.lazy(
  () => import("../components/SpokeCustomComponents/ForgetPassword/ForgetPassword")
);
