import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UploadState {
  // eslint-disable-next-line
  designPageData: any[];
  variantsData: {
    uploadedVariant0: {
      Variant: string;
      VariantName: string;
      VariantUniqueName: string;
      VariantExtention: string;
      VariantUrlData: string;
    };
    uploadedVariant1: {
      Variant: string;
      VariantName: string;
      VariantUniqueName: string;
      VariantExtention: string;
      VariantUrlData: string;
    };
    uploadedVariant2: {
      Variant: string;
      VariantName: string;
      VariantUniqueName: string;
      VariantExtention: string;
      VariantUrlData: string;
    };
    uploadedVariant3: {
      Variant: string;
      VariantName: string;
      VariantUniqueName: string;
      VariantExtention: string;
      VariantUrlData: string;
    };
    uploadedVariant4: {
      Variant: string;
      VariantName: string;
      VariantUniqueName: string;
      VariantExtention: string;
      VariantUrlData: string;
    };
    uploadedVariant5: {
      Variant: string;
      VariantName: string;
      VariantUniqueName: string;
      VariantExtention: string;
      VariantUrlData: string;
    };
  };
}

const designData = localStorage.getItem("designData");
const personalizationColor = localStorage.getItem("personalizationColor");

const initialState: UploadState = {
  designPageData: designData ? [JSON.parse(designData), personalizationColor] : [], // eslint-disable-line
  variantsData: {
    uploadedVariant0: {
      Variant: "",
      VariantName: "",
      VariantUniqueName: "",
      VariantExtention: "",
      VariantUrlData: "",
    },
    uploadedVariant1: {
      Variant: "",
      VariantName: "",
      VariantUniqueName: "",
      VariantExtention: "",
      VariantUrlData: "",
    },
    uploadedVariant2: {
      Variant: "",
      VariantName: "",
      VariantUniqueName: "",
      VariantExtention: "",
      VariantUrlData: "",
    },
    uploadedVariant3: {
      Variant: "",
      VariantName: "",
      VariantUniqueName: "",
      VariantExtention: "",
      VariantUrlData: "",
    },
    uploadedVariant4: {
      Variant: "",
      VariantName: "",
      VariantUniqueName: "",
      VariantExtention: "",
      VariantUrlData: "",
    },
    uploadedVariant5: {
      Variant: "",
      VariantName: "",
      VariantUniqueName: "",
      VariantExtention: "",
      VariantUrlData: "",
    },
  },
};

const designDataSlice = createSlice({
  name: "designData",
  initialState,
  reducers: {
    // eslint-disable-next-line
    addDesignData: (state, action: PayloadAction<any>) => {
      state.designPageData[0] = action.payload;
      localStorage.setItem("designData", JSON.stringify(action.payload));
    },
    // eslint-disable-next-line
    addPersonalizationColorData: (state, action: PayloadAction<any>) => {
      state.designPageData[1] = action.payload;
      localStorage.setItem("personalizationColor", action.payload);
    },
    clearDesignData: (state) => {
      state.designPageData = [];
      // localStorage.setItem("designData", "");
    },
    clearPersonalizationColor: (state) => {
      state.designPageData[1] = [];
      localStorage.setItem("personalizationColor", "");
    },

    //Variant0 reducers
    addVariant0: (state, action: PayloadAction<string>) => {
      state.variantsData.uploadedVariant0 = {
        ...state.variantsData.uploadedVariant0,
        Variant: action.payload,
      };
    },
    addVariant0Name: (state, action: PayloadAction<string>) => {
      state.variantsData.uploadedVariant0 = {
        ...state.variantsData.uploadedVariant0,
        VariantName: action.payload,
      };
    },
    addVariant0UniqueName: (state, action: PayloadAction<string>) => {
      state.variantsData.uploadedVariant0 = {
        ...state.variantsData.uploadedVariant0,
        VariantUniqueName: action.payload,
      };
    },
    addVariant0Extention: (state, action: PayloadAction<string>) => {
      state.variantsData.uploadedVariant0 = {
        ...state.variantsData.uploadedVariant0,
        VariantExtention: action.payload,
      };
    },
    addVariantURL0: (state, action: PayloadAction<string>) => {
      state.variantsData.uploadedVariant0 = {
        ...state.variantsData.uploadedVariant0,
        VariantUrlData: action.payload,
      };
    },
    clearVariant0: (state) => {
      state.variantsData.uploadedVariant0 = {
        Variant: "",
        VariantName: "",
        VariantUniqueName: "",
        VariantExtention: "",
        VariantUrlData: "",
      };
    },

    //Variant1 reducers
    addVariant1: (state, action: PayloadAction<string>) => {
      state.variantsData.uploadedVariant1 = {
        ...state.variantsData.uploadedVariant1,
        Variant: action.payload,
      };
    },
    addVariant1Name: (state, action: PayloadAction<string>) => {
      state.variantsData.uploadedVariant1 = {
        ...state.variantsData.uploadedVariant1,
        VariantName: action.payload,
      };
    },
    addVariant1UniqueName: (state, action: PayloadAction<string>) => {
      state.variantsData.uploadedVariant1 = {
        ...state.variantsData.uploadedVariant1,
        VariantUniqueName: action.payload,
      };
    },
    addVariant1Extention: (state, action: PayloadAction<string>) => {
      state.variantsData.uploadedVariant1 = {
        ...state.variantsData.uploadedVariant1,
        VariantExtention: action.payload,
      };
    },
    addVariantURL1: (state, action: PayloadAction<string>) => {
      state.variantsData.uploadedVariant1 = {
        ...state.variantsData.uploadedVariant1,
        VariantUrlData: action.payload,
      };
    },
    clearVariant1: (state) => {
      state.variantsData.uploadedVariant1 = {
        Variant: "",
        VariantName: "",
        VariantUniqueName: "",
        VariantExtention: "",
        VariantUrlData: "",
      };
    },

    //Variant2 reducers
    addVariant2: (state, action: PayloadAction<string>) => {
      state.variantsData.uploadedVariant2 = {
        ...state.variantsData.uploadedVariant2,
        Variant: action.payload,
      };
    },
    addVariant2Name: (state, action: PayloadAction<string>) => {
      state.variantsData.uploadedVariant2 = {
        ...state.variantsData.uploadedVariant2,
        VariantName: action.payload,
      };
    },
    addVariant2UniqueName: (state, action: PayloadAction<string>) => {
      state.variantsData.uploadedVariant2 = {
        ...state.variantsData.uploadedVariant2,
        VariantUniqueName: action.payload,
      };
    },
    addVariant2Extention: (state, action: PayloadAction<string>) => {
      state.variantsData.uploadedVariant2 = {
        ...state.variantsData.uploadedVariant2,
        VariantExtention: action.payload,
      };
    },
    addVariantURL2: (state, action: PayloadAction<string>) => {
      state.variantsData.uploadedVariant2 = {
        ...state.variantsData.uploadedVariant2,
        VariantUrlData: action.payload,
      };
    },
    clearVariant2: (state) => {
      state.variantsData.uploadedVariant2 = {
        Variant: "",
        VariantName: "",
        VariantUniqueName: "",
        VariantExtention: "",
        VariantUrlData: "",
      };
    },

    //Variant3 reducers
    addVariant3: (state, action: PayloadAction<string>) => {
      state.variantsData.uploadedVariant3 = {
        ...state.variantsData.uploadedVariant3,
        Variant: action.payload,
      };
    },
    addVariant3Name: (state, action: PayloadAction<string>) => {
      state.variantsData.uploadedVariant3 = {
        ...state.variantsData.uploadedVariant3,
        VariantName: action.payload,
      };
    },
    addVariant3UniqueName: (state, action: PayloadAction<string>) => {
      state.variantsData.uploadedVariant3 = {
        ...state.variantsData.uploadedVariant3,
        VariantUniqueName: action.payload,
      };
    },
    addVariant3Extention: (state, action: PayloadAction<string>) => {
      state.variantsData.uploadedVariant3 = {
        ...state.variantsData.uploadedVariant3,
        VariantExtention: action.payload,
      };
    },
    addVariantURL3: (state, action: PayloadAction<string>) => {
      state.variantsData.uploadedVariant3 = {
        ...state.variantsData.uploadedVariant3,
        VariantUrlData: action.payload,
      };
    },
    clearVariant3: (state) => {
      state.variantsData.uploadedVariant3 = {
        Variant: "",
        VariantName: "",
        VariantUniqueName: "",
        VariantExtention: "",
        VariantUrlData: "",
      };
    },

    //Variant4 reducers
    addVariant4: (state, action: PayloadAction<string>) => {
      state.variantsData.uploadedVariant4 = {
        ...state.variantsData.uploadedVariant4,
        Variant: action.payload,
      };
    },
    addVariant4Name: (state, action: PayloadAction<string>) => {
      state.variantsData.uploadedVariant4 = {
        ...state.variantsData.uploadedVariant4,
        VariantName: action.payload,
      };
    },
    addVariant4UniqueName: (state, action: PayloadAction<string>) => {
      state.variantsData.uploadedVariant4 = {
        ...state.variantsData.uploadedVariant4,
        VariantUniqueName: action.payload,
      };
    },
    addVariant4Extention: (state, action: PayloadAction<string>) => {
      state.variantsData.uploadedVariant4 = {
        ...state.variantsData.uploadedVariant4,
        VariantExtention: action.payload,
      };
    },
    addVariantURL4: (state, action: PayloadAction<string>) => {
      state.variantsData.uploadedVariant4 = {
        ...state.variantsData.uploadedVariant4,
        VariantUrlData: action.payload,
      };
    },
    clearVariant4: (state) => {
      state.variantsData.uploadedVariant4 = {
        Variant: "",
        VariantName: "",
        VariantUniqueName: "",
        VariantExtention: "",
        VariantUrlData: "",
      };
    },

    //Variant2 reducers
    addVariant5: (state, action: PayloadAction<string>) => {
      state.variantsData.uploadedVariant5 = {
        ...state.variantsData.uploadedVariant5,
        Variant: action.payload,
      };
    },
    addVariant5Name: (state, action: PayloadAction<string>) => {
      state.variantsData.uploadedVariant5 = {
        ...state.variantsData.uploadedVariant5,
        VariantName: action.payload,
      };
    },
    addVariant5UniqueName: (state, action: PayloadAction<string>) => {
      state.variantsData.uploadedVariant5 = {
        ...state.variantsData.uploadedVariant5,
        VariantUniqueName: action.payload,
      };
    },
    addVariant5Extention: (state, action: PayloadAction<string>) => {
      state.variantsData.uploadedVariant5 = {
        ...state.variantsData.uploadedVariant5,
        VariantExtention: action.payload,
      };
    },
    addVariantURL5: (state, action: PayloadAction<string>) => {
      state.variantsData.uploadedVariant5 = {
        ...state.variantsData.uploadedVariant5,
        VariantUrlData: action.payload,
      };
    },
    clearVariant5: (state) => {
      state.variantsData.uploadedVariant5 = {
        Variant: "",
        VariantName: "",
        VariantUniqueName: "",
        VariantExtention: "",
        VariantUrlData: "",
      };
    },
  },
});

export const {
  addDesignData,
  addPersonalizationColorData,
  clearDesignData,
  addVariant0,
  addVariant0Name,
  addVariant0UniqueName,
  addVariant0Extention,
  addVariantURL0,
  clearVariant0,
  addVariant1,
  addVariant1Name,
  addVariant1UniqueName,
  addVariant1Extention,
  addVariantURL1,
  clearVariant1,
  addVariant2,
  addVariant2Name,
  addVariant2UniqueName,
  addVariant2Extention,
  addVariantURL2,
  clearVariant2,
  addVariant3,
  addVariant3Name,
  addVariant3UniqueName,
  addVariant3Extention,
  addVariantURL3,
  clearVariant3,
  addVariant4,
  addVariant4Name,
  addVariant4UniqueName,
  addVariant4Extention,
  addVariantURL4,
  clearVariant4,
  addVariant5,
  addVariant5Name,
  addVariant5UniqueName,
  addVariant5Extention,
  addVariantURL5,
  clearVariant5,
} = designDataSlice.actions;
export default designDataSlice.reducer;
