/* eslint-disable no-console */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LOCAL_STORAGE_KEYS, setLocalStorageData } from "api/Services/localStorage.service";

interface ChildPortalState {
  id: number;
  previewUrl: string;
}

const storedChildPortalId = localStorage.getItem("childPortalId");
const initialState: ChildPortalState = {
  id: storedChildPortalId ? parseInt(storedChildPortalId) : 0,
  previewUrl: "",
};

const childPortalSlice = createSlice({
  name: "childPortal",
  initialState,
  reducers: {
    setChildPortalId: (state, action: PayloadAction<number>) => {
      state.id = action.payload;
      setLocalStorageData(LOCAL_STORAGE_KEYS.CHILD_PORTAL_ID, action?.payload?.toString());
    },
    getPreviewUrl: (state, action: PayloadAction<string>) => {
      state.previewUrl = action.payload;
      setLocalStorageData(LOCAL_STORAGE_KEYS.PREVIEW_URL, action.payload);
    },
  },
});

export const { setChildPortalId, getPreviewUrl } = childPortalSlice.actions;
export default childPortalSlice.reducer;
