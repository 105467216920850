import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface GuestData {
  id: number;
  firstName: string;
  lastName: string;
  company: string;
  email: string;
  giftClaimed: string;
  details: string;
}

interface GuestState {
  guests: GuestData[];
}

const initialState: GuestState = {
  guests: [],
};

export const guestSlice = createSlice({
  name: "guest",
  initialState,
  reducers: {
    addGuest: (state, action: PayloadAction<GuestData>) => {
      state.guests.push(action.payload);
    },
    setGuests: (state, action: PayloadAction<GuestData[]>) => {
      state.guests = action.payload;
    },
  },
});

export const { addGuest, setGuests } = guestSlice.actions;

export default guestSlice.reducer;
