/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { Divider, Space, Tooltip } from "antd";
import {
  IInvoiceDownloadLoading,
  IInvoiceListItem,
} from "interfaces/ApiInterface/SpokeCustumApiInterface/IBilling";

import { currencyFormatWithTwoDecimals } from "utils";
import moment from "moment";

export const DEFAULT_PENDING_STATUS = "Pending";

export const columns = (props: {
  invoiceDownloadLoader: IInvoiceDownloadLoading;
  /* eslint-disable no-unused-vars */
  downloadInvoice: (invoiceNumber: string) => void;
  isAddressEditable: boolean;
  /* eslint-disable no-unused-vars */
  onPayClick: (row: IInvoiceListItem) => void;
}) => {
  const { invoiceDownloadLoader, downloadInvoice, isAddressEditable, onPayClick } = props;
  return [
    {
      title: "Invoice",
      dataIndex: "ZnodeInvoiceNo",
      sorter: (a: IInvoiceListItem, b: IInvoiceListItem) =>
        (a?.ZnodeInvoiceNo || "").localeCompare(b?.ZnodeInvoiceNo || ""),
    },
    {
      title: "Date",
      dataIndex: "Date",
      sorter: (a: IInvoiceListItem, b: IInvoiceListItem) =>
        (a?.Date || "").localeCompare(b?.Date || ""),

      render: (dateValue: string) => {
        const date = moment(dateValue, "YYYY-MM-DD");
        return date?.format("MM/DD/YYYY") || "";
      },
    },
    {
      title: "Total",
      dataIndex: "TotalAmount",
      sorter: (a: IInvoiceListItem, b: IInvoiceListItem) =>
        (a?.TotalAmount || "").localeCompare(b?.TotalAmount || ""),
      render: (price: string) => {
        return currencyFormatWithTwoDecimals(Number(price));
      },
    },

    {
      title: "Status",
      dataIndex: "Paid",
      sorter: (a: IInvoiceListItem, b: IInvoiceListItem) => Number(a?.Paid) - Number(b?.Paid),
      render: (paymentFlag: boolean) => {
        return (
          <Space size={8} align="center">
            <div className="status-text">{paymentFlag ? "Paid" : DEFAULT_PENDING_STATUS}</div>
          </Space>
        );
      },
    },
    {
      title: "",
      dataIndex: "ZnodeInvoiceNo",
      render: (text: string, row: IInvoiceListItem) => {
        return (
          <Space direction="horizontal" size={8} split={<Divider type="vertical" />}>
            <div>
              <div>
                {invoiceDownloadLoader?.loading && invoiceDownloadLoader?.invoiceNumber == text ? (
                  <div className="download-link">Downloading...</div>
                ) : (
                  <Tooltip
                    title={
                      isAddressEditable
                        ? "Please update the billing details to download the PDF"
                        : null
                    }
                  >
                    <div
                      className={isAddressEditable ? "disable-download-link" : "download-link"}
                      onClick={() => {
                        if (!isAddressEditable) {
                          downloadInvoice(text);
                        }
                      }}
                    >
                      Download PDF
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
            {!row?.Paid && (
              <Tooltip
                title={
                  isAddressEditable
                    ? "Please update the billing details before the transaction"
                    : null
                }
              >
                <div
                  className={isAddressEditable ? "disable-pay" : "pay-link"}
                  onClick={() => {
                    if (!isAddressEditable) {
                      onPayClick && onPayClick(row);
                    }
                  }}
                >
                  Pay
                </div>
              </Tooltip>
            )}
          </Space>
        );
      },
      width: "230px",
    },
  ];
};

/**
 * TODO: Need to remove this constant data after API Integration
 */
export const STATES = [
  {
    Text: "Alabama",
    Value: "AL",
  },
  {
    Text: "Alaska",
    Value: "AK",
  },
  {
    Text: "American Samoa",
    Value: "AS",
  },
  {
    Text: "Arizona",
    Value: "AZ",
  },
  {
    Text: "Arkansas",
    Value: "AR",
  },
  {
    Text: "California",
    Value: "CA",
  },
  {
    Text: "Colorado",
    Value: "CO",
  },
  {
    Text: "Connecticut",
    Value: "CT",
  },
  {
    Text: "Delaware",
    Value: "DE",
  },
  {
    Text: "District Of Columbia",
    Value: "DC",
  },
  {
    Text: "Federated States Of Micronesia",
    Value: "FM",
  },
  {
    Text: "Florida",
    Value: "FL",
  },
  {
    Text: "Georgia",
    Value: "GA",
  },
  {
    Text: "Guam",
    Value: "GU",
  },
  {
    Text: "Hawaii",
    Value: "HI",
  },
  {
    Text: "Idaho",
    Value: "ID",
  },
  {
    Text: "Illinois",
    Value: "IL",
  },
  {
    Text: "Indiana",
    Value: "IN",
  },
  {
    Text: "Iowa",
    Value: "IA",
  },
  {
    Text: "Kansas",
    Value: "KS",
  },
  {
    Text: "Kentucky",
    Value: "KY",
  },
  {
    Text: "Louisiana",
    Value: "LA",
  },
  {
    Text: "Maine",
    Value: "ME",
  },
  {
    Text: "Marshall Islands",
    Value: "MH",
  },
  {
    Text: "Maryland",
    Value: "MD",
  },
  {
    Text: "Massachusetts",
    Value: "MA",
  },
  {
    Text: "Michigan",
    Value: "MI",
  },
  {
    Text: "Minnesota",
    Value: "MN",
  },
  {
    Text: "Mississippi",
    Value: "MS",
  },
  {
    Text: "Missouri",
    Value: "MO",
  },
  {
    Text: "Montana",
    Value: "MT",
  },
  {
    Text: "Nebraska",
    Value: "NE",
  },
  {
    Text: "Nevada",
    Value: "NV",
  },
  {
    Text: "New Hampshire",
    Value: "NH",
  },
  {
    Text: "New Jersey",
    Value: "NJ",
  },
  {
    Text: "New Mexico",
    Value: "NM",
  },
  {
    Text: "New York",
    Value: "NY",
  },
  {
    Text: "North Carolina",
    Value: "NC",
  },
  {
    Text: "North Dakota",
    Value: "ND",
  },
  {
    Text: "Northern Mariana Islands",
    Value: "MP",
  },
  {
    Text: "Ohio",
    Value: "OH",
  },
  {
    Text: "Oklahoma",
    Value: "OK",
  },
  {
    Text: "Oregon",
    Value: "OR",
  },
  {
    Text: "Palau",
    Value: "PW",
  },
  {
    Text: "Pennsylvania",
    Value: "PA",
  },
  {
    Text: "Puerto Rico",
    Value: "PR",
  },
  {
    Text: "Rhode Island",
    Value: "RI",
  },
  {
    Text: "South Carolina",
    Value: "SC",
  },
  {
    Text: "South Dakota",
    Value: "SD",
  },
  {
    Text: "Tennessee",
    Value: "TN",
  },
  {
    Text: "Texas",
    Value: "TX",
  },
  {
    Text: "Utah",
    Value: "UT",
  },
  {
    Text: "Vermont",
    Value: "VT",
  },
  {
    Text: "Virgin Islands",
    Value: "VI",
  },
  {
    Text: "Virginia",
    Value: "VA",
  },
  {
    Text: "Washington",
    Value: "WA",
  },
  {
    Text: "West Virginia",
    Value: "WV",
  },
  {
    Text: "Wisconsin",
    Value: "WI",
  },
  {
    Text: "Wyoming",
    Value: "WY",
  },
];

export const COUNTRIES = [
  { label: "United States", value: "us" },
  // { label: "Canada", value: "ca" },
];

export const getStateValueUsingCode = (code: string) => {
  const getStateName = STATES?.find((el) => {
    return el?.Value == code;
  });
  return getStateName?.Text || "";
};

export const getCountryValueUsingCode = (code: string) => {
  const getStateName = COUNTRIES?.find((el) => {
    return el?.value == code;
  });
  return getStateName?.label || "";
};
