import React, { useEffect } from "react";

const PaymentReceiver = () => {
  useEffect(() => {
    showHideIframe();
    window.addEventListener("message", receiveMessage, false);

    return () => {
      if (window) {
        window.removeEventListener("message", receiveMessage);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showHideIframe = () => {
    // Show the iframe_holder and hide the loadingMessage
    const iframeHolder = window.parent.parent.document.getElementById("authorize_payment");
    const loadingMessage = window.parent.parent.document.getElementById("loadingMessage");
    if (iframeHolder && loadingMessage) {
      loadingMessage.style.display = "none";
      iframeHolder.style.visibility = "visible";
    }
  };

  const receiveMessage = (event: MessageEvent) => {
    // eslint-disable-next-line no-console
    // console.log({ event });
    if (event && event.data) {
      FinalizeAuthorizePaymentFunction(event.data);
    }
    // if (window.location.hash && window.location.hash.length > 1) {
    //   FinalizeAuthorizePaymentFunction(window.location.hash);
    // }
  };

  const parseQueryString = (queryString: string) => {
    const params: Record<string, string> = {};
    // Check if queryString is not an empty string and is of type string
    if (queryString !== "" && typeof queryString === "string") {
      const arr = [];
      const firstAmpIndex = queryString?.indexOf("&");
      if (firstAmpIndex >= 0) {
        const action = queryString.slice(0, firstAmpIndex);
        const response = queryString.slice(firstAmpIndex + 1);
        if (firstAmpIndex !== -1) {
          arr.push(action);
          arr.push(response);
        }
      } else {
        arr.push(queryString);
      }
      arr.forEach((keyVal) => {
        const [key, value] = keyVal.split("=");
        if (key !== undefined && value !== undefined) {
          params[key] = decodeURIComponent(value);
        }
      });
    }
    return params;
  };

  const FinalizeAuthorizePaymentFunction = (queryStr: string) => {
    const params = parseQueryString(queryStr);
    const input = params["action"];
    switch (input) {
      case "successfulSave": {
        break;
      }
      case "cancel": {
        closeIframe();
        break;
      }
      case "resizeWindow": {
        const width = params["width"];
        const height = params["height"];
        const iframe = document.getElementById("add_payment") as HTMLIFrameElement;
        if (iframe && iframe.style) {
          iframe.style.width = width || "";
          iframe.style.height = height || "";
        }
        break;
      }
      case "transactResponse": {
        const response: string = params["response"] || "";
        closeModalIframe(response);
        break;
      }
    }
  };

  const closeIframe = () => {
    const eventName = "closeAuthorizeDiv";
    window.parent.parent.postMessage({ event: eventName }, "*");
  };

  const closeModalIframe = (AuthorizeMessage: string) => {
    const eventName = "closeModalIframe";
    // Send a message to the parent window
    window.parent.parent.postMessage({ event: eventName, data: JSON.parse(AuthorizeMessage) }, "*");
  };

  return <div></div>;
};

export default PaymentReceiver;
