/* eslint-disable max-len */
import { httpApi } from "../../Http.api";
import { AxiosError } from "axios";
import {
  StoreResponse,
  ISubDomainNameResponse,
  ICopyStoreData,
  IRemoveUploadData,
  IActiveStoreData,
  IStoreCreationPayload,
  IValidatecompanyNameData,
  IRequestPcnaPayload,
} from "interfaces/ApiInterface/IStore";
import { IValidateUserEmail } from "interfaces/ClientInterface/SpokeCustumApiInterface/IAboutStore";

const artifiWebsiteID = process.env.REACT_APP_ARTIFI_WEBSITE_ID as string;
const artifiClientKey = process.env.REACT_APP_ARTIFI_WEB_API_CLIENT_KEY as string;

/** Spoke Custom Store APIs Start */

// Create new store and add in store list
export const createStoreFunc = (data: IStoreCreationPayload) => {
  return httpApi
    .post("/bstoreswebstore/createwebstorebstore", { ...data })
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

// Delete Store API Call
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deleteStoreFunc = (storeName: string | null): Promise<any> => {
  if (!storeName) {
    return Promise.reject("storeName is null or undefined");
  }

  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "/bstores/Delete",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      Ids: storeName,
    }),
  };

  return httpApi
    .request(config)
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

// Get Subdomain Url
export const getSubdomainName = (portalId: number): Promise<ISubDomainNameResponse> => {
  return httpApi
    .get(`/bstores/GetParentStoreDomainName/${portalId}`)
    .then((res: ISubDomainNameResponse) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

export const uploadImageData = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  folderId: number,
  imageName: string,
  imageType: string,
  isReplace: boolean
) => {
  return httpApi
    .post(
      // eslint-disable-next-line max-len
      `/apiupload/upload?folderid=${folderId}&filetype=.${imageType}&isMediaReplace=${isReplace}&filename=${imageName}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

/** Spoke Custom Store APIs end */

// Fetch list of all existing store
export const bStoreData = (portalId: number, userId: number): Promise<StoreResponse> => {
  return httpApi
    .get(`/bstoreswebstore/getbstorelist/${portalId}?UserId=${userId}`)
    .then((res: StoreResponse) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

// Duplicate Store API Call
export const createDuplicateStore = (data: ICopyStoreData) => {
  const payload = {
    BStoreDomainName: data.BStoreDomainName,
    DomainUrl: data.DomainUrl,
    StoreName: data.StoreName,
    PortalId: Number(data.PortalId),
    IsManageBStoreFromWebstore: data.IsManageBStoreFromWebstore,
    UserId: data.UserId,
  };

  return httpApi
    .post("/bstores/copybstore", { ...payload })
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

// Upload Images from Design page
export const uploadDesignImageData = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  portalId: number
) => {
  const url = process.env.REACT_APP_ARTIFI_USER_UPLOAD_URL as string;

  return httpApi
    .post(
      // eslint-disable-next-line max-len
      `https://${url}/api/2.0/UploadPhoto/UploadUserPhotos?frontAppUserId=${portalId}&websiteId=${artifiWebsiteID}&webApiClientKey=${artifiClientKey}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err: AxiosError) => {
      throw err;
    });
};

// Fetch logo variants in Design page
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getDesignImageData = (portalId: number): Promise<any> => {
  const url = process.env.REACT_APP_ARTIFI_GET_USER_UPLOAD_KEY as string;
  return httpApi
    .get(
      // eslint-disable-next-line max-len
      `https://${url}/Designer/UserUploadedPhoto/GetUserPhotosByUserId?userId=${portalId}&websiteId=${artifiWebsiteID}&pageIndex=1&pageSize=50&webApiClientKey=${artifiClientKey}`
    ) // eslint-disable-line
    .then((res: any) => res) // eslint-disable-line
    .catch((err: AxiosError) => {
      throw err;
    });
};

// Delete API of upload media
export const deleteImageData = (data: IRemoveUploadData) => {
  return httpApi
    .post("/apiupload/remove", data)
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

// Make store active or inactive
export const ChangeStoreActiveStatus = (data: IActiveStoreData) => {
  return httpApi
    .post("/bstoreswebstore/updatebstoreisactiveflag", data)
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

/** Store Overview data */
export const getStoreOverview = (portalId: number) => {
  return httpApi
    .get(`/spokecustombstoredashboard/getbstoreoverviewdetails/${portalId}`)
    .then((res) => res)
    .catch((err) => {
      throw err;
    });
};

export const getCompaniesList = (
  UserId: number | undefined,
  pageIndex: number,
  pageSize: number
) => {
  const apiUrl = `/spokecustombstore/spokecustomgetcompanynamebyuserid/${UserId}/${pageSize}/${pageIndex}`;
  return httpApi
    .get(apiUrl)
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

export const validateUserEmail = (payload: IValidateUserEmail) => {
  return httpApi
    .post("/spokecustomwebstore/validateuser", payload)
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

// Check if company name already exists
export const validatecompanyName = (data: IValidatecompanyNameData) => {
  return httpApi
    .post("/spokecustomwebstore/validatecompany", data)
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

// eslint-disable-next-line no-unused-vars
export const getPcnaAccountList = (emailId: string) => {
  const apiUrl = "/spokecustombstore/spokecustomgetpcnadetails";
  return httpApi
    .post(apiUrl, {
      UserName: emailId,
      // UserName: "carrie@egpromo.com",
    })
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

// Check if company name already exists
export const requestForPcnaAccount = (data: IRequestPcnaPayload) => {
  return httpApi
    .post("/spokecustombstore/submitpcnaaccountdetails", data)
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};
