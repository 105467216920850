/* eslint-disable max-lines-per-function */
import React, { useEffect } from "react";
import { Typography, Input, Form, Row, Button, Col, Select } from "antd";
// import StepsComponent from "components/Common/Steps/Steps";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import {
  IOnBoardingBillingDetailsprops,
  IPcnaAccountList,
} from "interfaces/ClientInterface/SpokeCustumApiInterface/IAboutStore";
import { isValidEmail } from "utils";
import { getPageChange } from "store/Slices/store.slice";
import { COUNTRIES, STATES } from "components/SpokeCustomComponents/Billing/Billing.Constant";
import "./OnBoardingBillingDetails.scss";
const { Title } = Typography;

const OnBoardingBillingDetails: React.FC<IOnBoardingBillingDetailsprops> = ({ form }) => {
  const dispatch = useAppDispatch();
  const pageNumber = useAppSelector((state) => state.store.pageNumber);
  const pcnaAccountDetailsFormName = "PcnaAccountDetails";
  const pcnaAccountDetailsFormValue: IPcnaAccountList = form.getFieldValue(
    pcnaAccountDetailsFormName
  );
  // eslint-disable-next-line no-unused-vars
  const companyName = form.getFieldValue("CompanyName");
  const emailAddress = form.getFieldValue("EmailAddress");

  useEffect(() => {
    form.setFieldsValue({
      BillingEmailAddress: emailAddress,
      BillingCountry: pcnaAccountDetailsFormValue?.Country || "",
      BillingStreet: pcnaAccountDetailsFormValue?.Street || "",
      BillingCity: pcnaAccountDetailsFormValue?.City || "",
      BillingState: pcnaAccountDetailsFormValue?.State || "",
      BillingPostalCode: pcnaAccountDetailsFormValue?.PostalCode || "",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pcnaAccountDetailsFormValue]);

  const handleBack = () => {
    dispatch(getPageChange(pageNumber - 1));
  };

  const nextClickHandler = async () => {
    try {
      await form.validateFields([
        "BillingEmailAddress",
        "BillingCountry",
        "BillingStreet",
        "BillingCity",
        "BillingState",
        "BillingPostalCode",
      ]);
      dispatch(getPageChange(pageNumber + 1));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };
  const stateList = () => {
    const states = STATES.map((st) => {
      return {
        label: st.Text,
        value: st.Text
      };
    });
    return states;
  };
  return (
    <>
      <div className="your-store billing-details-wrapper">
        <Row gutter={[16, 16]} className="d-flex justify-center align-center mb-32">
          <Col span={24}>
            <Title level={3} className="my-1" data-test-selector="hdgWhoStoreFor">
              {/* {`Please review your billing details for ${companyName || ""}.`} */}
              Please review your billing details
            </Title>
          </Col>
          <Col span={24}>
            <div>
              <div className="font-style">Billing email</div>
              <Form.Item
                name="BillingEmailAddress"
                className="m-0"
                rules={[
                  { required: true, message: "Email address is required", whitespace: true },
                  {
                    validator: (rule, value) => {
                      if (!value || isValidEmail(value)) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(new Error("The input is not a valid Email"));
                      }
                    },
                  },
                  {
                    max: 50,
                    message: "Input must not exceed the maximum character limit of 50",
                  },
                ]}
              >
                <Input
                  data-test-selector="txtEmailAddress "
                  placeholder="e.g. accounts@ritex.com"
                  allowClear={true}
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={24}>
            <div className="font-style">Country</div>
            <Form.Item
              name={"BillingCountry"}
              className="m-0"
              rules={[{ required: true, message: "Country is required" }]}
            >
              <Select
                placeholder="Select Country"
                bordered={true}
                data-test-selector={"drpCountryList"}
                options={COUNTRIES}
              />
            </Form.Item>
            <Title className="m-0 country-footer-message">
              Givee is only available for Distributors in the United States
            </Title>
          </Col>
          <Col span={24}>
            <div className="font-style">Street</div>
            <Form.Item
              name={"BillingStreet"}
              className="m-0"
              rules={[{ required: true, message: "Street is required", whitespace: true }]}
            >
              <Input data-test-selector="txtCompanyName" allowClear={true} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <div className="font-style">City/town</div>
            <Form.Item
              name={"BillingCity"}
              className="m-0"
              rules={[{ required: true, message: "City  is required", whitespace: true }]}
            >
              <Input data-test-selector="txtCity" allowClear={true} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <div className="font-style">State</div>
            <Form.Item
              name={"BillingState"}
              className="m-0"
              rules={[{ required: true, message: "State is required", whitespace: true }]}
            >
              <Select options={stateList()} data-test-selector="drpState" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <div className="font-style">ZIP Code</div>
            <Form.Item
              name={"BillingPostalCode"}
              className="m-0"
              rules={[{ required: true, message: "Postal Code is required", whitespace: true }]}
            >
              <Input data-test-selector="txtPostalCode" allowClear={true} />
            </Form.Item>
          </Col>
        </Row>
        {/* <StepsComponent /> */}
        <Row className="my-1" style={{ justifyContent: "end" }}>
          <div className="back-btn">
            <Button onClick={handleBack} data-test-selector="btnCancel">
              Back
            </Button>
          </div>
          <div className="next-btn">
            <Form.Item>
              <Button
                onClick={nextClickHandler}
                className="gray-bg-button"
                data-test-selector="btnNext"
              >
                Next
              </Button>
            </Form.Item>
          </div>
        </Row>
      </div>
    </>
  );
};

export default OnBoardingBillingDetails;
