/* eslint-disable no-irregular-whitespace */
import React, { useEffect, useState } from "react";
import { Upload, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { addImage, removeImageByIndex, storeMediaId } from "store/Slices/uploadImage.slice";
import { FOLDER_ID } from "config/constant";
import { notificationController } from "components/Common/Notification/Notification";
import { uploadImageData } from "api/Services/SpokeCustumAPIs/store.api";
import { CloseOutlined, PictureOutlined } from "@ant-design/icons";
import { useAppSelector } from "hooks/reduxHooks";
import { useDispatch } from "react-redux";
// import { beforeUpload } from "utils";
import "./UploadImage.scss";
import { RcFile } from "antd/es/upload";
import { ERROR_SIZE_MSG, TEN_MB_IN_BYTES } from "config/SpokeCustom.Constant";
import { addTimestampToFilename } from "utils";

const { Dragger } = Upload;
const { Title } = Typography;

interface IUploadProps {
  showButton: boolean;
  // eslint-disable-next-line no-unused-vars
  onMediaIdReceived: (mediaId: string) => void;
}

const UploadImg: React.FC<IUploadProps> = ({ onMediaIdReceived }) => {
  const dispatch = useDispatch();
  const uploadedImages = useAppSelector((state) => state.upload.uploadedImages);
  const [loading, setLoading] = useState<boolean>(false);
  const [imageName, setImageName] = useState<string>(localStorage.getItem("imageName") || "");

  useEffect(() => {
    const storedImageName = localStorage.getItem("imageName");
    if (storedImageName) {
      setImageName(storedImageName);
    }
  }, []);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined style={{ color: "#000" }} /> : <PictureOutlined />}
      <div className="info-text-dragger">
        <span style={{ color: "#4D9CD4" }}>Upload a file</span> or drag and drop
      </div>
      <p className="ant-upload-hint">PNG or SVG up to 10MB</p>
    </div>
  );

  const beforeUpload = (file: RcFile): Promise<boolean> => {
    const allowedTypes = [
      "image/png",
      "image/svg+xml",
      // "application/postscript",
      // "application/illustrator",
    ];

    return new Promise((resolve) => {
      const reader = new FileReader();

      if (!allowedTypes.includes(file.type)) {
        notificationController.error({
          message: "Please upload one of the accepted file types: PNG or SVG",
        });
        resolve(false);
        return;
      }

      reader.readAsDataURL(file);
      reader.addEventListener("load", (event) => {
        const _loadedImageUrl = event.target?.result;
        const image = document.createElement("img");
        image.src = _loadedImageUrl as string;

        image.addEventListener("load", () => {
          if (!(file?.size <= TEN_MB_IN_BYTES)) {
            notificationController.error({
              message: ERROR_SIZE_MSG,
            });
            resolve(false);
            return;
          }

          resolve(true);
        });
      });
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const customRequest = async (file: any) => {
    const newImageName = file.file.name;
    setImageName(newImageName);
    localStorage.setItem("imageName", newImageName);
    setLoading(true);

    const formData = new FormData();
    const [imageName, imageType] = file.file.name.split(".");
    const isReplace = true;
    formData.append("image", file.file);

    try {
      const res = await uploadImageData(
        formData,
        FOLDER_ID,
        addTimestampToFilename(imageName),
        imageType,
        isReplace
      );
      notificationController.success({ message: "File uploaded successfully" });
      if (res.data && res.data[0] && res.data[0].MediaId && onMediaIdReceived) {
        dispatch(storeMediaId(res.data[0].MediaId));
        onMediaIdReceived(res.data[0].MediaId);
      }

      const src = await new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.file);
        reader.onload = () => resolve(reader.result as string);
      });
      dispatch(addImage(src));
    } catch (error) {
      notificationController.error({ message: "File upload failed" });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = () => {
    onMediaIdReceived("0");
    dispatch(removeImageByIndex());
  };

  const props = {
    name: "file",
    customRequest,
    maxCount: 1,
    showUploadList: false,
    beforeUpload: beforeUpload,
  };

  return (
    <div>
      <Title className="upload-file-title">Upload file</Title>
      {/* <Form.Item name={"uploadFile"}> */}
      <div className="upload-container">
        <Dragger
          {...props}
          multiple={false}
          beforeUpload={beforeUpload}
          data-test-selector="imageUpload"
        >
          {uploadedImages.length > 0 ? (
            <img src={uploadedImages} alt="Uploaded" style={{ width: "100%" }} />
          ) : (
            uploadButton
          )}
        </Dragger>
        {uploadedImages.length > 0 && (
          <div className="file-info">
            <span className="file-name">{imageName}</span>{" "}
            <CloseOutlined
              data-test-selector="btnCancel"
              className="close-icon"
              onClick={() => handleDelete()}
            />
          </div>
        )}
      </div>
      {/* </Form.Item> */}
    </div>
  );
};

export default UploadImg;
