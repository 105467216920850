/* eslint-disable max-len */
import {
  OldECommerceItemLimit,
  // ECommerceItemLimit,
  OldECommerceStoreOption,
  // ECommerceStoreOption,
  OldGiftStoreOption,
  // GiftStoreOption,
  OldGiftingItemLimit,
  // GiftingItemLimit,
  OldGiftingStoreCredit,
  // GiftingStoreCredit,
} from "Icon/Icon";

export const STORE_TYPE_ATTRIBUTES = {
  GIFTING_STORE: "GiftingStore",
  E_COMMERCE_STORE: "EcommerceStore",
};

export const GIFTING_STORE_LIMITS = {
  QUANTITY_BASED: "QtyBased",
  MONETARY_BASED: "MonetoryBased",
};

export const E_COMMERCE_STORE_LIMITS = {
  E_COMMERCE_ONLY_STORE: "ECommerceStoreOnly",
  E_COMMERCE_WITH_CREDIT: "EcommerceWithStoreCredit",
};

export const STORE_TYPE_OPTIONS = [
  {
    title: "Gift store",
    subTitle:
      "Show appreciation to your guests and employees by offering them either a set number of free gifts or store credits.",
    image: OldGiftStoreOption,
    value: STORE_TYPE_ATTRIBUTES.GIFTING_STORE,
  },
  {
    title: "E-Commerce",
    subTitle:
      "Guests purchase items directly from your store. You also have the option to provide store credits.",
    image: OldECommerceStoreOption,
    value: STORE_TYPE_ATTRIBUTES.E_COMMERCE_STORE,
  },
];

export const GIFTING_METHOD_OPTIONS = [
  {
    title: "Item limit",
    subTitle: "Set a limit on the number of gifts a guest can order. Prices are hidden.",
    image: OldGiftingItemLimit,
    value: GIFTING_STORE_LIMITS.QUANTITY_BASED,
  },
  {
    title: "Store credit",
    subTitle:
      "Provide your guests with a monetary value they can spend on their desired items. Prices are visible.",
    image: OldGiftingStoreCredit,
    value: GIFTING_STORE_LIMITS.MONETARY_BASED,
  },
];

export const E_COMMERCE_OPTIONS = [
  {
    title: "No store credits",
    subTitle: "Guests will pay full store price for their items. Prices are visible.",
    image: OldECommerceItemLimit,
    value: E_COMMERCE_STORE_LIMITS.E_COMMERCE_ONLY_STORE,
  },
  {
    title: "Store credit",
    subTitle:
      "Provide your guests with a monetary value to spend on their desired items. They can also use their own funds to exceed their store credits. Prices are visible.",
    image: OldGiftingStoreCredit,
    value: E_COMMERCE_STORE_LIMITS.E_COMMERCE_WITH_CREDIT,
  },
];
