export const themeSetting = {
  token: {
    colorPrimary: "#4D9CD4",
    colorSecondary: "#002375",
    colorIcon: "#000000",
    popHeader: "#000000",
    colorBgContainer: "#ffffff",
    colorGrey: "#7d7d7d",
    fontFamily: "Inter-Regular",
  },
  components: {
    Radio: {
      colorPrimary: "#2354a8",
    },
  },
};
