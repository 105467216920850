/* eslint-disable max-len */
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { withLoading } from "hocs/withLoading.hoc";
import { DashboardPage, Logout } from "./routes";
import LoginPage from "pages/Login/LoginPage";
import RequireAuth from "./RequireAuth";
import Login from "components/SpokeCustomComponents/Login/Login";
import AuthorizeRedirectPage from "./AuthorizeRedirectPage";
import StripeRedirectPage from "components/SpokeCustomComponents/StoreAdmin/StripeConnectPages/StripeRedirectPage";
import SalesForceChatBotWrapper from "components/SpokeCustomComponents/SalesForceChatBot/SalesForceWrapper";
import CreateStore from "components/Store/AboutStore/CreateStore/CreateStore";
import AuthLayout from "layouts/AuthLayout/AuthLayout";
export const DASHBOARD_PATH = "/dashboard";

const Dashboard = withLoading(DashboardPage);
const LogoutFallback = withLoading(Logout);

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <Dashboard />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/redirect/:portalId/:stripeAccountId" element={<StripeRedirectPage />} />
        <Route path="/pay" element={<AuthorizeRedirectPage />} />
        <Route path={DASHBOARD_PATH} element={protectedLayout}>
          <Route path="*" element={<Dashboard />} />
        </Route>
        <Route path="/" element={<AuthLayout />}>
          <Route path="/" element={<LoginPage />} />
          <Route path="dashboard/login" element={<Login />} />
        </Route>
        <Route path="/dashboard/about-store" element={<CreateStore />} />
        <Route path="/logout" element={<LogoutFallback />} />
      </Routes>
      <SalesForceChatBotWrapper />
    </BrowserRouter>
  );
};
