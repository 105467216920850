/* eslint-disable indent */
import React from "react";
import { Button, Form, Row, Typography } from "antd";

import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { getPageChange } from "store/Slices/store.slice";
// import StepsComponent from "components/Common/Steps/Steps";
import UploadImg from "../UploadImg/UploadImg";
import { ArrowRightOutlined } from "@ant-design/icons";
import "../StoreLogo/StoreLogo.scss";

const { Title } = Typography;

interface IStoreLogoProps {
  // eslint-disable-next-line no-unused-vars
  onMediaIdReceived: (id: string) => void;
}

const StoreLogo: React.FC<IStoreLogoProps> = ({ onMediaIdReceived }) => {
  const dispatch = useAppDispatch();
  const pageNumber = useAppSelector((state) => state.store.pageNumber);

  const handleBackClick = () => {
    dispatch(getPageChange(pageNumber - 1));
  };

  const handleNextClick = async () => {
    dispatch(getPageChange(pageNumber + 1));
  };

  return (
    <div className="store-logo-page">
      <Title level={3} className="my-1 margin-bottom">
        Store logo
      </Title>
      <Title level={5}>Upload the logo you would like to use for your store.</Title>
      <UploadImg showButton={false} onMediaIdReceived={onMediaIdReceived} />
      <div className="mt-32">
        {/* <StepsComponent /> */}
      </div>
      <Row className="my-1 btn-section">
        <Button
          className="text"
          onClick={handleNextClick}
          data-test-selector="divSkipForNow"
          type="text"
        >
          Skip for now
          <span>
            <ArrowRightOutlined />
          </span>
        </Button>
        <div className="d-flex">
          <Button
            onClick={handleBackClick}
            style={{ marginRight: "10px" }}
            className="back-step-btn"
            data-test-selector="btnBack"
          >
            Back
          </Button>
          <Form.Item>
            <Button
              htmlType="submit"
              className="gray-bg-button"
              onClick={handleNextClick}
              data-test-selector="btnCreateStore"
            >
              Next
            </Button>
          </Form.Item>
        </div>
      </Row>
    </div>
  );
};

export default StoreLogo;
