import { httpApi } from "api/Http.api";
import { IValidateTokenResponse } from "interfaces/ApiInterface/IAuth";
import {
  AuthRequest,
} from "interfaces/ClientInterface/IAuth";

export const fetchLoggedInDetails = (loginPayload: AuthRequest): Promise<IValidateTokenResponse> =>
  httpApi
    .post<IValidateTokenResponse>("BStoresWebStore/ValidateCSRToken", { ...loginPayload })
    .then(({ data }) => data);

