import React from "react";
import SalesForceChatBot from "./SalesForceChatBot";
import { useLocation } from "react-router-dom";
import { readToken } from "api/Services/localStorage.service";
import { APPLICATION_ENVIRONMENTS } from "config/SpokeCustom.Constant";

const SalesForceChatBotWrapper: React.FC = () => {
  const { pathname } = useLocation();
  const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT as string;
  const deploymentId = process.env.REACT_APP_SALESFORCE_CHAT_BOT_DEPLOYMENT_ID as string;
  const buttonId = process.env.REACT_APP_SALESFORCE_CHAT_BOT_BUTTON_ID as string;
  const isUserLoggedIn = readToken() ? true : false;

  const hideSalesForceChatBotConditionally = () => {
    const restrictedURL = [
      "/dashboard/about-store",
      "/dashboard/ForgetPassword",
      "/dashboard/login",
      "/pay",
      "/redirect",
    ];
    if (restrictedURL.includes(pathname) || pathname === "/") {
      if (pathname === "/dashboard/about-store") {
        return isUserLoggedIn;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  return (
    <>
      {ENVIRONMENT?.toString() !== APPLICATION_ENVIRONMENTS.LOCAL && (
        <SalesForceChatBot
          deploymentId={deploymentId}
          buttonId={buttonId}
          hideWidget={hideSalesForceChatBotConditionally()}
        />
      )}
    </>
  );
};

export default SalesForceChatBotWrapper;
