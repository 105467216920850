import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UploadState {
  uploadedLogo: string;
}

const initialState: UploadState = {
  uploadedLogo: "",
};

const uploadLogoSlice = createSlice({
  name: "uploadLogo",
  initialState,
  reducers: {
    addLogo: (state, action: PayloadAction<string>) => {
      state.uploadedLogo = action.payload;
    },
    removeLogoByIndex: (state) => {
      state.uploadedLogo = "";
    },
    clearLogo: (state) => {
      state.uploadedLogo = "";
    },
  },
});

export const { addLogo, removeLogoByIndex, clearLogo } = uploadLogoSlice.actions;
export default uploadLogoSlice.reducer;
