import authReducer from "store/Slices/auth.slice";
import useReducer from "store/Slices/user.slice";
import navReducer from "store/Slices/nav.slice";
import storeReducer from "store/Slices/store.slice";
import quoteReducer from "store/Slices/quote.slice";
import orderReducer from "store/Slices/order.slice";
import guestReducer from "store/Slices/guest.Slice";
import navbarReducer from "store/Slices/navbar.slice";
import uploadReducer from "store/Slices/uploadImage.slice";
import catalogUseReducer from "store/Slices/catalogUse.slice";
import productsStateReducer from "store/Slices/products.slice";

import uploadLogoReducer from "store/Slices/uploadLogo.slice";
import uploadFaviconReducer from "./uploadFavicon.slice";
import productDetailsReducer from "./productDetails.slice";

import childPortalReducer from "store/Slices/childPortal.slice";
import domainReducer from "store/Slices/domainSlice";
import Custom2Slice from "./Custom2.slice";
import storeDetailsReducer from "store/Slices/storeDetailsSlice";
import designDataReducer from "store/Slices/designPage.Slice";
import storeValidationReducer from "./storeValidation.slice";
import loadingReducer from "./loadingSlice";

const reducer = {
  auth: authReducer,
  user: useReducer,
  navigation: navReducer,
  store: storeReducer,
  quote: quoteReducer,
  order: orderReducer,
  guest: guestReducer,
  navbar: navbarReducer,
  upload: uploadReducer,
  catalogUse: catalogUseReducer,
  uploadLogo: uploadLogoReducer,
  uploadFavicon: uploadFaviconReducer,
  productDetails: productDetailsReducer,
  childPortal: childPortalReducer,
  domain: domainReducer,
  custom2: Custom2Slice,
  storeDetails: storeDetailsReducer,
  designData: designDataReducer,
  storeValid: storeValidationReducer,
  productsData: productsStateReducer,
  loading: loadingReducer,
};

export default reducer;
