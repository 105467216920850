/* eslint-disable no-console */
/* eslint-disable max-len */
// eslint-disable-next-line max-len
import React from "react";
import { Typography, Form, Row, Button, Col } from "antd";
// import StepsComponent from "components/Common/Steps/Steps";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { IFormInstanceProps } from "interfaces/ClientInterface/SpokeCustumApiInterface/IAboutStore";
import { getPageChange } from "store/Slices/store.slice";
import { STORE_TYPE_OPTIONS } from "components/SpokeCustomComponents/StoreAdmin/StoreAdmin.Constant";
import { IStoreTypeCard } from "interfaces/ClientInterface/SpokeCustumApiInterface/IStoreAdmin";
import CustomRadioButton from "components/SpokeCustomComponents/Common/CustomRadioButton/CustomRadioButton";
import { GLOBAL_ADDITIONAL_ATTRIBUTES } from "config/SpokeCustom.Constant";
import { notificationController } from "components/Common/Notification/Notification";

import "./StoreTypeSelection.scss";
const { Title } = Typography;

const StoreTypeSelection: React.FC<IFormInstanceProps> = ({ form }) => {
  const dispatch = useAppDispatch();
  const pageNumber = useAppSelector((state) => state.store.pageNumber);
  const storeTypeFormValue = Form.useWatch(GLOBAL_ADDITIONAL_ATTRIBUTES.STORE_TYPE, {
    form,
    preserve: true,
  });

  const handleBack = () => {
    dispatch(getPageChange(pageNumber - 1));
  };

  const nextClickHandler = async () => {
    try {
      await form.validateFields([GLOBAL_ADDITIONAL_ATTRIBUTES.STORE_TYPE]);
      if (storeTypeFormValue) {
        dispatch(getPageChange(pageNumber + 1));
      } else {
        notificationController.error({
          message: "Please select store type before proceeding further",
        });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const handleRadioClick = (value: string) => {
    //set the value for radio option
    form.setFields([
      //set the value for radio option
      {
        name: GLOBAL_ADDITIONAL_ATTRIBUTES.STORE_TYPE,
        value: value,
        errors: [],
      },
    ]);
  };

  const renderCard = (item: IStoreTypeCard) => {
    const isActive = storeTypeFormValue == item.value;

    return (
      <Col span={24} key={item.value}>
        <CustomRadioButton
          onClickHandler={handleRadioClick}
          isSelected={isActive}
          title={item.title}
          subTitle={item.subTitle}
          image={item.image}
          value={item.value}
        />
      </Col>
    );
  };

  return (
    <>
      <div className="store-type-onboarding">
        <Title level={3} className="my-1" data-test-selector="hdgWhichStoreType">
          Which type of store would you like to set up?
        </Title>
        <Form.Item name={GLOBAL_ADDITIONAL_ATTRIBUTES.STORE_TYPE} className="mt-32">
          <div className="margin-bottom my-1 radio-option-wrapper">
            <Row gutter={[24, 24]} className="mb-20">
              {STORE_TYPE_OPTIONS.map((item: IStoreTypeCard) => {
                return renderCard(item);
              })}
            </Row>
          </div>
        </Form.Item>
        {/* <StepsComponent /> */}
        <Row className="my-1" style={{ justifyContent: "end" }}>
          <div className="back-btn">
            <Button onClick={handleBack} data-test-selector="btnCancel">
              Back
            </Button>
          </div>
          <div className="next-btn">
            <Form.Item>
              <Button
                onClick={nextClickHandler}
                className="gray-bg-button"
                data-test-selector="btnNext"
              >
                Next
              </Button>
            </Form.Item>
          </div>
        </Row>
      </div>
    </>
  );
};

export default StoreTypeSelection;
