import { IValidateUser } from "interfaces/ClientInterface/IResetPassword";
import { httpApi } from "../../Http.api";
import { IResetPasswordBulk } from "interfaces/ClientInterface/IResetPasswordBulk";
import { ILoginForm } from "interfaces/ClientInterface/SpokeCustumApiInterface/ILogin";
import { ILoginAPIPayload } from "interfaces/ApiInterface/SpokeCustumApiInterface/ILogin";

export const loginUser = (userData: ILoginForm, PortalId: number) => {
  const data: ILoginAPIPayload = {
    user: {
      password: userData?.password,
      username: userData?.username,
    },
    userName: userData?.username,
    portalId: PortalId,
  };

  return httpApi
    .post("/users/login", data)
    .then((response) => response.data)
    .catch((error) => {
      // Handle the error appropriately
      // eslint-disable-next-line no-console
      console.error("Login API Error:", error);
      throw error;
    });
};

export const logoutUser = (PortalId: number) => {
  return httpApi
    .post(`/SpokeCustomBStore/SpokeCustomLogout?portalId=${PortalId}`)
    .then((response) => response.data)
    .catch((error) => {
      // Handle the error appropriately
      // eslint-disable-next-line no-console
      console.error("Login API Error:", error);
      throw error;
    });
};

export const validateExistingUser = (body: IValidateUser) => {
  return httpApi
    .post("/users/getbyusername", body)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const resetPassword = (body: any) => {
  return httpApi
    .post("users/forgotpassword", body)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

// for resetting bulk passwords
export const resetBulkPassword = (body: IResetPasswordBulk) => {
  return httpApi
    .post("/users/BulkResetPassword", body)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
