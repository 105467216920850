import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StoreState } from "interfaces/ClientInterface/ISlices";
import { Store } from "interfaces/ApiInterface/IStore";

const initialState: StoreState = {
  storeName: "",
  storeList: [],
  IsBStoreOwner: false,
  IsBStoreManager: false,
  bStorePortalId: 0,
  pageNumber: 1,
  error: "",
  duplicateStoreData: {
    PortalId: 0,
    StoreName: "",
    DomainURL: "",
    IsApproved: false,
    IsActive: false,
    RoleName: "",
    OpenDate: "",
    CloseDate: "",
    CreatedBy: 0,
    CreatedDate: "",
    ModifiedBy: 0,
    ModifiedDate: "",
    ActionMode: "",
    Custom1: "",
    Custom2: "",
    Custom3: "",
    Custom4: "",
    Custom5: "",
  },
  createStore: {
    PortalId: 0,
    StoreName: "",
    StoreCode: "",
    UserId: 0,
    ParentPortalId: 0,
    PublishCatalogId: 0,
    DomainUrl: "",
    CompanyName: "",
    UserVerificationType: "",
    WebsiteDescription: "",
    FaviconId: 0,
    MediaId: "",
    Color1: "",
    Color2: "",
    Color3: "",
    Color4: "",
    IsBStoreUsersSelfRegister: false,
    IsBStoreLoginRequired: false,
    IsBStoreShowPricing: false,
    CreatedBy: 0,
    CreatedDate: "",
    ModifiedBy: 0,
    ModifiedDate: "",
    ActionMode: "",
    Custom1: "",
    Custom2: "",
    Custom3: "",
    Custom4: "",
    Custom5: "",
    ImageNotAvailablePath: "",
    BStoreDomainName: "",
    IsManageBstoreFromWebstore: true,
    IsBStoresAutoPublished: false,
  },
  stateStore: {
    PortalId: 0,
    ParentPortalId: 0,
    StoreCode: "",
    PublishCatalogId: 0,
    LocaleId: 0,
    CMSThemeId: 0,
    CMSParentThemeId: 0,
    CompanyName: "",
    ThemeName: "",
    ParentThemeName: "",
    ProductReviewStatus: "",
    StoreName: "",
    DomainUrl: "",
    RoleName: "",
    CustomerServiceEmail: "",
    CustomerServicePhoneNumber: "",
    ImageNotAvailablePath: "",
    MediaServerUrl: "",
    MediaServerThumbnailUrl: "",
    CMSThemeCSSId: 0,
    CatalogName: "",
    PublishStatus: "",
    CSSName: "",
    StoreLogo: "",
    PublishState: 0,
    ParentStoreName: "",
    IsActive: false,
    IsBStore: false,
    IsApproved: false,
    UserVerificationType: "",
    IsBStoreUsersSelfRegister: false,
    IsBStoreLoginRequired: false,
    IsBStoreShowPricing: false,
    IsDirectory: false,
    IsEnableSSL: false,
    SubDomainUrl: "",
    IsDefault: false,
    IsSpecific: false,
    ExternalId: "",
    PriceListId: 0,
    OpenDate: "",
    CloseDate: "",
    IsCatalogCustomized: false,
    CreatedBy: 0,
    CreatedDate: "",
    ModifiedBy: 0,
    ModifiedDate: "",
    ActionMode: "",
    Custom1: "",
    Custom2: "",
    Custom3: "",
    Custom4: "",
    Custom5: "",
  },
  createStoreResponse: {
    ParentPortalId: 0,
    StoreCode: "",
    PublishCatalogId: 0,
    LocaleId: 0,
    CMSThemeId: 0,
    CMSParentThemeId: 0,
    CompanyName: "",
    ThemeName: "",
    ParentThemeName: "",
    ProductReviewStatus: "",
    CustomerServiceEmail: "",
    CustomerServicePhoneNumber: "",
    ImageNotAvailablePath: "",
    MediaServerUrl: "",
    MediaServerThumbnailUrl: "",
    CMSThemeCSSId: 0,
    CatalogName: "",
    PublishStatus: "",
    CSSName: "",
    StoreLogo: "",
    PublishState: 0,
    ParentStoreName: "",
    IsBStore: false,
    UserVerificationType: "",
    IsBStoreUsersSelfRegister: false,
    IsBStoreLoginRequired: false,
    IsBStoreShowPricing: false,
    IsDirectory: false,
    IsEnableSSL: false,
    SubDomainUrl: "",
    IsDefault: false,
    IsSpecific: false,
    ExternalId: "",
    PriceListId: 0,
    IsCatalogCustomized: false,
  },
  copyStoreData: {
    PortalId: 0,
    ParentPortalId: 0,
    StoreCode: "",
    PublishCatalogId: 0,
    LocaleId: 0,
    PimCategoryHierarchyId: 0,
    PimCatalogId: 0,
    CMSThemeId: 0,
    CMSParentThemeId: 0,
    CompanyName: "",
    ThemeName: "",
    ParentThemeName: "",
    StoreName: "",
    DomainUrl: "",
    CustomerServiceEmail: "",
    CustomerServicePhoneNumber: "",
    ImageNotAvailablePath: "",
    MediaServerUrl: "",
    MediaServerThumbnailUrl: "",
    CMSThemeCSSId: 0,
    CatalogName: "",
    PublishStatus: "",
    CSSName: "",
    StoreLogo: "",
    PublishState: "",
    ParentStoreName: "",
    IsActive: false,
    IsBStore: false,
    IsApproved: false,
    UserVerificationType: "",
    IsBStoreUsersSelfRegister: false,
    IsBStoreLoginRequired: false,
    IsBStoreShowPricing: false,
    IsDirectory: false,
    IsEnableSSL: false,
    IsDefault: false,
    IsSpecific: false,
    ExternalId: "",
    PriceListId: "",
    OpenDate: "",
    CloseDate: "",
    BStoreCatalogType: "",
    IsCatalogCustomized: false,
    DomainId: 0,
    BStoreDomainName: "",
    UserId: 0,
    isBStoreAutoPublished: true,
    IsManageBStoreFromWebstore: true,
  },
};

export const storeSlice = createSlice({
  name: "store",
  initialState,
  reducers: {
    getStoreData: (
      state,
      action: PayloadAction<{ store: Store[]; isOwner: boolean; isManager: boolean }>
    ) => {
      state.storeList = action.payload.store;
      state.IsBStoreOwner = action.payload.isOwner;
      state.IsBStoreManager = action.payload.isManager;
    },
    getPageChange: (state, action: PayloadAction<number>) => {
      state.pageNumber = action.payload;
    },
    getStorePortalID: (state, action: PayloadAction<number>) => {
      state.bStorePortalId = action.payload;
      localStorage.setItem("bStorePortalId", JSON.stringify(action.payload));
    },
    getSingleStoreData: (state, action: PayloadAction<Store>) => {
      state.duplicateStoreData = action.payload;
    },
    getStoreName: (state, action: PayloadAction<string>) => {
      state.storeName = action.payload;
      localStorage.setItem("storeName", JSON.stringify(action.payload));
    },
    getStoreList: (state, action: PayloadAction<Store[]>) => {
      state.storeList = action.payload;
    },
    goToNextpage: (state, action: PayloadAction<Store[]>) => {
      state.storeList = action.payload;
    },
  },
});

export const {
  getStoreData,
  getPageChange,
  getStorePortalID,
  getSingleStoreData,
  getStoreName,
  getStoreList,
} = storeSlice.actions;
export default storeSlice.reducer;
