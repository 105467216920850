import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NavState } from "interfaces/ClientInterface/ISlices";

const initialState: NavState = {
  navPage: "",
};

export const navSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    getNavigatedPage: (state, action: PayloadAction<string>) => {
      state.navPage = action.payload;
    },
  },
});

export const { getNavigatedPage } = navSlice.actions;
export default navSlice.reducer;
