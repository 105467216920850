/* eslint-disable no-unused-vars */
import React from "react";
import { Space } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import "./SelectionRadio.scss";

interface ISelectionRadio {
  title: string;
  isSelected: boolean;
  onClickHandler: (value: string) => void;
  isDelete?: boolean;
  deleteClickHandler?: (value: string) => void;
  uniqueKey: string;
}

const SelectionRadio: React.FC<ISelectionRadio> = ({
  title,
  isSelected,
  onClickHandler,
  isDelete,
  deleteClickHandler,
  uniqueKey,
}) => {
  return (
    <div
      className="custom-radio-container"
      data-test-selector={`chkCustomRadio${title}`}
      onClick={() => onClickHandler(title)}
      key={uniqueKey}
    >
      <div
        className={`d-flex justify-between align-center ${
          isSelected ? "active-card" : "in-active-card"
        }`}
      >
        <Space direction="horizontal" className="content-part-container">
          <Space direction="vertical" size={5}>
            <div className="title" data-test-selector="hdgTitle">
              {title}
            </div>
          </Space>
        </Space>
        <Space>
          {isDelete && (
            <div
              className="delete-button"
              onClick={(e) => {
                e.stopPropagation();
                deleteClickHandler && deleteClickHandler(title);
              }}
            >
              Delete
            </div>
          )}
          <div className="action-btn-container">
            {isSelected ? (
              <CheckCircleFilled className="checked" />
            ) : (
              <div className="blank-circle" />
            )}
          </div>
        </Space>
      </div>
    </div>
  );
};

export default SelectionRadio;
