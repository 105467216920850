import React from "react";
import { Layout, Typography, Image } from "antd";
import Link from "antd/es/typography/Link";
// import LOGO_BLACK from "../../../assets/Images/logo-black.png";
import FOOTER_LOGO from "../../../assets/Images/Logo.png";
const { Footer } = Layout;
const { Text } = Typography;
import "./Footer.scss";

const FooterComponent: React.FC = () => {
  return (
    <>
      <div className="footer-container">
        <div className="border-top"></div>
        <Footer>
          <Text className="font-size-12">
            ©️ 2024 Polyconcept North America. All rights reserved.
          </Text>
          <div className="footer-img">
            <Image src={FOOTER_LOGO} width={100} preview={false} alt="Givee Footer Logo"/>
          </div>
          <div className="footer-link">
            <Link
              href="https://www.pcna.com/en-us/privacy-policy"
              target="_blank"
              className="font-size-12"
            >
              Privacy Policy
            </Link>
            <Link
              href="https://www.pcna.com/en-us/givee-terms-and-conditions"
              target="_blank"
              className="font-size-12"
            >
              Terms & Conditions
            </Link>
            <Link href="https://www.getgivee.com/contact" target="_blank" className="font-size-12">
              Contact
            </Link>
          </div>
        </Footer>
      </div>
    </>
  );
};
export default FooterComponent;
