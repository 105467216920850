import React from "react";
import { CheckCircleFilled } from "@ant-design/icons";
import {
  IStoresStripeList,
  IStripeValueInForm,
} from "interfaces/ClientInterface/SpokeCustumApiInterface/IAboutStore";

interface ICustomRadioButton {
  uniqueID: string;
  storeName: string;
  status: React.ReactNode;
  isSelected: boolean;
  customActionIcon?: React.ReactNode;
  // eslint-disable-next-line no-unused-vars
  onClickHandler: (value: IStripeValueInForm) => void;
  value: IStoresStripeList;
}

const StripeCustomRadioButton: React.FC<ICustomRadioButton> = ({
  uniqueID,
  storeName,
  status,
  isSelected,
  customActionIcon = null,
  onClickHandler,
  value,
}) => {
  const selectedValue = {
    storePortalId: value?.storePortalId,
    storeStripeid: value?.stripeId,
  };

  return (
    <div
      className="custom-radio-container"
      onClick={() => onClickHandler(selectedValue)}
      data-test-selector={`chkCustomRadio${selectedValue?.storePortalId}`}
    >
      <div
        className={`d-flex justify-between align-center 
          ${isSelected ? "active-card" : "in-active-card"}`}
      >
        <div className="content-part">
          <div className="stripe-details-container">
            <div className="title">{storeName}</div>
            <div className="unique-id" data-test-selector="hdgTitle">
              {uniqueID}
            </div>
            <div className="sub-title" data-test-selector="hdgSubTitle">
              {status}
            </div>
          </div>
        </div>
        {customActionIcon ? (
          customActionIcon
        ) : (
          <div className="action-btn">
            {isSelected ? (
              <CheckCircleFilled className="checked" />
            ) : (
              <div className="blank-circle" />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default StripeCustomRadioButton;
