/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
import React from "react";
import { Typography, Form, Row, Button, Col, InputNumber } from "antd";
// import StepsComponent from "components/Common/Steps/Steps";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { IFormInstanceProps } from "interfaces/ClientInterface/SpokeCustumApiInterface/IAboutStore";
import { getPageChange } from "store/Slices/store.slice";
import {
  GIFTING_METHOD_OPTIONS,
  GIFTING_STORE_LIMITS,
} from "components/SpokeCustomComponents/StoreAdmin/StoreAdmin.Constant";
import { IStoreTypeCard } from "interfaces/ClientInterface/SpokeCustumApiInterface/IStoreAdmin";
import CustomRadioButton from "components/SpokeCustomComponents/Common/CustomRadioButton/CustomRadioButton";
import { GLOBAL_ADDITIONAL_ATTRIBUTES } from "config/SpokeCustom.Constant";
import { notificationController } from "components/Common/Notification/Notification";

import "../StoreType/StoreTypeSelection.scss";
const { Title } = Typography;

const GiftingStoreOptions: React.FC<IFormInstanceProps> = ({ form }) => {
  const dispatch = useAppDispatch();
  const pageNumber = useAppSelector((state) => state.store.pageNumber);
  const giftLimitSelection = Form.useWatch(GLOBAL_ADDITIONAL_ATTRIBUTES.B_STORE_GIFTING_OPTIONS, {
    form,
    preserve: true,
  });

  const handleBack = () => {
    dispatch(getPageChange(pageNumber - 1));
  };

  const nextClickHandler = async () => {
    try {
      if (giftLimitSelection) {
        if (giftLimitSelection == GIFTING_STORE_LIMITS.QUANTITY_BASED) {
          await form.validateFields([GLOBAL_ADDITIONAL_ATTRIBUTES.B_STORE_ITEMS_LIMIT_IN_CART]);
          dispatch(getPageChange(pageNumber + 1));
        } else {
          await form.validateFields([GLOBAL_ADDITIONAL_ATTRIBUTES.B_STORE_MONETORY_VALUE]);
          dispatch(getPageChange(pageNumber + 1));
        }
      } else {
        notificationController.error({
          message: "Please select any option before proceeding further",
        });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const renderCard = (item: IStoreTypeCard) => {
    const isActive = giftLimitSelection == item.value;

    return (
      <Col span={24} key={item.value}>
        <CustomRadioButton
          onClickHandler={(value: string) => {
            form.setFields([
              {
                name: GLOBAL_ADDITIONAL_ATTRIBUTES.B_STORE_GIFTING_OPTIONS,
                value: value,
                errors: [],
              },
            ]);
          }}
          isSelected={isActive}
          title={item.title}
          subTitle={item.subTitle}
          image={item.image}
          value={item.value}
        />
      </Col>
    );
  };

  return (
    <>
      <div className="store-type-onboarding">
        <Title level={3} className="my-1" data-test-selector="hdgWhichStoreType">
          How would you like to distribute gifts?
        </Title>
        <Form.Item
          name={GLOBAL_ADDITIONAL_ATTRIBUTES.B_STORE_GIFTING_OPTIONS}
          className="mb-0 mt-32"
        >
          <Row gutter={[24, 24]} className="mb-20 store-type-container">
            {GIFTING_METHOD_OPTIONS.map((item: IStoreTypeCard) => {
              return renderCard(item);
            })}
          </Row>
        </Form.Item>
        {giftLimitSelection === GIFTING_STORE_LIMITS.QUANTITY_BASED && (
          <div className="margin-bottom">
            <div className="font-input-label">Number of items per guest</div>
            <Form.Item
              label={""}
              name={GLOBAL_ADDITIONAL_ATTRIBUTES.B_STORE_ITEMS_LIMIT_IN_CART}
              rules={[
                { required: true, message: "Please input the number of item limit per guest" },
                {
                  pattern: new RegExp(/^\d+$/),
                  message: "Please select a round number for your item limit.",
                },
              ]}
            >
              <InputNumber
                className="w-100"
                min={"1"}
                type="number"
                data-test-selector="txtGiftQty"
                // precision={1}
                onWheel={(e) => e.currentTarget.blur()}
              />
            </Form.Item>
          </div>
        )}
        {giftLimitSelection === GIFTING_STORE_LIMITS.MONETARY_BASED && (
          <div className="margin-bottom">
            <div className="font-input-label">Store credits per guest</div>
            <Form.Item
              label={""}
              name={GLOBAL_ADDITIONAL_ATTRIBUTES.B_STORE_MONETORY_VALUE}
              rules={[
                {
                  required: true,
                  message: "Please input the number of store credits per guest",
                },
                {
                  pattern: new RegExp(/^\d+$/),
                  message: "Please select a round number for your Store Credit.",
                },
              ]}
              className="mb-0"
            >
              <InputNumber
                className="w-100"
                prefix={"$"}
                type="number"
                data-test-selector="txtGiftValue"
                // precision={2}
                onWheel={(e) => e.currentTarget.blur()}
              />
            </Form.Item>
            <Title level={5} className="input-bottom-info mt-8">
              1 credit = $1 USD
            </Title>
          </div>
        )}
        {/* <StepsComponent /> */}
        <Row className="my-1" style={{ justifyContent: "end" }}>
          <div className="back-btn">
            <Button onClick={handleBack} data-test-selector="btnCancel">
              Back
            </Button>
          </div>
          <div className="next-btn">
            <Form.Item>
              <Button
                onClick={nextClickHandler}
                className="gray-bg-button"
                data-test-selector="btnNext"
              >
                Next
              </Button>
            </Form.Item>
          </div>
        </Row>
      </div>
    </>
  );
};

export default GiftingStoreOptions;
