import React, { Suspense } from "react";
import { LoadingOutlined } from "@ant-design/icons";
// eslint-disable-next-line no-unused-vars
type ReturnType<T> = (props: T) => JSX.Element;

export const withLoading = <T extends object>(Component: React.ComponentType<T>): ReturnType<T> => {
  return (props: T) => (
    <Suspense
      fallback={
        <div className="d-flex justify-center align-center suspense-loader-wrapper">
          <LoadingOutlined className="loader" />
        </div>
      }
    >
      <Component {...props} />
    </Suspense>
  );
};
