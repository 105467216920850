import React from "react";
import { Pagination } from "antd";
import type { PaginationProps } from "antd";
import "./Pager.scss";

const Pager = (props: PaginationProps) => {
  return <Pagination {...props} />;
};

export default Pager;
