import React from "react";
import { Typography, Row, Button, Col, Modal } from "antd";
import { ExportOutlined } from "@ant-design/icons";
import "./ExistingStripeAccount.scss";

const { Title } = Typography;

interface IStripeModal {
  loading: boolean;
  createNew: () => void;
  openModal: boolean;
  handleCancel?: () => void;
  errorOccured: boolean;
}

const StripeModal: React.FC<IStripeModal> = (props) => {
  const { loading, createNew, openModal, handleCancel, errorOccured } = props;

  return (
    <>
      <Modal
        open={openModal}
        width={700}
        onCancel={() => {
          handleCancel && handleCancel();
        }}
        closable={errorOccured}
        destroyOnClose
        data-test-selector="modalCopyStripe"
        footer={null}
        centered
        maskClosable={errorOccured}
      >
        <div className="stripe-redirect-modal">
          <Row gutter={[16, 16]} className="d-flex justify-center align-center">
            <Col span={24} className="mt-16">
              <Title level={3} className="m-0">
                {errorOccured
                  ? "There was an issue directing you to Stripe"
                  : "You are about to be directed to Stripe"}
              </Title>
            </Col>
            <Col span={24} className="mb-16">
              <span className="sub-text">
                {errorOccured ? (
                  <span>
                    Please try again. If the issue continues contact our support team at{" "}
                    <Button
                      type="link"
                      onClick={(e) => {
                        window.open("mailto:support@givee.com", "_self");
                        e.preventDefault();
                      }}
                    >
                      support@givee.com
                    </Button>
                  </span>
                ) : (
                  "This may take a few seconds."
                )}
              </span>
            </Col>
          </Row>
          {errorOccured && (
            <div className="bottom-action-container">
              <Button
                className="back-btn"
                type="default"
                onClick={() => {
                  handleCancel && handleCancel();
                }}
                data-test-selector="btnCancel"
              >
                Cancel
              </Button>
              <div className="btn-submit">
                <Button
                  block
                  size="middle"
                  type="primary"
                  onClick={createNew}
                  data-test-selector="btnCreateStripe"
                  icon={<ExportOutlined />}
                  iconPosition="end"
                  loading={loading}
                >
                  Create a new Stripe Connect account
                </Button>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default StripeModal;
