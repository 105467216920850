import { httpApi } from "api/Http.api";
import { AxiosError } from "axios";
import { ICreateStripeAccount } from "interfaces/ApiInterface/SpokeCustumApiInterface/IStoreAdmin";

// Get Store Details according to Portal Number
export const generateStripeAccount = (payload: ICreateStripeAccount) => {
  return httpApi
    .post("stripeaccount/stripecrateaccount", payload)
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

export const updateStripeDetails = (
  accountId: string,
  portalId: string,
  isDisconnectStripe?: boolean
) => {
  return httpApi
    .post(
      `stripeaccount/savestripeaccountid/${accountId}/${portalId}/${isDisconnectStripe || false}`
    )
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

export const getStripeDetails = (portalId: number) => {
  return httpApi
    .get(`stripeaccount/GetStripeAccountDetails/${portalId}`)
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};

export const getStoresWithStripeDetails = (userId: number, rows: number, pageNo: number) => {
  return httpApi
    .get(`spokecustombstore/spokecustomgetbstorestripaccount/${userId}/${rows}/${pageNo} `)
    .then((res) => res)
    .catch((err: AxiosError) => {
      throw err;
    });
};
