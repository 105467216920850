import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UploadState {
  // eslint-disable-next-line
  productDetailsData: any[];
}

const storedProductData = localStorage.getItem("productDetailsData");
const storedArtifiData = localStorage.getItem("artifiDetailsData");
const initialState: UploadState = {
  productDetailsData:
    storedProductData && storedArtifiData
      ? [JSON.parse(storedProductData), JSON.parse(storedArtifiData)]
      : [],
};

const productDetailsSlice = createSlice({
  name: "productDetails",
  initialState,
  reducers: {
    // eslint-disable-next-line
    addProductDetails: (state, action: PayloadAction<any>) => {
      state.productDetailsData[0] = action.payload;
      localStorage.setItem("productDetailsData", JSON.stringify(action.payload));
    },
    // eslint-disable-next-line
    addArtifiDetails: (state, action: PayloadAction<any>) => {
      state.productDetailsData[1] = action.payload;
      localStorage.setItem("artifiDetailsData", JSON.stringify(action.payload));
    },
    clearProductDetails: (state) => {
      state.productDetailsData = [];
    },
  },
});

export const { addProductDetails, addArtifiDetails, clearProductDetails } =
  productDetailsSlice.actions;
export default productDetailsSlice.reducer;
