/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { SCRIPTS } from "config/SpokeCustom.Constant";
import React, { useEffect } from "react";
import { removeScript } from "utils";

declare global {
  interface Window {
    embedded_svc: any;
  }
}

interface ISalesForceChatBot {
  hideWidget: boolean;
  buttonId: string;
  deploymentId: string;
}

const SalesForceChatBot: React.FC<ISalesForceChatBot> = ({
  buttonId,
  deploymentId,
  hideWidget,
}) => {
  useEffect(() => {
    const initESW = (gslbBaseURL: string | null) => {
      window.embedded_svc.settings.displayHelpButton = hideWidget; // Or false
      window.embedded_svc.settings.language = ""; // For example, enter 'en' or 'en-US'

      window.embedded_svc.settings.enabledFeatures = ["LiveAgent"];
      window.embedded_svc.settings.entryFeature = "LiveAgent";

      window.embedded_svc.init(
        "https://pcna.my.salesforce.com",
        "https://pcna.my.site.com/lilybot",
        gslbBaseURL,
        "00D30000001IBzw",
        "Givee_Chat_Queue",
        {
          baseLiveAgentContentURL:
            "https://c.la3-core2.sfdc-lywfpd.salesforceliveagent.com/content",
          deploymentId: deploymentId?.toString(),
          buttonId: buttonId?.toString(),
          baseLiveAgentURL: "https://d.la3-core2.sfdc-lywfpd.salesforceliveagent.com/chat",
          eswLiveAgentDevName: "Givee_Chat_Queue",
          isOfflineSupportEnabled: true,
        }
      );
    };

    if (!window.embedded_svc) {
      //ESW
      const scriptElement: any = document.createElement("script");
      scriptElement.setAttribute(
        "src",
        "https://pcna.my.salesforce.com/embeddedservice/5.0/esw.min.js"
      );
      scriptElement.onload = function () {
        initESW(null);
      };
      document.body.appendChild(scriptElement);
    } else {
      initESW("https://service.force.com");
    }

    return () => {
      removeScript(SCRIPTS.SALESFORCE_CHAT_BOT_SCRIPTS);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="chat-bot-wrapper">
      <style>
        {`
          .helpButton {
            left: 220px !important;
          }
          .embeddedServiceSidebar.layout-docked .dockableContainer {
            left: 170px !important;
          }
          .embeddedServiceHelpButton .helpButton .uiButton {
            font-family: "Arial", sans-serif;
          }
        `}
      </style>
    </div>
  );
};

export default SalesForceChatBot;
