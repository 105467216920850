import React from "react";
import { Typography, Input, Form, Row, Button } from "antd";
// import StepsComponent from "components/Common/Steps/Steps";
import { readToken } from "api/Services/localStorage.service";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
// eslint-disable-next-line max-len
import { ICompanyInformation } from "interfaces/ClientInterface/SpokeCustumApiInterface/IAboutStore";
import { getPageChange } from "store/Slices/store.slice";
import { useNavigate } from "react-router-dom";
import "./CompanyInformation.scss";

const { Title } = Typography;

const CompanyInformation: React.FC<ICompanyInformation> = ({ form }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isUserLoggedIn = readToken() ? true : false;
  const pageNumber = useAppSelector((state) => state.store.pageNumber);
  const isPcnaAccountRequested = Form.useWatch("PcnaAccountRequested", {
    form,
    preserve: true,
  });
  const PcnaAccountSkipped = Form.useWatch("PcnaAccountSkipped", {
    form,
    preserve: true,
  });

  const handleBack = () => {
    if (isUserLoggedIn) {
      navigate("/dashboard/stores");
      form.resetFields();
    } else {
      if (PcnaAccountSkipped){
        dispatch(getPageChange(pageNumber - 2));
      } else {
        dispatch(getPageChange(pageNumber - 1));
      }
    }
  };

  const nextClickHandler = async () => {
    try {
      await form.validateFields(["CompanyName"]);
      dispatch(getPageChange(pageNumber + 1));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  return (
    <>
      <div className="your-store">
        <Title level={3} className="my-1" data-test-selector="hdgWhoStoreFor">
          What company are you creating this store for?
        </Title>
        <div className="margin-bottom mt-32">
          <div className="font-style">Company name</div>
          <Form.Item
            name="CompanyName"
            rules={[
              { required: true, message: "Company name is required", whitespace: true },
              {
                max: 100,
                message: "Input must not exceed the maximum character limit of 100",
              },
            ]}
          >
            <Input data-test-selector="txtCompanyName" />
          </Form.Item>
        </div>
        {/* <StepsComponent /> */}
        <Row className="my-1" style={{ justifyContent: "end" }}>
          <div className="back-btn">
            <Button
              onClick={handleBack}
              data-test-selector="btnCancel"
              disabled={!isUserLoggedIn && isPcnaAccountRequested}
            >
              Back
            </Button>
          </div>
          <div className="next-btn">
            <Form.Item>
              <Button
                onClick={nextClickHandler}
                className="gray-bg-button"
                data-test-selector="btnNext"
              >
                Next
              </Button>
            </Form.Item>
          </div>
        </Row>
      </div>
    </>
  );
};

export default CompanyInformation;
