/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */

import React, { useEffect, useState } from "react";
import { Typography, Space, Button, Row, Form, Input } from "antd";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { getPageChange } from "store/Slices/store.slice";
// import StepsComponent from "components/Common/Steps/Steps";
import { checkDomainUrl } from "api/Services/storesetting.api";
import { setEnteredDomainNames, setStoreName } from "store/Slices/catalogUse.slice";
import { storeNameToDomainSlug } from "utils";
import { LoadingComponent } from "components/SpokeCustomComponents/LoadingComponent/LoadingComponent";
import { IStoreNameProps } from "interfaces/ApiInterface/SpokeCustumApiInterface/IStores";
import "./StoreDetails.scss";

const { Title } = Typography;

const StoreName: React.FC<IStoreNameProps> = (props) => {
  const { form, domainNameFromProps, setStoreUrlList } = props;

  const dispatch = useAppDispatch();
  const pageNumber = useAppSelector((state) => state.store.pageNumber);
  const [loading, setLoading] = useState<boolean>(true);
  const [storeNameList, setStoreNameList] = useState<string[]>();

  useEffect(() => {
    if (domainNameFromProps) {
      setLoading(true);
      checkDomainUrl()
        .then((res) => {
          const data: string[] = [];
          const storeUrlData: string[] = [];
          res.data.Domains?.map((item) => {
            let sortedDomain = item.DomainName.includes("https")
              ? item.DomainName.slice(8)
              : item.DomainName.slice(7);

            storeUrlData.push(sortedDomain);

            if (domainNameFromProps) {
              sortedDomain = sortedDomain.replace(domainNameFromProps, "");
            }

            data.push(sortedDomain);
          });
          setStoreNameList(data);
          //   setStoreList(data);
          setStoreUrlList(storeUrlData);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainNameFromProps]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validateStoreName = (_: any, value: string, callback: any) => {
    const domainName = storeNameToDomainSlug(value);
    if (storeNameList?.includes(domainName)) {
      callback("The store name is already taken. Please add another name.");
    } else {
      callback();
    }
  };

  const handleStoreNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const nameWithDashes = storeNameToDomainSlug(inputValue);

    dispatch(setStoreName(inputValue));
    //   setEnteredDomainName(nameWithDashes);
    dispatch(setEnteredDomainNames(nameWithDashes));
    form.setFields([
      {
        name: "StoreName",
        value: inputValue,
      },
      {
        name: "BStoreDomainName",
        value: nameWithDashes,
        errors: [],
      },
    ]);
    //   setEnteredDomainName(nameWithDashes);
  };

  const handleNextClick = async () => {
    try {
      await form.validateFields(["StoreName"]);
      dispatch(getPageChange(pageNumber + 1));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const handleBackClick = () => {
    dispatch(getPageChange(pageNumber - 1));
  };

  return (
    <>
      {loading ? (
        <Space className="d-flex justify-center align-center h-300">
          <LoadingComponent />
        </Space>
      ) : (
        <div className="store-create-page">
          <Title level={3}>What do you want to name the store?</Title>
          <Space direction="vertical" className="w-100 mb-16 mt-32">
            <div className="font-style mbottom-5">Store name</div>
            <Form.Item
              className="input-box m-0"
              name="StoreName"
              rules={[
                // { required: true, message: "Store Name is required" },
                {
                  validator: (rule, value, callback) => {
                    // Check if the input has a value before calling validateStoreName
                    if (!value) {
                      callback("Store Name is required");
                    } else if (value.trim() === "") {
                      callback("Store Name is required");
                    } else {
                      validateStoreName(rule, value, callback);
                    }
                  },
                },
              ]}
            >
              <Input
                maxLength={35}
                placeholder="E.g. Acme Corporation swag"
                onChange={handleStoreNameChange}
                showCount
                data-test-selector="txtStoreName"
              />
            </Form.Item>
            {/* <Title level={5} className="info-text">
              This is the name of the event or store. You may edit the Store name in the Store admin
              if changes are needed.
            </Title> */}
          </Space>
          {/* <StepsComponent /> */}
          <Row className="my-1" style={{ justifyContent: "end", paddingTop: "20px" }}>
            <Button
              onClick={handleBackClick}
              style={{ marginRight: "10px" }}
              className="back-step-btn"
              data-test-selector="btnBack"
            >
              Back
            </Button>
            <Form.Item>
              <span className="btn-container">
                <Button
                  className="gray-bg-button"
                  onClick={handleNextClick}
                  data-test-selector="btnNext"
                >
                  Next
                </Button>
              </span>
            </Form.Item>
          </Row>
        </div>
      )}
    </>
  );
};

export default StoreName;
