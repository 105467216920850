/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from "react";
import { Typography, Form, Row, Button, Col, Space } from "antd";
// import StepsComponent from "components/Common/Steps/Steps";
import { readToken } from "api/Services/localStorage.service";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
// eslint-disable-next-line max-len
import {
  IAlreadyExistCompanyProps,
  ICompanyList,
  INewCompanyPageData,
} from "interfaces/ClientInterface/SpokeCustumApiInterface/IAboutStore";
import {
  numberWithCommas,
} from "utils";
import { getPageChange } from "store/Slices/store.slice";
import { useNavigate } from "react-router-dom";
import SelectionRadio from "../../../../SpokeCustomComponents/Common/SelectionRadio/SelectionRadio";
import Pager from "components/SpokeCustomComponents/SharedComponents/Pager/Pager";
import AddNewCompanyModal from "../AddCompanyModal/AddCompanyModal";
// eslint-disable-next-line max-len
import { LoadingComponent } from "components/SpokeCustomComponents/LoadingComponent/LoadingComponent";
import { notificationController } from "components/Common/Notification/Notification";
const { Title } = Typography;

const AlreadyExistCompany: React.FC<IAlreadyExistCompanyProps> = ({
  form,
  selectedCompanyProps,
  pageProps,
  companyListProps,
  loading,
}) => {
  const { selectedCompany, setSelectedCompany, setPreserveNewlyAddedCompany } =
    selectedCompanyProps;
  const { newCompanyPageData, setNewCompanyPageData } = pageProps;
  const { companyList, setCompanyList } = companyListProps;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isUserLoggedIn = readToken() ? true : false;
  const userInfo = useAppSelector((state) => state.auth.userInfo);
  const PortalId = userInfo?.PortalId ? userInfo?.PortalId : 0;
  const pageNumber = useAppSelector((state) => state.store.pageNumber);
  const [addNewCompanyModalVisible, setAddNewCompanyModalVisible] = useState<boolean>(false);

  /**
   * if portal id is not present in local storage,
   * then redirect the user to landing page
   */
  useEffect(() => {
    if (PortalId == 0) {
      const redirectToLandingPageURL = process.env.REACT_APP_REDIRECT_URL;
      window.location.href = redirectToLandingPageURL || "";
    }
  }, [PortalId]);

  const handleBack = () => {
    if (isUserLoggedIn) {
      navigate("/dashboard/stores");
      form.resetFields();
    } else {
      dispatch(getPageChange(pageNumber - 1));
    }
  };

  const nextClickHandler = async () => {
    if (selectedCompany != "") {
      form.setFields([
        {
          name: "CompanyName",
          value: selectedCompany,
          errors: [],
        },
      ]);
      dispatch(getPageChange(pageNumber + 1));
    } else {
      notificationController.error({
        message: "Please select company before proceeding further",
      });
    }
  };

  const renderCard = (item: ICompanyList, index: number) => {
    const isActive = selectedCompany == item.CompanyName;

    return (
      <Col span={24} key={item.CompanyName}>
        <SelectionRadio
          onClickHandler={(value: string) => {
            setSelectedCompany(value);
          }}
          isSelected={isActive}
          title={item.CompanyName}
          isDelete={item.isNewlyAdded}
          deleteClickHandler={() => {
            onDeleteCompany(index);
          }}
          uniqueKey={`company-list-${item.CompanyName}-${index}`}
        />
      </Col>
    );
  };

  const onAddCompanyName = (companyName: string) => {
    const existingCompanies = JSON.parse(JSON.stringify(companyList));
    existingCompanies.unshift({
      CompanyName: companyName,
      isNewlyAdded: true,
    });
    setCompanyList(existingCompanies);
    setSelectedCompany(companyName);
    setPreserveNewlyAddedCompany(companyName);
    setAddNewCompanyModalVisible(false);
  };

  const onDeleteCompany = (index: number) => {
    const existingCompanies = [...companyList];
    existingCompanies.splice(index, 1);
    setCompanyList(existingCompanies);
    setSelectedCompany("");
    setPreserveNewlyAddedCompany("");
  };

  const isCompanyAlreadyAddedCheck = companyList?.some((item: ICompanyList) => {
    return item?.isNewlyAdded == true;
  });

  return (
    <>
      <div className="add-company-wrapper">
        <Title level={3} className="my-1" data-test-selector="hdgWhoStoreFor">
          What company are you creating this store for?
        </Title>
        {loading ? (
          <Space className="d-flex justify-center align-center h-300">
            <LoadingComponent />
          </Space>
        ) : (
          <>
            <Row gutter={[10, 14]} className="mb-32">
              {companyList?.length > 0 &&
                companyList?.map((item, index) => {
                  return renderCard(item, index);
                })}
            </Row>
            <Row className="mb-32">
              <Button
                block
                size="middle"
                type="primary"
                data-test-selector="btnAddNewCompany"
                onClick={() => {
                  setAddNewCompanyModalVisible(true);
                }}
                disabled={isCompanyAlreadyAddedCheck}
              >
                Add a new company
              </Button>
            </Row>
            {companyList?.length > 0 && newCompanyPageData.totalResult > 5 && (
              <div className="mb-32">
                <Pager
                  size="small"
                  defaultCurrent={1}
                  current={newCompanyPageData.pageIndex}
                  pageSize={newCompanyPageData.pageSize}
                  total={newCompanyPageData.totalResult}
                  onChange={(page, pageSize) => {
                    setNewCompanyPageData((prevState: INewCompanyPageData) => ({
                      ...prevState,
                      pageIndex: page,
                    }));
                    if (pageSize) {
                      setNewCompanyPageData((prevState: INewCompanyPageData) => ({
                        ...prevState,
                        pageSize: pageSize,
                      }));
                    }
                  }}
                  showSizeChanger
                  onShowSizeChange={(current, pageSize) => {
                    setNewCompanyPageData((prevState: INewCompanyPageData) => ({
                      ...prevState,
                      pageIndex: 1,
                      pageSize: pageSize,
                    }));
                  }}
                  showTotal={(total, range) => (
                    <span>
                      Showing <b>{numberWithCommas(range[0])}</b> to
                      <b> {numberWithCommas(range[1])}</b> of
                      <b> {numberWithCommas(total)}</b> items
                    </span>
                  )}
                  itemRender={(current, type, originalElement) => {
                    if (type === "page") {
                      return (
                        <a
                          style={
                            current === newCompanyPageData.pageIndex
                              ? { backgroundColor: "#4D9CD4", color: "#ffffff" }
                              : {}
                          }
                          href={`#${current}`}
                          onClick={(e) => {
                            e.preventDefault();
                            setNewCompanyPageData((prevState: INewCompanyPageData) => ({
                              ...prevState,
                              pageIndex: current,
                            }));
                          }}
                        >
                          {numberWithCommas(current)}
                        </a>
                      );
                    }
                    return originalElement;
                  }}
                  pageSizeOptions={[5, 10, 20, 50]}
                />
              </div>
            )}
          </>
        )}
        {/* <StepsComponent /> */}
        <Row className="my-1" style={{ justifyContent: "end" }}>
          <div className="back-btn">
            <Button onClick={handleBack} data-test-selector="btnCancel">
              Back
            </Button>
          </div>
          <div className="next-btn">
            <Form.Item>
              <Button
                onClick={nextClickHandler}
                className="gray-bg-button"
                data-test-selector="btnNext"
              >
                Next
              </Button>
            </Form.Item>
          </div>
        </Row>
      </div>
      <AddNewCompanyModal
        isModalVisible={addNewCompanyModalVisible}
        handleCancel={() => {
          setAddNewCompanyModalVisible(false);
        }}
        successHandlerCallback={onAddCompanyName}
      />
    </>
  );
};

export default AlreadyExistCompany;
